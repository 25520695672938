export class Currency {

    static namedWithCurrencyCode(name, currency_code) {
        return new this(name, currency_code);
    }

    constructor(a_name, currency_code) {
        this._name = a_name;
        this._currency_code = currency_code;
    }

    currencyCode() {
        return this._currency_code;
    }

    nameForOne() {
        return this._name;
    }

    toString() {
        return this.nameForOne();
    }

    isEqualTo(anotherCurrency) {
        return this._name === anotherCurrency.nameForOne() && this._currency_code === anotherCurrency.currencyCode();
    }
}

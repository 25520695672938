import {Money} from "../financial/money/money";
import {CurrencySerializer} from "./CurrencySerializer";
import {UnknownMoney} from "../financial/money/unknown_money";

const UNKNOWN_MONEY_SERIALIZED = "UNKNOWN_MONEY"

export class MoneySerializer {

    serialize(aMoney) {
        return aMoney.serialize(this);
    }

    deserialize(dict) {
        const currency = new CurrencySerializer().deserialized(dict['currency']);
        if (dict["amount"] === UNKNOWN_MONEY_SERIALIZED) {
            return UnknownMoney.withCurrency(currency);
        } else {
            return Money.withAmountAndCurrency(parseFloat(dict['amount']), currency);
        }
    }

    // double dispatch

    serializeMoney(anAmount, aCurrency) {
        return {
            "currency": new CurrencySerializer().serialize(aCurrency),
            "amount": anAmount.toString()
        };
    }

    serializeUnknownMoney(anAmount, aCurrency) {
        return {
            "currency": new CurrencySerializer().serialize(aCurrency),
            "amount": UNKNOWN_MONEY_SERIALIZED
        };
    }
}
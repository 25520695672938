import React from 'react';
import {LoginScreen} from "../../login/ui/LoginScreen";
import {LoggedInUserStorage} from "../../LoggedInUserStorage";
import {Footer} from "./Footer";
import {HeaderBar} from "./HeaderBar";
import {TermsAndConditionsScreen} from "./TermsAndConditionsScreen";
import {LoggedInScreen} from "./LoggedInScreen";
import {Backdrop} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import {TradingActionBrowserStorage} from "../../trading_action/TradingActionBrowserStorage";
import {TradingActionLogic} from "../../trading_action/TradingActionLogic";
import {WithdrawalConfirmationScreen} from "../../withdrawal/ui/WithdrawalConfirmationScreen";
import {HomebrokerErrorScreen} from "./HomebrokerErrorScreen"
import {
    DEPOSIT_AND_WITHDRAWAL_HOWTO_SCREEN,
    LOGGED_IN,
    LOGIN,
    ScreenNavigator,
    TERMS_AND_CONDITIONS,
    WITHDRAWAL_CONFIRMATION,
    HOMEBROKER_ERROR_SCREEN
} from "../ScreenNavigator";
import {EcoChatFab} from "./EcoChatFab";
import {DepositAndWithdrawalHowToScreen} from "../../DepositAndWithdrawalHowToScreen";
import {system} from "../../../index";
import {ErrorSnackbar} from "./ErrorSnackbar";
import {InformationSnackbar} from "./InformationSnackbar";

const useStyles = theme => ({
    containerBelowHeaderBar: {
        paddingTop: theme.mixins.toolbar.minHeight,
        minHeight: `calc(100vh - ${theme.mixins.footer.height})`
    },
    backdrop: {
        zIndex: theme.zIndex.appBar - 1
    }
});

export class Screen extends React.Component {

    constructor(props) {
        super(props);
        this._loggedInUserStorage = new LoggedInUserStorage();
        const loggedInUserOrNull = this._loggedInUserStorage.getLoggedInUserOrNull();
        this.state = {
            loggedInUser: loggedInUserOrNull,
            isSessionExpired: false,
            isUnexpectedError: false,
            isNetworkError: false,
            preferredTradingActionType: null,
            menuOpen: false,
            navigator: new ScreenNavigator(loggedInUserOrNull != null),
            isOpeningLoggedLink: false,
            isLoggedLinkURLErrors: false,
            loggedLinkURLName: "",
            isOpeningChangePasswordLink: false,
            isChangePaswordURLErrors:false,

        };
        this._tradingActionLogic = new TradingActionLogic(new TradingActionBrowserStorage());

        this.handleLoginSuccessful = this.handleLoginSuccessful.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handlePreferredTradingActionTypeChange = this.handlePreferredTradingActionTypeChange.bind(this);
        this.handleGetDefaultTradingActionType = this.handleGetDefaultTradingActionType.bind(this);
        this.handleGoBackToHome = this.handleGoBackToHome.bind(this);
        this.handleHomebrokerRedirection = this.handleHomebrokerRedirection.bind(this);
        this.handleMatrizRedirection = this.handleMatrizRedirection.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);

        this.handleNetworkError = this.handleNetworkError.bind(this);
        this.handleUnexpectedError = this.handleUnexpectedError.bind(this);
        this.handleConnectionRestablished = this.handleConnectionRestablished.bind(this);
        this.handleSessionExpired = this.handleSessionExpired.bind(this);
        this.isConnectionError = this.isConnectionError.bind(this);

        system.configureErrorHandlers(this.handleNetworkError, this.handleUnexpectedError, this.handleSessionExpired,
            this.isConnectionError, this.handleConnectionRestablished);
    }

    // handlers

    handleLoginSuccessful(loggedInUser) {
        this.state.navigator.showLoggedInScreen();
        this._loggedInUserStorage.saveLoggedInUser(loggedInUser);
        this.setState({
            navigator: this.state.navigator,
            loggedInUser: loggedInUser
        });
    }

    handleSessionExpired() {
        this.handleLogout();
        this.setState({
            isSessionExpired: true
        });
    }

    handleLogout() {
        this.state.navigator.showLoginScreen();
        this._loggedInUserStorage.removeLoggedInUser();
        this.setState({
            navigator: this.state.navigator,
            loggedInUser: null,
        });
    }

    handleTermsAndConditionsClick() {
        this.state.navigator.showTermsAndConditions();
        this.setState({
            navigator: this.state.navigator
        });
    }

    handlePreferredTradingActionTypeChange(preferredTradingActionType) {
        this._tradingActionLogic.changePreferredTradingActionType(preferredTradingActionType);
        this.setState({preferredTradingActionType: preferredTradingActionType});
    }

    handleGetDefaultTradingActionType(defaultTradingActionType) {
        const preferredTradingActionType = this._tradingActionLogic.getPreferredTradingActionType(defaultTradingActionType);
        this.setState({preferredTradingActionType: preferredTradingActionType})
    }

    handleGoBackToHome() {
        this.state.navigator.goBackToHome(this.isLoggedIn());
        this.setState({
            navigator: this.state.navigator
        });
    }

    handleUnexpectedError() {
        this.setState({isUnexpectedError: true});
    }

    handleNetworkError() {
        this.setState({isNetworkError: true});
    }

    handleConnectionRestablished() {
        this.setState({isUnexpectedError: false, isNetworkError: false});
    }

    handleHomebrokerRedirection() {
        return this.handleLoggedLinkRedirection("/redirectToHomebroker", "Home Broker");
    }

    handleMatrizRedirection() {
        return this.handleLoggedLinkRedirection("/redirectToMatriz", "Matriz");
    }

    async handleChangePassword() {
        console.log("En change password");
        let user_token = this._loggedInUserStorage.getLoggedInUserOrNull().userToken();
        let url = "/changePassword";
        this.setState({
            isOpeningChangePasswordLink: true,
            isChangePaswordURLErrors:false,
        });
        console.log("Estoy en handle password");
        await system.redirectToUrl(user_token, url,
        (change_password_response) => {
            let response = change_password_response;
            this.setState({
                isOpeningChangePasswordLink: false,
            });
            // FIXME: Handle change password problems
            console.log("Vuelta ok de handle password")
            if(response["status" == "OK"]) {
                let mail = response["mail"]
                alert("Dentro de los próximos 2 minutos te llegará un email con asunto 'OMS  ECO - Recupero password', " +
                    "que contiene un link para que elijas tu nueva contraseña.");
            } else {
                if (response["response"] == "PASSWORD_RESET_TOKEN_ALREADY_SENT") {
                    alert("Ya ha sido enviado el email de cambio de clave previamente, por favor verificá en tu casilla si te llegó un email " 
                        + "con el asunto 'OMS ECO - Recupero Password'. Por cualquier inconveniente, por favor consultanos en Eco Chat, opción 9.");
                } else {
                    this.setState((state) => {
                        return {
                            isOpeningChangePasswordLink: false,
                            isChangePaswordURLErrors: true,
                        }
                    });
                }
            }
        },
        (errors) => {  
            this.setState((state) => {
            return {
                isOpeningChangePasswordLink: false,
                isChangePaswordURLErrors: true,
            }
            });
        });

    }

    async handleLoggedLinkRedirection(url, error) {
        this.setState({
            isOpeningLoggedLink: true,
            loggedLinkURLName: error,
        });
        let user_token = this._loggedInUserStorage.getLoggedInUserOrNull().userToken();
        await system.redirectToUrl(user_token, url,
        (linkUrl) => {
            let url = linkUrl;
            this.setState({
                isOpeningLoggedLink: false
            });
            window.open(url, '_blank').focus();            
        },
        (errors) => {  
            this.setState((state) => {
            return {
                isOpeningLoggedLink: false,
                isLoggedLinkURLErrors: true,
            }
            });
        });
    }    


    // private

    isLoggedIn() {
        return this.state.loggedInUser != null;
    }

    isConnectionError() {
        return this.state.isNetworkError || this.state.isUnexpectedError;
    }

    // rendering

    render() {
        const {classes} = this.props;

        return (
            <>
                <HeaderBar isLoggedIn={this.isLoggedIn()}
                           loggedInUser={this.state.loggedInUser}
                           onLogout={this.handleLogout}
                           onPreferredTradingActionTypeChange={this.handlePreferredTradingActionTypeChange}
                           preferredTradingActionType={this.state.preferredTradingActionType}
                           onMenuOpen={() => this.setState({menuOpen: true})}
                           onMenuClose={() => this.setState({menuOpen: false})}
                           onMatrizClicked={this.handleMatrizRedirection}
                           onHomebrokerClicked={this.handleHomebrokerRedirection}
                           onChangePasswordClicked={this.handleChangePassword}
                />
                {this.state.navigator.screenToShow({
                    [TERMS_AND_CONDITIONS]:
                        <div className={classes.containerBelowHeaderBar}>
                            <TermsAndConditionsScreen/>
                            <EcoChatFab/>
                        </div>,
                    [WITHDRAWAL_CONFIRMATION]:
                        <div className={classes.containerBelowHeaderBar}>
                            <WithdrawalConfirmationScreen onGoBackToHome={this.handleGoBackToHome}/>
                            <EcoChatFab/>
                        </div>,
                    [DEPOSIT_AND_WITHDRAWAL_HOWTO_SCREEN]:
                        <div className={classes.containerBelowHeaderBar}>
                            <DepositAndWithdrawalHowToScreen onClose={this.handleGoBackToHome}/>,
                        </div>,
                    [HOMEBROKER_ERROR_SCREEN]:
                        <div className={classes.containerBelowHeaderBar}>
                            <HomebrokerErrorScreen onGoBackToPortfolio={this.handleGoBackToHome}/>,
                        </div>,
                    [LOGGED_IN]:
                        <div data-e2e="home" className={classes.containerBelowHeaderBar}>
                            <LoggedInScreen loggedInUser={this.state.loggedInUser}
                                            preferredTradingActionType={this.state.preferredTradingActionType}
                                            onHomebrokerClicked={this.handleHomebrokerRedirection}
                                            onMatrizClicked={this.handleMatrizRedirection}
                                            onGetDefaultTradingActionType={this.handleGetDefaultTradingActionType}
                            />
                            <EcoChatFab/>
                        </div>,
                    [LOGIN]:
                        <div data-e2e="home">
                            <LoginScreen onSuccessfulLogin={this.handleLoginSuccessful}
                                         isSessionExpired={this.state.isSessionExpired}/>
                        </div>
                })}
                <Footer onTermsAndConditionsClick={() => this.handleTermsAndConditionsClick()}/>
                <Backdrop open={this.state.menuOpen} className={classes.backdrop}/>
                <ErrorSnackbar data-e2e="unexpected-error-snackbar"
                               isShown={this.state.isUnexpectedError}
                               title="Hubo un problema inesperado en el servidor"/>
                <ErrorSnackbar data-e2e="network-error-snackbar"
                               isShown={this.state.isNetworkError}
                               title="No es posible conectarse con el servidor"
                               subtitle="Por favor verifique su conexión a Internet"/>
                <ErrorSnackbar data-e2e="loggedLink-error-snackbar"
                               autoHideDuration={3000}
                               isShown={this.state.isLoggedLinkURLErrors}
                               title={"No es posible redireccionar a " + this.state.loggedLinkURLName}
                               subtitle="Por favor verifique su conexión a Internet. 
                               Este botón funciona solamente si ya te diste de alta en Históricos (Home Broker).
                               Para darte de alta, elegí opción 2 del menú de Eco Chat.
                               Si el problema persiste comunicarse con el EcoChat opción 9"/>
                <ErrorSnackbar data-e2e="changePassword-error-snackbar"
                               autoHideDuration={3000}
                               isShown={this.state.isChangePaswordURLErrors}
                               title={"Error recuperando la contraseña"}
                               subtitle="Por favor verifique su conexión a Internet. 
                               Si el problema persiste comunicarse con el EcoChat opción 9"/>

                <InformationSnackbar data-e2e="opening-homebroker-snackbar"
                               isShown={this.state.isOpeningLoggedLink}
                               title={"Generando redirección a " + this.state.loggedLinkURLName + ". Revise no estar blockeando las ventanas emergentes."}/>

                <InformationSnackbar data-e2e="opening-changePassword-snackbar"
                               isShown={this.state.isOpeningChangePasswordLink}
                               title={"Generando recuperación de contraseña."}/>
            </>
        );
    }
}

Screen = withStyles(useStyles)(Screen);

import React from "react";
import Divider from '@material-ui/core/Divider';
import withStyles from "@material-ui/core/styles/withStyles";
import {IconButton, Popover} from "@material-ui/core";
import {MoreVertSharp} from "@material-ui/icons";
import {ConfigurationSectionTitle} from "./ConfigurationSectionTitle";
import CloseIcon from "@material-ui/icons/Close";
import LogoutButton from "./LogoutButton";
import ChangePasswordButton from "./ChangePasswordButton";
import {PreferredTradingActionConfiguration} from "./PreferredTradingActionConfiguration";
import {PopoverModel} from "../../../PopoverModel";
import {TradingActionLogic} from "../../../trading_action/TradingActionLogic";

const useStyles = theme => ({
    popover: {
        marginTop: theme.spacing(1),
    },
    popoverPaper: {
        borderRadius: theme.spacing(2),
        marginTop: theme.mixins.toolbar.minHeight,
        width: '100%',
        maxWidth: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '400px'
        }
    },
    openButton: {
        color: theme.palette.grey[400]
    },
    configurationSectionTitleContainer: {
        padding: theme.spacing(4, 3, 2, 3)
    },
    configurationSectionChangePasswordContainer: {
        padding: theme.spacing(2, 3, 1, 3)
    },
    configurationSectionLogoutContainer: {
        padding: theme.spacing(2, 3, 4, 3)
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        color: theme.palette.grey[400],
    },
});

export class ConfigurationMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {model: new PopoverModel()};

        this._modelUpdated = this._modelUpdated.bind(this);
    }

    // private

    _modelUpdated() {
        this.setState({model: this.state.model});
    }

    _areActionsAvailable() {
        const isLoaded = this.props.preferredTradingActionType !== null;
        const tradingActionLogic = new TradingActionLogic();

        return isLoaded && tradingActionLogic.tradingActionCanBeToggled(this.props.preferredTradingActionType);
    }

    // handlers

    handleOpen() {
        const headerBar = document.getElementsByTagName('header')[0];
        this.state.model.openAnchoredTo(headerBar);
        this._modelUpdated();
        this.props.onMenuOpen();
    }

    handleClose() {
        this.state.model.close();
        this._modelUpdated();
        this.props.onMenuClose();
    }

    handleLogoutClick() {
        this.handleClose();
        this.props.onLogout();
    }

    handleChangePasswordClick() {
        this.props.onChangePasswordClicked();
    }
    // rendering

    render() {
        const {classes} = this.props;

        return (
            <>
                <IconButton edge="start" aria-label="menu" className={classes.openButton}
                            data-e2e="configuration-menu-button"
                            onClick={() => this.handleOpen()}>
                    <MoreVertSharp/>
                </IconButton>
                <Popover elevation={1}
                         open={this.state.model.isOpen()}
                         marginThreshold={0}
                         className={classes.popover}
                         anchorEl={() => this.state.model.anchorElement()}
                         onClose={() => this.handleClose()}
                         classes={{paper: classes.popoverPaper}}
                         anchorOrigin={{
                             horizontal: 'right',
                             vertical: 'top'
                         }}>
                    <IconButton aria-label="close" className={classes.closeButton}
                                onClick={() => this.handleClose()}>
                        <CloseIcon/>
                    </IconButton>
                    {this._areActionsAvailable() ?
                        <>
                            <div className={classes.configurationSectionTitleContainer}>
                                <ConfigurationSectionTitle loggedInUser={this.props.loggedInUser}/>
                            </div>
                            <Divider/>
                            <PreferredTradingActionConfiguration
                                preferredTradingActionType={this.props.preferredTradingActionType}
                                onPreferredTradingActionTypeChange={this.props.onPreferredTradingActionTypeChange}/>
                            <Divider/>
                        </> :
                        null
                    }

                    <div className={classes.configurationSectionChangePasswordContainer}>
                        <ChangePasswordButton onChangePasswordClicked={() => this.handleChangePasswordClick()}/>
                    </div>

                    <div className={classes.configurationSectionLogoutContainer}>
                        <LogoutButton onLogout={() => this.handleLogoutClick()}/>
                    </div>
                </Popover>
            </>
        );
    }
}

ConfigurationMenu = withStyles(useStyles)(ConfigurationMenu)

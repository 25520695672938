import {Cuit} from "./Cuit";
import {system} from "../../index";
import {LoggedInUser} from "./LoggedInUser";

export class LoginInteractions {
    constructor(loginScreen, onSuccessfulLogin) {
        this._screen = loginScreen;
        this._onSuccessfulLogin = onSuccessfulLogin;
    }

    initialState() {
        return {
            showForgotPassword: false,
            isLoading: false,
            accessDenied: false,
            serviceUnavailable: false,
            invalidCuit: false
        }
    }

    handleForgotPassword() {
        this._screen.setState({showForgotPassword: true})
    }

    async handleLogin(event) {
        event.preventDefault();

        const userName = event.target.userName.value;
        const password = event.target.password.value;

        if (!Cuit.isValid(userName)) {
            this._screen.setState({
                invalidCuit: true,
                accessDenied: false,
                serviceUnavailable: false
            });
        } else {
            this._screen.setState({
                isLoading: true
            });

            await system.loginWithCredentials(userName, password,
                (responseObject) => {
                    this._screen.setState({
                        isLoading: false,
                        invalidCuit: false
                    });
                    const loggedInUser = LoggedInUser.newWith(responseObject.userToken, responseObject.username,
                        responseObject.accountsNumbers);
                    this._onSuccessfulLogin(loggedInUser);
                },
                (errors) => {
                    const accessDenied = errors.some(error => error.code === "ACCESS_DENIED");
                    const serviceUnavailable = errors.some(error => error.code === "SERVICE_UNAVAILABLE");
                    this._screen.setState({
                        isLoading: false,
                        accessDenied: accessDenied,
                        serviceUnavailable: serviceUnavailable,
                        invalidCuit: false
                    });
                });
        }
    }
}
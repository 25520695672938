import React from "react";
import Alert from "@material-ui/lab/Alert";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = theme => ({
    root: {
        '& p': {
            margin: 0,
        }
    }
});

export class PortfolioLegalMessage extends React.Component {
    render() {
        const {classes} = this.props;

        return (
            <footer className={classes.root}>
                <Alert severity="info">
                    <p>
                        Salvo error u omisión (S.E.U.O)
                    </p>
                    <p>
                        Res. CNV: Conforme las reglamentaciones de los Mercados, la documentación de respaldo de
                        cada operación se encuentra a disposición del cliente.
                    </p>
                </Alert>
            </footer>
        );
    }
}

PortfolioLegalMessage = withStyles(useStyles)(PortfolioLegalMessage);
import React from 'react';
import {Cell, Pie, PieChart, ResponsiveContainer} from 'recharts';
import withStyles from "@material-ui/core/styles/withStyles";
import withWidth from '@material-ui/core/withWidth';
import Skeleton from "react-loading-skeleton";
import {MoneyPrinter} from "../../MoneyPrinter";

const useStyles = theme => ({
    pie: {
        filter: 'url(#shadow)',
    },
    insideChartContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    insideChartContent: {
        width: '70%',
    },
    titleStyleInsideChart: {
        ...theme.typography.body1,
        fontWeight: theme.typography.fontWeightBold,
        textAlign: 'center',
        margin: 0,
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.h5.fontSize
        }
    },
    subtitleStyleInsideChart: {
        ...theme.typography.caption,
        color: theme.palette.text.secondary,
        textAlign: 'center',
        margin: 0,
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.h6.fontSize
        }
    }
});

export class PortfolioSharePieChart extends React.Component {

    constructor(props) {
        super(props);
        this.onPieSectionHover = this.onPieSectionHover.bind(this);
        this.onPieLeave = this.onPieLeave.bind(this);
    }

    // events

    onPieSectionHover(dataOfSelectedPieSection, _index) {
        this.props.onPieSectionHover(dataOfSelectedPieSection.holding);
    }

    onPieLeave() {
        this.props.onPieLeave();
    }

    // private

    pieChartSize() {
        return "100%";
    }

    // chart data

    pieChartData() {
        return this.props.holdingsData.filter((holdingData) => holdingData.share.value > 0)
    }

    selectedHoldingData() {
        return this.pieChartData().find((holdingData) => holdingData.holding === this.props.selectedHolding);
    }

    // rendering conditions

    isPortfolioLoaded() {
        return this.props.portfolioTotal !== null;
    }

    portfolioIsNotEmpty() {
        return this.props.portfolioTotal.amount > 0;
    }

    noSelectedHolding() {
        return this.props.selectedHolding === null;
    }

    // rendering

    renderTextInsidePieChartDependingOnSelection() {
        if (this.noSelectedHolding()) {
            const portfolioTotalFormatted = new MoneyPrinter().print(this.props.portfolioTotal);
            return this.renderTextInsidePieChart(
                portfolioTotalFormatted,
                "Portfolio"
            );
        } else {
            const holdingTotalFormatted = new MoneyPrinter().print(this.selectedHoldingData().total);
            return this.renderTextInsidePieChart(
                holdingTotalFormatted,
                this.selectedHoldingData().displayName
            )
        }
    }

    renderTextInsidePieChart(title, subtitle) {
        const {classes} = this.props;

        return (
            <foreignObject width={this.pieChartSize()} height={this.pieChartSize()}>
                <div xmlns="http://www.w3.org/1999/xhtml" className={classes.insideChartContainer}>
                    <div className={classes.insideChartContent}>
                        <h1 className={classes.titleStyleInsideChart}>{title}</h1>
                        <h2 className={classes.subtitleStyleInsideChart}>{subtitle}</h2>
                    </div>
                </div>
            </foreignObject>
        )
    }

    renderPieShadowDefinition() {
        return (
            <defs>
                <filter id="shadow">
                    <feDropShadow dx="0" dy="0" stdDeviation="3" floodOpacity="0.3"/>
                </filter>
            </defs>
        );
    }

    render() {
        const {classes} = this.props;
        return (
            this.isPortfolioLoaded() ?
                (this.portfolioIsNotEmpty() ?
                        <ResponsiveContainer width={this.pieChartSize()} height={this.pieChartSize()}>
                            <PieChart>
                                {this.renderTextInsidePieChartDependingOnSelection()}
                                {this.renderPieShadowDefinition()}
                                <Pie className={classes.pie}
                                     data={this.pieChartData()}
                                     innerRadius="84%"
                                     outerRadius="100%"
                                     dataKey="share.value"
                                     onMouseEnter={this.onPieSectionHover}
                                     onMouseLeave={this.onPieLeave}
                                     blendStroke>
                                    {this.pieChartData().map(({holding, color}) =>
                                        <Cell key={`cell-${holding}`} fill={color}/>
                                    )}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer> : null
                ) : <Skeleton circle={true} width={this.pieChartSize()} height={this.pieChartSize()}/>
        );
    }
}

PortfolioSharePieChart = withWidth()(withStyles(useStyles)(PortfolioSharePieChart))

import React from "react";
import {Hidden, List, Paper} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = theme => ({
    list: {
        padding: 0
    },
    title: {
        color: theme.palette.text.secondary,
        [theme.breakpoints.up('sm')]: {
            paddingBottom: theme.spacing(3),
            color: '#6B3EFF',
            fontWeight: theme.typography.fontWeightMedium
        }
    },
    titleAddon: {
        color: theme.palette.text.secondary,
        [theme.breakpoints.up('sm')]: {
            fontWeight: theme.typography.fontWeightBold
        }

    }
});

export class HowTo extends React.Component {

    // rendering

    render() {
        const {classes} = this.props;

        const list = (
            <List className={classes.list} dense>
                {this.props.children}
            </List>
        );

        return (
            <>
                <Hidden smUp>
                    <Typography variant="body2" className={classes.titleAddon}>
                        {this.props.allCuit}
                    </Typography>
                    <Typography className={classes.title}>
                        {this.props.xsTitle}
                    </Typography>
                    {list}
                </Hidden>
                <Hidden only="xs">
                    <Typography variant="body2" align="center" className={classes.titleAddon}>
                        {this.props.allCuit}
                    </Typography>
                    <Typography variant="h5" align="center" className={classes.title}>
                        {this.props.smUpTitle}
                    </Typography>
                    <Paper>
                        {list}
                    </Paper>
                </Hidden>
            </>
        );
    }
}

HowTo = withStyles(useStyles)(HowTo);

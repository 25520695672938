import Radio from '@material-ui/core/Radio';
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = theme => ({
    icon: {
        borderRadius: '50%',
        width: '12px',
        height: '12px',
        borderColor: theme.palette.grey[500],
        border: '1px solid'
    },
    checkedIcon: {
        backgroundColor: theme.palette.primary.main
    }
});

class ConfigurationSectionRadio extends React.Component {
    render() {
        const {classes, ...other} = this.props;
        return (
            <Radio
                checkedIcon={<span className={`${classes.icon} ${classes.checkedIcon}`}/>}
                icon={<span className={classes.icon}/>}
                {...other}
            />
        )
    };
}

export default withStyles(useStyles)(ConfigurationSectionRadio);

export const TERMS_AND_CONDITIONS = "TERMS_AND_CONDITIONS";
export const WITHDRAWAL_CONFIRMATION = "WITHDRAWAL_CONFIRMATION";
export const LOGGED_IN = "LOGGED_IN";
export const LOGIN = "LOGIN"
export const DEPOSIT_AND_WITHDRAWAL_HOWTO_SCREEN = "DEPOSIT_AND_WITHDRAWAL_HOWTO_SCREEN"
export const HOMEBROKER_ERROR_SCREEN = "HOMEBROKER_ERROR_SCREEN"

export class ScreenNavigator {
    constructor(isLoggedIn) {
        if (window.location.pathname.startsWith("/withdraw/confirm/")) {
            this._screenToShow = WITHDRAWAL_CONFIRMATION;
        } else if (window.location.pathname === "/depositar-y-retirar") {
            this._screenToShow = DEPOSIT_AND_WITHDRAWAL_HOWTO_SCREEN;
        } else if (window.location.pathname === '/homebroker-error') {
            this._screenToShow = HOMEBROKER_ERROR_SCREEN;
        } else {
            this._showHome(isLoggedIn);
        }
    }

    // navigate

    goBackToHome(isLoggedIn) {
        this._changeUrl('/');
        this._showHome(isLoggedIn);
    }

    showTermsAndConditions() {
        this._screenToShow = TERMS_AND_CONDITIONS;
    }

    showLoggedInScreen() {
        this._screenToShow = LOGGED_IN;
    }

    showLoginScreen() {
        this._screenToShow = LOGIN;
    }

    // show

    screenToShow(screensByName) {
        return screensByName[this._screenToShow];
    }

    // private

    _showHome(isLoggedIn) {
        if (isLoggedIn) {
            this.showLoggedInScreen();
        } else {
            this.showLoginScreen();
        }
    }

    _changeUrl(url) {
        window.history.pushState(null, null, url);
    }
}
import {CreationError} from "../../financial/assertions/CreationError";
import {FormCompletionAssistant} from "./FormCompletionAssistant";
import {CreationAssertion} from "../../financial/assertions/CreationAssertion";

export class FormSectionCompletionAssistant extends FormCompletionAssistant {
    _assistants;
    _model;

    static with(assistants, fromContainerModelGetter, assertionsId) {
        return new this(assistants, fromContainerModelGetter, assertionsId);
    }

    constructor(assistants, fromContainerModelGetter, assertionsId) {
        super(assertionsId, fromContainerModelGetter);
        this._assistants = assistants;
    }

    // model

    createModel(creationClosure) {
        this.removeFailedAssertions();
        const models = this._createComposedModels(creationClosure);
        try {
            this._model = creationClosure(...models);
        } catch (error) {
            this._model = CreationAssertion.INVALID_MODEL;
            this._handleCreateModelError(error);
        }

        return this._model;
    }

    getModel() {
        return this._model;
    }

    setModel(newModel) {
        this._model = newModel;
        this._assistants.forEach(assistant => assistant.setModelFrom(newModel));
    }

    resetModel() {
        this._model = CreationAssertion.INVALID_MODEL;
        this._assistants.forEach(assistant => assistant.resetModel());
    }

    // testing

    isIncomplete() {
        return this._assistants.some((assistant) => assistant.isIncomplete());
    }

    // private

    _createComposedModels(creationClosure) {
        return this._assistants.map(assistant => assistant.createModel(creationClosure));
    }

    _handleCreateModelError(error) {
        if (error instanceof CreationError) {
            this._routeFailedAssertionsOf(error);
        } else {
            throw error;
        }
    }

    _routeFailedAssertionsOf(creationError) {
        creationError.forEachAssertionFailed(failedAssertion => this._routeFailedAssertion(failedAssertion));
    }

    _routeFailedAssertion(failedAssertion) {
        if (this.handles(failedAssertion)) {
            this.addFailedAssertion(failedAssertion);
        } else {
            this._routeNotHandledByThisFailedAssertion(failedAssertion);
        }
    }

    _routeNotHandledByThisFailedAssertion(failedAssertion) {
        const assistantsHandlingAssertion = this._assistantsHandling(failedAssertion);

        if (assistantsHandlingAssertion.length === 0) {
            this.addFailedAssertion(failedAssertion);
        } else {
            this._addFailedAssertionToAll(assistantsHandlingAssertion, failedAssertion);
        }
    }

    _addFailedAssertionToAll(assistantsHandlingAssertion, failedAssertion) {
        assistantsHandlingAssertion.forEach(assistant => assistant.addFailedAssertion(failedAssertion));
    }

    _assistantsHandling(assertion) {
        return this._assistants.filter(assistant => assistant.handles(assertion));
    }
}
import React from "react";
import { AppBar, Hidden, Link, Toolbar } from "@material-ui/core";
import logo from "../../img/logo-eco-verde.png";
import withStyles from "@material-ui/core/styles/withStyles";
import { ConfigurationMenu } from "./configuration_menu/ConfigurationMenu";
import { RouterButtons } from "../../ui_common/buttons/RouterButtons";

const useStyles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(0, 0, 3, 3)
  },
  logoEco: {
    height: '2em',
  },
  logoEcoLink: {
    margin: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1)
    }
  },
  menu: {
    position: 'absolute',
    right: theme.spacing(1)
  },
  routerButtons: {
    position: 'absolute',
    right: '5%',
    width: '80%',
    minWidth: '920px'
  },
  hombrokerButton: {
    position: 'absolute',
    right: '0%'
  }
});

export class HeaderBar extends React.Component {

  // rendering

  render() {
    const { classes } = this.props;
    return (
      <AppBar position="fixed" color="transparent" className={classes.root} elevation={1}>
        <Toolbar>
          <Link href="/" className={classes.logoEcoLink}>
            <img src={logo} alt="Logo" className={classes.logoEco} />
          </Link>
          {this.props.isLoggedIn ?
            <>
              <Hidden mdDown>
                <div className={classes.routerButtons}>
                  <RouterButtons 
                    onHomebrokerClicked={this.props.onHomebrokerClicked}
                    onMatrizClicked={this.props.onMatrizClicked}
                    />
                </div>
              </Hidden>
              <div className={classes.menu}>
                <ConfigurationMenu
                  onPreferredTradingActionTypeChange={this.props.onPreferredTradingActionTypeChange}
                  preferredTradingActionType={this.props.preferredTradingActionType}
                  onLogout={this.props.onLogout}
                  onMenuOpen={this.props.onMenuOpen}
                  onMenuClose={this.props.onMenuClose}
                  loggedInUser={this.props.loggedInUser}
                  onChangePasswordClicked={this.props.onChangePasswordClicked}
                />
              </div>
            </> : null
          }
        </Toolbar>
      </AppBar>
    );
  }
}

HeaderBar = withStyles(useStyles)(HeaderBar);

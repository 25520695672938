import {CreationAssertionRunner} from "./assertions/CreationAssertionRunner";
import {CreationAssertion} from "./assertions/CreationAssertion";
import {BankAccountIdValidator} from "./BankAccountIdValidator";

export class WithdrawalRequest {
    _withdrawalAmount;

    static WITHDRAWAL_AMOUNT_ASSERTION_ID = "WITHDRAWAL_AMOUNT_ASSERTION_ID";
    static WITHDRAWAL_AMOUNT_GREATER_THAN_AVAILABLE_ERROR_DESCRIPTION = "El monto a retirar debe ser menor o igual " +
        "al disponible";
    static WITHDRAWAL_BANK_ACCOUNT_ASSERTION_ID = "WITHDRAWAL_BANK_ACCOUNT_ASSERTION_ID";
    static WITHDRAWAL_INVALID_BANK_ACCOUNT_ERROR_DESCRIPTION = "El CBU o ALIAS es inválido";

    static newWith(accountNumber, bankAccountId, withdrawalAmount, availableToWithdraw) {
        CreationAssertionRunner.assertAll([
            this.withdrawalAmountAssertion(withdrawalAmount, availableToWithdraw),
            this.bankAccountIdAssertion(bankAccountId)
        ]);

        return new this(bankAccountId, withdrawalAmount, accountNumber);
    }

    constructor(bankAccountId, withdrawalAmount, accountNumber) {
        this._withdrawalAmount = withdrawalAmount;
        this._bankAccountId = bankAccountId;
        this._accountNumber = accountNumber;
    }

    withdrawalAmount() {
        return this._withdrawalAmount;
    }

    bankAccountId() {
        return this._bankAccountId;
    }

    accountNumber() {
        return this._accountNumber;
    }

    static withdrawalAmountAssertion(withdrawalAmount, availableToWithdraw) {
        return CreationAssertion.for(
            withdrawalAmount,
            this.WITHDRAWAL_AMOUNT_ASSERTION_ID,
            () => withdrawalAmount.isLessOrEqualThan(availableToWithdraw),
            this.WITHDRAWAL_AMOUNT_GREATER_THAN_AVAILABLE_ERROR_DESCRIPTION
        );
    }

    static bankAccountIdAssertion(bankAccountId) {
        return CreationAssertion.for(
            bankAccountId,
            this.WITHDRAWAL_BANK_ACCOUNT_ASSERTION_ID,
            () => new BankAccountIdValidator(bankAccountId).validate(),
            this.WITHDRAWAL_INVALID_BANK_ACCOUNT_ERROR_DESCRIPTION
        );
    }
}


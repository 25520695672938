import React from "react";
import {Grid} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = theme => ({
    loading: {
        margin: theme.spacing(4, 0)
    }
});

export class WithdrawalFormLoading extends React.Component {

    render() {
        const {classes} = this.props;

        return (
	    <>
	            <Grid data-e2e="withdrawal-request-form-loading" container justify="center">
        	        <CircularProgress className={classes.loading}/>
	            </Grid>
        	    <Grid data-e2e="withdrawal-request-form-loading" container justify="center">
			Un momento por favor, esta operación puede tardar hasta un minuto

        	    </Grid>
	    </>
        );
    }

}

WithdrawalFormLoading = withStyles(useStyles)(WithdrawalFormLoading);

import React from "react";
import {IconButton, Popover} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import infoIcon from "../../img/icons/info.svg";
import {PopoverModel} from "../../PopoverModel";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = theme => ({
    infoIcon: {
        width: '16px',
        verticalAlign: 'middle'
    },
    iconButton: {
        padding: 0
    },
    popover: {
        background: theme.palette.info.main,
        color: theme.palette.common.white,
        padding: theme.spacing(3, 3, 5, 3),
        [theme.breakpoints.up('sm')]: {
            width: '350px'
        }
    },
    title: {
        fontWeight: theme.typography.fontWeightBold,
        marginBottom: theme.spacing(1)
    },
    closeButton: {
        color: theme.palette.common.white,
        position: 'absolute',
        right: 0,
        top: 0
    }
});

export class PositionPopoverInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {model: new PopoverModel()}
    }

    handleInformationButtonClick(event) {
        event.stopPropagation();
        this.state.model.openAnchoredTo(event.currentTarget);
        this._modelUpdated();
    }

    handleClose() {
        this.state.model.close();
        this._modelUpdated();
    }

    handlePopoverClickToPreventTradingActionFiring(event) {
        event.stopPropagation();
    }

    _modelUpdated() {
        this.setState({model: this.state.model});
    }

    render() {
        const {classes} = this.props;

        return this.props.shouldDisplay ? (
            <>
                <IconButton className={classes.iconButton}
                            onClick={(event) => this.handleInformationButtonClick(event)}>
                    <img src={infoIcon} alt="Info" className={classes.infoIcon}/>
                </IconButton>
                <Popover
                    classes={{paper: classes.popover}}
                    open={this.state.model.isOpen()}
                    anchorEl={this.state.model.anchorElement()}
                    onClose={() => this.handleClose()}
                    onClick={(event) => {
                        this.handlePopoverClickToPreventTradingActionFiring(event)
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}>
                    <IconButton aria-label="close" className={classes.closeButton}
                                onClick={() => this.handleClose()}>
                        <CloseIcon fontSize="small"/>
                    </IconButton>
                    <Typography component="h6" variant="subtitle1" className={classes.title}>
                        Información útil
                    </Typography>
                    <Typography component="p" variant="subtitle2">
                        Los precios aquí desplegados se muestran en
                        pesos y corresponden al último precio operado
                        de la especie subyacente en pesos.
                    </Typography>
                </Popover>
            </>
        ) : null;
    }
}

PositionPopoverInfo = withStyles(useStyles)(PositionPopoverInfo);

import React from "react";
import { WithdrawalSuccessScreen } from "./WithdrawalSuccessScreen";

export class WithdrawalBeingProcessedScreen extends React.Component {
  render() {
    return (
      <WithdrawalSuccessScreen data-e2e="withdraw-being-processed-screen"
        title="La extracción está siendo procesada"
        subtitle="Esto puede demorar hasta dos horas. Si luego de este tiempo no se
                                     acreditó el dinero en tu banco, ingresá al Eco Chat, y elegí opción 9 para
                                     comunicarte con nosotros. Las extracciones se realizan en días hábiles hasta las 17hs."
        onGoBackToPortfolio={this.props.onGoBackToPortfolio} />
    )
  }
}

export class BankAccountIdValidator {

    constructor(bankAccountId) {
        this._bankAccountId = bankAccountId;
    }

    validate() {
        return this._isAlias() || this._isCBU();
    }

    _isAlias() {
        const aliasRegex = new RegExp('^[A-Za-z0-9.-]{6,20}$')

        return aliasRegex.test(this._bankAccountId);
    }

    _isCBU() {
        const hasCBULength = this._bankAccountId.length === 22;
        const isNumericRegex = /^\d*$/;
        const isNumeric = isNumericRegex.test(this._bankAccountId);

        return hasCBULength && isNumeric &&
            this._isBankCodeValid(this._bankAccountId.substr(0, 8)) &&
            this._isAccountNumberValid(this._bankAccountId.substr(8, 14));
    }

    _isBankCodeValid(bankCodeAsString) {
        const bankCode = this._stringToNumberArray(bankCodeAsString);

        const bank = bankCode.slice(0, 3);
        const subsidiary = bankCode.slice(3, 7);
        const checkDigit = bankCode[7];

        const checkSum = bank[0] * 7 + bank[1] * 1 + bank[2] * 3 +
            subsidiary[0] * 9 + subsidiary[1] * 7 + subsidiary[2] * 1 + subsidiary[3] * 3;

        return this._validateCheckDigit(checkSum, checkDigit);
    }

    _isAccountNumberValid(accountNumberAsString) {
        const accountNumber = this._stringToNumberArray(accountNumberAsString);

        const checkDigit = accountNumber[13];

        const checkSum = accountNumber[0] * 3 + accountNumber[1] * 9 + accountNumber[2] * 7 + accountNumber[3] +
            accountNumber[4] * 3 + accountNumber[5] * 9 + accountNumber[6] * 7 + accountNumber[7] +
            accountNumber[8] * 3 + accountNumber[9] * 9 + accountNumber[10] * 7 + accountNumber[11] +
            accountNumber[12] * 3;

        return this._validateCheckDigit(checkSum, checkDigit);
    }

    _validateCheckDigit(checkSum, checkDigit) {
        const checkSumLastDigit = this._lastDigitFrom(checkSum);
        const difference = 10 - checkSumLastDigit;

        if (checkDigit === 0) {
            return difference === 10;
        } else {
            return difference === checkDigit;
        }
    }

    _stringToNumberArray(string) {
        const characterArray = string.split("");
        return characterArray.map((character) => parseInt(character));
    }

    _lastDigitFrom(number) {
        const lastDigitAsString = number.toString().substr(-1);
        return parseInt(lastDigitAsString);
    }
}
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {Typography} from "@material-ui/core";
import {WithdrawalSuccessScreen} from "../../withdrawal/ui/WithdrawalSuccessScreen"
const useStyles = _theme => ({
    emailAddress: {
        color: '#6B3EFF',
    }
})

export class HomebrokerErrorScreen extends React.Component {
    render() {
        const {classes} = this.props;
        return (
            <WithdrawalSuccessScreen
                data-e2e="withdraw-email-sent-screen"
                title={
                    <>
                        Ups! Perdón, algo no anduvo bien.
                    </>
                }
                subtitle="Por favor volvé a Eco Portfolio e intentá apretando nuevamente el botón 'Ingresar a Históricos'"
                onGoBackToPortfolio={this.props.onGoBackToPortfolio}/>
        )
    }
}

HomebrokerErrorScreen = withStyles(useStyles)(HomebrokerErrorScreen)
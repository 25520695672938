import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import LockIcon from '@material-ui/icons/Lock';
const useStyles = theme => ({
    root: {
        padding: 0,
    },
    changePasswordIcon: {
        marginRight: theme.spacing(2),
        color: theme.palette.grey,
        width: '20px'
    },
    text: {
        fontWeight: 'bold'
    },
    startIcon: {
        margin: 0
    }
});

class ChangePasswordButton extends React.Component {

    render() {
        const {classes} = this.props;
        return (
           
            <Button data-e2e="change-password-button"
                    onClick={this.props.onChangePasswordClicked}
                    className={classes.root}
                    classes={{startIcon: classes.startIcon}}
                    >
                <LockIcon color="blue" classname = {classes.changePasswordIcon}/>
                <Typography variant="subtitle1" className={classes.text}>Cambiar Contraseña</Typography>
            </Button>
        )
    }

}

export default withStyles(useStyles)(ChangePasswordButton)

import React from "react";
import logoutIcon from "../../../img/icons/logoutButton.svg";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = theme => ({
    root: {
        padding: 0,
    },
    logoutIcon: {
        marginRight: theme.spacing(1),
        color: theme.palette.grey[500],
        width: '20px'
    },
    text: {
        fontWeight: 'bold'
    },
    startIcon: {
        margin: 0
    }
});

class LogoutButton extends React.Component {

    render() {
        const {classes} = this.props;
        return (
            <Button data-e2e="logout-button"
                    onClick={this.props.onLogout}
                    className={classes.root}
                    classes={{startIcon: classes.startIcon}}
                    startIcon={<img src={logoutIcon} alt="logout button" className={classes.logoutIcon}/>}>
                <Typography variant="subtitle1" className={classes.text}>Cerrar sesión</Typography>
            </Button>
        )
    }

}

export default withStyles(useStyles)(LogoutButton)

import React from "react";
import Typography from "@material-ui/core/Typography";
import promotionalImage from "../../img/promotional-image.png";
import withStyles from "@material-ui/core/styles/withStyles";

const imgStyle = {
    maxHeight: '55vh',
    maxWidth: '100%'
};

const useStyles = theme => ({
    root: {
        textAlign: 'center',
        color: theme.palette.common.white
    },
    emphaticText: {
        fontWeight: theme.typography.fontWeightBold
    },
    img: imgStyle,
    upperText: {
        marginBottom: `calc(min(${imgStyle.maxHeight}, ${imgStyle.maxWidth}) * -0.12)`
    },
    lowerText: {
        marginTop: `calc(min(${imgStyle.maxHeight}, ${imgStyle.maxWidth}) * -0.08)`
    }
})

export let PromotionalInformation = class PromotionalInformation extends React.Component {
    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.upperText}>
                    <Typography component="p" variant="h5">
                        Tradeá como los
                    </Typography>
                    <Typography component="p" variant="h5" className={classes.emphaticText}>
                        expertos
                    </Typography>
                </div>
                <img alt="app screenshot" src={promotionalImage} className={classes.img}/>
                <div className={classes.lowerText}>
                    <Typography component="p" variant="h6">
                        Plataformas intuitivas y potentes
                    </Typography>
                    <Typography component="p" variant="body2">
                        para day-traders o
                    </Typography>
                    <Typography component="p" variant="body2">
                        inversores principiantes
                    </Typography>
                </div>
            </div>);
    }
}

PromotionalInformation = withStyles(useStyles)(PromotionalInformation)

import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {Typography} from "@material-ui/core";
import {WithdrawalSuccessScreen} from "./WithdrawalSuccessScreen";

const useStyles = _theme => ({
    emailAddress: {
        color: '#6B3EFF',
    }
})

export class WithdrawalEmailSentScreen extends React.Component {
    render() {
        const {classes} = this.props;
        return (
            <WithdrawalSuccessScreen
                data-e2e="withdraw-email-sent-screen"
                title={
                    <>
                        Confirmá la extracción en tu email: <Typography component="span" variant="h6"
                                                                        className={classes.emailAddress}>
                        {this.props.emailAddress}</Typography>
                    </>
                }
                subtitle="Revisá tu casilla de email para confirmar la operación. Si necesitás más información, por
                favor comunicate con nosotros a través de Eco Chat, opción 9."
                onGoBackToPortfolio={this.props.onGoBackToPortfolio}/>
        )
    }
}

WithdrawalEmailSentScreen = withStyles(useStyles)(WithdrawalEmailSentScreen)
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {FormControl, FormControlLabel, RadioGroup} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ConfigurationSectionRadio from "./ConfigurationSectionRadio";
import {OPEN_MATRIZ_ACTION, OPEN_TALARIS_ACTION} from "../../../../system/trading_actions/trading_action_types";

const useStyles = theme => ({
    form: {
        color: theme.palette.text.secondary,
        padding: theme.spacing(3, 0, 3, 6)
    },
    formTitles: {
        marginBottom: theme.spacing(2)
    },
    title: {
        fontWeight: 'bold'
    },
});

export class PreferredTradingActionConfiguration extends React.Component {

    constructor(props) {
        super(props);

        this.handleChangePreferred = this.handleChangePreferred.bind(this);
    }

    handleChangePreferred(event) {
        const newTradingActionType = event.target.value;
        if (this.hasChangeSelection(newTradingActionType)) {
            this.props.onPreferredTradingActionTypeChange(newTradingActionType);
        }
    }

    hasChangeSelection(newTradingActionType) {
        return newTradingActionType != null;
    }

    render() {
        const {classes} = this.props;
        return (
            <FormControl component="fieldset" className={classes.form}>
                <div className={classes.formTitles}>
                    <Typography className={classes.title}>Ruteador</Typography>
                    <Typography>¿Cuál querés usar para este dispositivo?</Typography>
                </div>
                <RadioGroup name="Ruteador preferido" value={this.props.preferredTradingActionType}
                            onChange={this.handleChangePreferred}>
                    <FormControlLabel value={OPEN_MATRIZ_ACTION}
                                      data-e2e="matriz-trading-action-button"
                                      control={<ConfigurationSectionRadio/>}
                                      label="Matriz"/>
                    <FormControlLabel value={OPEN_TALARIS_ACTION}
                                      data-e2e="talaris-trading-action-button"
                                      control={<ConfigurationSectionRadio/>}
                                      label="Talaris"/>
                </RadioGroup>
            </FormControl>
        )
    }
}


PreferredTradingActionConfiguration = withStyles(useStyles)(PreferredTradingActionConfiguration)

import {OPEN_MATRIZ_ACTION, OPEN_TALARIS_ACTION} from "../../system/trading_actions/trading_action_types";

export class TradingActionLogic {
    constructor(storage) {
        this._storage = storage;
    }

    tradingActionCanBeToggled(tradingAction) {
        return [OPEN_MATRIZ_ACTION, OPEN_TALARIS_ACTION].includes(tradingAction);
    }

    getPreferredTradingActionType(defaultTradingActionType) {
        if (this.tradingActionCanBeToggled(defaultTradingActionType)) {
            const savedTradingActionType = this._storage.getSavedTradingActionType();
            if (savedTradingActionType != null) {
                return savedTradingActionType;
            }
        }

        return defaultTradingActionType;
    }

    changePreferredTradingActionType(newTradingActionType) {
        this._storage.savePreferredTradingActionType(newTradingActionType);
    }
}

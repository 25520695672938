export class PopoverModel {
    constructor() {
        this._anchorElement = null;
    }

    // actions

    openAnchoredTo(anElement) {
        this._anchorElement = anElement;
    }

    close() {
        this._anchorElement = null;
    }

    // accessing

    isOpen() {
        return this._anchorElement !== null;
    }

    anchorElement() {
        return this._anchorElement
    }
}
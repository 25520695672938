import React from "react";
import {Typography} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import waitingStatusIcon from "../../img/icons/withdraw-waiting.svg"
import {WithdrawalStatusScreen} from "./WithdrawalStatusScreen";

const useStyles = theme => ({
    title: {
        fontWeight: theme.typography.fontWeightMedium,
        margin: theme.spacing(1, 0, 2, 0)
    }
})

export class WithdrawalSuccessScreen extends React.Component {
    render() {
        const {classes} = this.props;
        return (
            <WithdrawalStatusScreen data-e2e={this.props['data-e2e']}
                                    iconImageSrc={waitingStatusIcon}
                                    title={
                                        <Typography component="h1" variant="h5" className={classes.title}>
                                            {this.props.title}
                                        </Typography>
                                    }
                                    subtitle={this.props.subtitle}
                                    onGoBackToPortfolio={this.props.onGoBackToPortfolio}/>
        )
    }
}

WithdrawalSuccessScreen = withStyles(useStyles)(WithdrawalSuccessScreen)
import React from "react";
import {Typography} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import {BlueButton} from "../../ui_common/buttons/BlueButton";

const useStyles = theme => ({
    root: {
        textAlign: 'center',
        margin: theme.spacing(0, 2)
    },
    mainIcon: {
        width: '200px'
    },
    subtitle: {
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightMedium,
        margin: theme.spacing(4, 0)
    },
    infoBox: {
        marginBottom: theme.spacing(10)
    }
})

export class WithdrawalStatusScreen extends React.Component {
    render() {
        const classes = this.props.classes;
        return (
            <div data-e2e={this.props['data-e2e']} className={classes.root}>
                <div className={classes.infoBox}>
                    <img src={this.props.iconImageSrc} alt="Imagen de esperando" className={classes.mainIcon}/>
                    {this.props.title}
                    <Typography component="p" className={classes.subtitle}>
                        {this.props.subtitle}
                    </Typography>
                </div>
                <BlueButton data-e2e="go-back-to-portfolio"
                            onClick={this.props.onGoBackToPortfolio}>
                    Volver al EcoPortfolio
                </BlueButton>
            </div>
        )
    }
}

WithdrawalStatusScreen = withStyles(useStyles)(WithdrawalStatusScreen)
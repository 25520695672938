export class Cuit {

    static isValid(aCuitStringWithoutHyphens) {
        if (aCuitStringWithoutHyphens.length !== 11) {
            return false;
        }

        let accumulated = 0;
        let digits = aCuitStringWithoutHyphens.split('');
        let lastDigit = parseInt(digits.pop());

        for (let i = 0; i < digits.length; i++) {
            accumulated += digits[9 - i] * (2 + (i % 6));
        }

        let verification = 11 - (accumulated % 11);
        if (verification === 11) {
            verification = 0;
        } else if (verification === 10) {
            verification = 9;
        }

        return lastDigit === verification;
    }
}

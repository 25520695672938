export class TradingActionBrowserStorage {

    static PREFERRED_TRADING_ACTION_TYPE = 'preferredTradingActionType';

    savePreferredTradingActionType(newTradingActionType) {
        localStorage.setItem(TradingActionBrowserStorage.PREFERRED_TRADING_ACTION_TYPE, newTradingActionType);
    }

    getSavedTradingActionType() {
        return localStorage.getItem(TradingActionBrowserStorage.PREFERRED_TRADING_ACTION_TYPE);
    }
}
import React from "react";
import {HowToListItem} from "../ui_common/HowToListItem";
import {HowTo} from "../ui_common/HowTo";
import Grid from "@material-ui/core/Grid";
import {withStyles} from "@material-ui/core";

const useStyles = theme => ({
    howToWithdrawContainer: {
        marginTop: theme.spacing(5)
    }
});

export class HowToWithdraw extends React.Component {

    // rendering

    render() {
        return (
            <Grid container className={this.props.classes.howToWithdrawContainer}>
                <Grid item xs={12}>
                    <HowTo xsTitle="Para Extraer" smUpTitle="¿Cómo solicito una extracción?">
                        <HowToListItem text="Ingresá a EcoPortfolio"/>
                        <HowToListItem
                            text="En la sección de Retiros al fondo de la pantalla, seleccioná si retirás Pesos o Dólar MEP"/>
                        <HowToListItem text="Ingresá tu solicitud de retiro y la procesaremos a la brevedad"/>
                    </HowTo>
                </Grid>
            </Grid>
        );
    }
}

HowToWithdraw = withStyles(useStyles)(HowToWithdraw);

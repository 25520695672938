import React from "react";
import {Fab} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import ecoChatIcon from "../../img/logo-eco-chat.svg";

const useStyles = theme => ({
    root: {
        backgroundColor: '#6B3EFF',
        borderRadius: '14px',
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: '#4B1EDF'
        }
    },
    smallSizeFab: {
        padding: '14px'
    },
    normalSizeFab: {
        padding: '28px'
    },
    text: {
        fontWeight: theme.typography.fontWeightBold,
        paddingBottom: '3px'
    },
    smallSizeText: {
        fontSize: theme.typography.subtitle2.fontSize
    },
    normalSizeText: {
        fontSize: theme.typography.h6.fontSize
    },
    icon: {
        marginRight: theme.spacing(1)
    }
});

const ECO_CHAT_URL = "https://api.whatsapp.com/send/?phone=5491125017985&text=hola&app_absent=0";

export class EcoChatButton extends React.Component {

    renderFab(fabSizeClass, textSizeClass) {
        const {classes} = this.props;

        return (
            <Fab variant="extended"
                 aria-label="Eco chat"
                 className={`${classes.root} ${fabSizeClass}`}
                 href={ECO_CHAT_URL}
                 target="_blank">
                <img src={ecoChatIcon} className={classes.icon} alt="Chat icon"/>
                <Typography component="p" className={`${classes.text} ${textSizeClass}`}>
                    Eco Chat
                </Typography>
            </Fab>
        );
    }

    render() {
        const {classes} = this.props;

        if (this.props.smallSize) {
            return this.renderFab(classes.smallSizeFab, classes.smallSizeText);
        } else {
            return this.renderFab(classes.normalSizeFab, classes.normalSizeText);
        }
    }
}

EcoChatButton = withStyles(useStyles)(EcoChatButton);

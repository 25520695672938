import {ColumnsFilterFactory} from "./ColumnsFilterFactory";

export class PortfolioHoldingsColumnsFilters {

    static defaultFilters(storage) {
        return new ColumnsFilterFactory().createColumnsFilter(storage);
    }

    static byHoldingType(columnsFilterByHoldingType, storage) {
        return new this(columnsFilterByHoldingType, storage);
    }

    constructor(columnsFilterByHoldingType, storage) {
        this._columnsFilterByHolding = columnsFilterByHoldingType;
        this._storage = storage;
    }

    // actions

    toggleColumn(columnName) {
        this._columnsFilterByHolding[this._currentColumnsFilter].toggleColumn(columnName);
    }

    applyFilter() {
        this._columnsFilterByHolding[this._currentColumnsFilter].applyFilter();
        this._columnsFilterByHolding[this._currentColumnsFilter].saveFilter((columnsToSave) =>
            this._storage.storeFiltersConfigurationFor(this._currentColumnsFilter, columnsToSave)
        );
    }

    startColumnsFilterOf(holdingType) {
        this._currentColumnsFilter = holdingType;
    }

    // testing - filter

    isColumnChecked(columnName) {
        return this._columnsFilterByHolding[this._currentColumnsFilter].isColumnChecked(columnName);
    }

    canApplyFilter() {
        return this._columnsFilterByHolding[this._currentColumnsFilter].canApplyFilter();
    }

    isColumnAvailableToCheck(columnName) {
        return this._columnsFilterByHolding[this._currentColumnsFilter].isColumnAvailableToCheck(columnName);
    }

    // testing - columns

    shouldDisplayColumn(holdingType, columnName, currentWidthBreakpoint) {
        return this._columnsFilterByHolding[holdingType].shouldDisplayColumn(columnName, currentWidthBreakpoint);
    }
}
import React from "react";
import { Grid, Hidden, isWidthUp } from "@material-ui/core";
import { PositionDetailsTable } from "./PositionDetailsTable";
import {
  BOND,
  FUTURE,
  LETTER,
  MUTUAL_FUNDS,
  NEGOTIABLE_OBLIGATION,
  OPTION_FUTURE,
  STOCK,
  STOCK_OPTION,
} from "../../../financial/instrument_types";
import Skeleton from "react-loading-skeleton";
import { PortfolioLegalMessage } from "./PortfolioLegalMessage";
import withWidth from "@material-ui/core/withWidth";
import { PortfolioSummary } from "./PortfolioSummary";
import { RouterButtons } from "../../ui_common/buttons/RouterButtons";
import Alert from "@material-ui/lab/Alert";
import { DOLLAR_MEP, PESO } from "../../../financial/moneys";
import { StandardContainerScreen } from "../../layout/ui/StandardContainerScreen";
import { OperationButton } from "./OperationButton";
import depositIcon from "../../img/icons/deposit-icon.png";
import withdrawalDollarIcon from "../../img/icons/withdrawal-dollar-icon.png";
import withdrawalPesoIcon from "../../img/icons/withdrawal-peso-icon.png";
import { ClientAccountSelect } from "./ClientAccountSelect";

export class Portfolio extends React.Component {
  // accessing - private

  isPortfolioLoaded() {
    return this.props.portfolio !== null;
  }

  investorInstrumentTypes() {
    const allInstrumentTypes = [
      [STOCK, "Acciones"],
      [BOND, "Bonos"],
      [LETTER, "Letras"],
      [NEGOTIABLE_OBLIGATION, "Obligaciones Negociables"],
      [STOCK_OPTION, "Opciones de Acciones"],
      [FUTURE, "Futuros"],
      [OPTION_FUTURE, "Opciones sobre Futuros"],
      [MUTUAL_FUNDS, "Fondos Comunes de Inversión"],
    ];

    return allInstrumentTypes.filter(
      ([type, _name]) => this.props.portfolio.positionsDetails[type].length > 0,
    );
  }

  portfolioTotalsForToday() {
    if (this.isPortfolioLoaded()) {
      return this.props.portfolio.portfolioTotals;
    } else {
      return null;
    }
  }

  // rendering

  renderNotifications() {
    if (this.props.serviceUnavailable) {
      return (
        <Grid item xs={12}>
          <Alert
            severity="warning"
            data-e2e="service-unavailable-warning-message"
          >
            El servicio no está disponible en este momento. Esta información
            puede no estar actualizada.
          </Alert>
        </Grid>
      );
    }
  }

  render() {
    const isDesktop = isWidthUp("md", this.props.width);

    return (
      <StandardContainerScreen data-e2e="portfolio">
        <Grid container spacing={3}>
          {this.renderNotifications()}
          <Hidden lgUp>
            <Grid item xs={12}>
              <RouterButtons
                onHomebrokerClicked={this.props.onHomebrokerClicked}
                onMatrizClicked={this.props.onMatrizClicked}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {window._env.REACT_APP_DISABLED_FEATURES !==
              "CHANGE_CLIENT_ACCOUNT_FEATURE" ? (
                <Grid item xs={12} lg="auto">
                  <Grid container>
                    <Grid item xs={12} md="auto">
                      <ClientAccountSelect
                        loggedInUser={this.props.loggedInUser}
                        selectedClientAccount={this.props.selectedClientAccount}
                        onSelectClientAccount={this.props.onSelectClientAccount}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              <Grid item xs={12} md="auto">
                <OperationButton
                  data-e2e={"deposit-button"}
                  onClick={this.props.onDepositClick}
                  icon={depositIcon}
                  title="Depositar"
                />
              </Grid>
              {window._env.REACT_APP_DISABLED_FEATURES !==
              "WITHDRAWAL_FEATURE" ? (
                <>
                  <Grid item xs={12} md="auto">
                    <OperationButton
                      data-e2e={`go-to-withdrawal-${PESO.toString()}`}
                      onClick={this.props.onWithdrawalPesoClick}
                      icon={withdrawalPesoIcon}
                      title="Retirar pesos"
                    />
                  </Grid>
                  <Grid item xs={12} md="auto">
                    <OperationButton
                      data-e2e={`go-to-withdrawal-${DOLLAR_MEP.toString()}`}
                      onClick={this.props.onWithdrawalDollarsClick}
                      icon={withdrawalDollarIcon}
                      title="Retirar dólares"
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <PortfolioSummary
              portfolio={this.props.portfolio}
              isDesktop={isDesktop}
              onDepositClick={this.props.onDepositClick}
            />
          </Grid>
          <Grid item xs={12}>
            {this.isPortfolioLoaded() ? (
              this.investorInstrumentTypes().map(
                ([instrumentType, instrumentName], index) => (
                  <PositionDetailsTable
                    key={index}
                    holdingType={instrumentType}
                    titleTypeName={instrumentName}
                    data={this.props.portfolio.positionsDetails[instrumentType]}
                    preferredTradingActionType={
                      this.props.preferredTradingActionType
                    }
                    onOpenColumnsFilter={this.props.onOpenColumnsFilter}
                    defaultExpanded={index === 0 || isDesktop}
                    columnsModel={this.props.columnsModel}
                  />
                ),
              )
            ) : (
              <Skeleton height={500} />
            )}
          </Grid>
          <Grid item xs={12}>
            <PortfolioLegalMessage />
          </Grid>
        </Grid>
      </StandardContainerScreen>
    );
  }
}

Portfolio = withWidth()(Portfolio);

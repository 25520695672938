import React from "react";
import {Hidden} from "@material-ui/core";
import {BlueButton} from "./BlueButton";
import {WhiteButton} from "./WhiteButton";

export class LoggedLinkButton extends React.Component {
  
  constructor(props) {
    super(props);

    this.handleLoggedLinkClicked = this.handleLoggedLinkClicked.bind(this);
    this.tilte = props.title;
  }


  handleLoggedLinkClicked(event) {
    this.props.onLoggedLinkClicked();
  }

    render() {
        return (
            <>
                <Hidden lgUp>
                    <WhiteButton {...this.props} onClick={this.handleLoggedLinkClicked}>{this.props.children} {this.props.title} </WhiteButton>
                </Hidden>
                <Hidden mdDown>
                    <BlueButton {...this.props} onClick={this.handleLoggedLinkClicked}>{this.props.children} {this.props.title} </BlueButton>
                </Hidden>
            </>
        )
    }

    
}
import {LoggedInUser} from "./login/LoggedInUser";

export class LoggedInUserStorage {

    LOGGED_IN_USER_KEY = 'loggedInUser'

    saveLoggedInUser(newLoggedInUser) {
        localStorage.setItem(this.LOGGED_IN_USER_KEY, JSON.stringify({
            userToken: newLoggedInUser.userToken(),
            username: newLoggedInUser.username(),
            accountsNumbers: newLoggedInUser.accountsNumbers()
        }));
    }

    removeLoggedInUser() {
        localStorage.removeItem(this.LOGGED_IN_USER_KEY);
    }

    getLoggedInUserOrNull() {
        const loggedInUserAsJson = localStorage.getItem(this.LOGGED_IN_USER_KEY);
        if (loggedInUserAsJson !== null) {
            const serializedLoggedInUser = JSON.parse(loggedInUserAsJson);
            return LoggedInUser.newWith(
                serializedLoggedInUser.userToken,
                serializedLoggedInUser.username,
                serializedLoggedInUser.accountsNumbers);
        } else {
            return null;
        }
    }
}

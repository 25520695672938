import React from 'react';
import ReactDOM from 'react-dom';
import { Screen } from "./web_ui/layout/ui/Screen";
import * as serviceWorker from './serviceWorker';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from "@material-ui/styles";
import { PortfolioEcoSystem } from "./PortfolioEcoSystem";
import { CssBaseline } from "@material-ui/core";
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";

// if (window._env.REACT_APP_ENVIRONMENT === "production") {
//     Sentry.init({
//         dsn: "https://8f5efce50eb648a7ad7c32c940badb67@o73280.ingest.sentry.io/5681417",
//         integrations: [new Integrations.BrowserTracing()],
//         tracesSampleRate: 0,
//     });
// }

// bug de material ui: cambia precedencia en producción
// https://github.com/mui-org/material-ui/issues/16609
export function highCssPrecedence() {
  return { index: 1 };
}

const defaultTheme = createMuiTheme();

const theme = createMuiTheme({
  mixins: {
    toolbar: {
      minHeight: '80px',
      [defaultTheme.breakpoints.down('sm')]: {
        minHeight: '80px',
      }
    },
    footer: {
      height: '150px'
    }
  },
  palette: {
    primary: {
      main: "#3fb498",
      contrastText: "#ffffff"
    },
    text: {
      primary: "#2f3a4d",
      secondary: "#6d7584",
    },
    secondary: {
      main: "#3fb498"
    },
    info: {
      main: "#3D7AD5"
    },
    action: {
      disabledBackground: '#6A717F',
      disabled: defaultTheme.palette.common.white
    }
  },
  shadows: Object.assign([], defaultTheme.shadows, {
    1: "0px 1px 24px 0px #C9CFDD50"
  }),
  typography: {
    fontFamily: '"Inter", sans-serif',
    button: {
      textTransform: 'none'
    }
  },
  shape: {
    borderRadius: "14px"
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover $notchedOutline": {
          borderColor: '#E8EAEF'
        }
      }
    },
    MuiTableCell: {
      root: {
        borderBottomColor: "#EDF2F9"
      }
    },
    MuiAlert: {
      root: {
        borderStyle: 'solid',
        borderWidth: '0.5pt',
      },
      icon: {
        color: 'inherit !important',
      },
      standardInfo: {
        color: "#3D7AD5",
        backgroundColor: "#F5F7FD"
      },
      standardError: {
        color: "#FF5555",
        backgroundColor: "#FFFAFA"
      },
      standardWarning: {
        color: "#FFAD01",
        backgroundColor: "#FDFCF5"
      }
    },
  },
});

export const system = new PortfolioEcoSystem();

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Screen />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from "react";
import { Box, Grid, Paper, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Alert, AlertTitle } from "@material-ui/lab";
import { BlueButton } from "../../../ui_common/buttons/BlueButton";
import Divider from "@material-ui/core/Divider";
import { WithdrawalRequest } from "../../../../financial/WithdrawalRequest";
import { FormSectionCompletionAssistant } from "../../../form_assistants/FormSectionCompletionAssistant";
import { FormFieldCompletionAssistant } from "../../../form_assistants/FormFieldCompletionAssistant";
import { PESO } from "../../../../financial/moneys";
import { MoneyPrinter } from "../../../MoneyPrinter";
import { WithdrawalFormReadonlyField } from "./WithdrawalFormReadonlyField";
import { WithdrawalFormTextField } from "./WithdrawalFormTextField";
import { WithdrawalFormMoneyField } from "./WithdrawalFormMoneyField";
import Button from "@material-ui/core/Button";
import { TextFieldCompletionAssistant } from "../../../form_assistants/TextFieldCompletionAssistant";

const useStyles = (theme) => ({
  section: {
    padding: theme.spacing(2, 3),
  },
  title: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  paper: {
    margin: theme.spacing(2, 0),
  },
  buttonsContainer: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      textAlign: "center",
      padding: theme.spacing(0, 16),
    },
  },
  buttonText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  submitButton: {
    marginBottom: theme.spacing(2),
  },
  upAlert: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomColor: "#6583FE",
  },
  downAlert: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTopWidth: 0,
  },
  processingWithdrawal: {
    borderColor: 'red',
    color: 'red',
  },
});

export class WithdrawalForm extends React.Component {
  constructor(props) {
    super(props);
    this.formCompletionAssistant = props.formCompletionAssistant;
  }

  static createFormAssistant() {
    const bankAccountIdAssistant = TextFieldCompletionAssistant.handling(
      WithdrawalRequest.WITHDRAWAL_BANK_ACCOUNT_ASSERTION_ID,
      (withdrawalRequest) => withdrawalRequest.bankAccountId(),
    );
    const withdrawalAmountAssistant = FormFieldCompletionAssistant.handling(
      WithdrawalRequest.WITHDRAWAL_AMOUNT_ASSERTION_ID,
      (withdrawalRequest) => withdrawalRequest.withdrawalAmount(),
      "",
    );

    const withdrawalRequestAssistant = FormSectionCompletionAssistant.with(
      [bankAccountIdAssistant, withdrawalAmountAssistant],
      undefined,
      [],
    );

    withdrawalRequestAssistant.withdrawalAmountAssistant =
      withdrawalAmountAssistant;
    withdrawalRequestAssistant.bankAccountIdAssistant = bankAccountIdAssistant;

    return withdrawalRequestAssistant;
  }

  // testing

  withdrawHasNoCost() {
    return this.props.costPerWithdrawal.amount() === 0;
  }

  isNotAbleToWithdraw() {
    return (
      this.formCompletionAssistant.isIncomplete() ||
      this.props.isProcessingWithdrawal
    );
  }

  // rendering

  render() {
    return (
      <>
        {this.renderLegalMessage()}
        {this.renderTitle()}
        {this.renderForm()}
      </>
    );
  }

  renderLegalMessage() {
    return this.renderPesosLegalMessage()
  }

  renderPesosLegalMessage() {
    return (
      <Alert severity="info">
        <AlertTitle>
          Los retiros se procesan los días hábiles de 10:00 a 17:00 hs.
        </AlertTitle>
        {this.renderCbuLegalMessage()}
      </Alert>
    );
  }

  // Not anymore
  renderDollarsLegalMessage() {
    const { classes } = this.props;
    return (
      <>
        <Alert className={classes.upAlert} severity="info">
          {this.renderCbuLegalMessage()}
        </Alert>
        <Alert
          className={classes.downAlert}
          severity="info"
          data-e2e={"dollars-withdrawal-legal-message"}
        >
          <Typography variant="caption">
            A partir de la 2da transferencia que recibas durante el mes
            calendario en tu Cuenta Bancaria en Dólares, tu entidad bancaria
            podría{" "}
            <b> requerir que justifiques el motivo de la transferencia</b>.
          </Typography>
        </Alert>
      </>
    );
  }

  renderCbuLegalMessage() {
    return (
      <Typography variant="caption">
        Por razones de seguridad solo podras transferir a un CBU del mismo
        titular de esta cuenta.
      </Typography>
    );
  }

  renderTitle() {
    const { classes } = this.props;
    const titleByCurrency =
      this.props.currency === PESO ? "Retirar pesos" : "Retirar dólares";
    return <Typography className={classes.title}>{titleByCurrency}</Typography>;
  }

  renderForm() {
    const { classes } = this.props;
    const bankAccountLabelByCurrency =
      this.props.currency === PESO ? "CBU / CVU / ALIAS" : "CBU / ALIAS";
    return (
      <form noValidate data-e2e="withdrawal-request-form">
        <Paper className={classes.paper}>
          <div className={classes.section}>
            <WithdrawalFormReadonlyField
              label="Nombre y apellido"
              data-e2e="account-holder"
              value={this.props.accountHolder}
            />
          </div>
          <Divider />
          <div className={classes.section}>
            <WithdrawalFormReadonlyField
              label="Número de cuenta Eco"
              data-e2e="account-number"
              value={this.props.accountNumber}
            />
          </div>
          <Divider />
          <div className={classes.section}>
            <WithdrawalFormTextField
              label={bankAccountLabelByCurrency}
              data-e2e="withdrawal-bankAccountId-input"
              name="bankAccountId"
              fieldAssistant={
                this.formCompletionAssistant.bankAccountIdAssistant
              }
              onFieldChange={this.props.onFormChange}
            />
            <WithdrawalFormMoneyField
              label="Monto"
              data-e2e="withdrawal-amount-input"
              name="amount"
              currency={this.props.currency}
              fieldAssistant={
                this.formCompletionAssistant.withdrawalAmountAssistant
              }
              onFieldChange={this.props.onFormChange}
            />
            {this.renderCostPerWithdrawal()}
          </div>
          <Divider />
          <div className={classes.section}>
            {this.renderAvailableToWithdraw()}
          </div>
        </Paper>
        {this.renderButtons()}
      </form>
    );
  }

  renderCostPerWithdrawal() {
    const costPerWithdrawalAsString =
      new MoneyPrinter().printObjectUsingLongNotation(
        this.props.costPerWithdrawal,
      );
    return (
      <p data-e2e="cost-per-withdrawal">
        {this.withdrawHasNoCost()
          ? "Esta extracción no tiene costo"
          : `Se debitará ${costPerWithdrawalAsString} por costo de extracción`}
      </p>
    );
  }

  renderAvailableToWithdraw() {
    const { classes } = this.props;
    const moneyPrinter = new MoneyPrinter();
    const availableToWithdrawFormatted =
      moneyPrinter.printObjectUsingLongNotation(this.props.availableToWithdraw);
    return (
      <Grid container justify="space-between" wrap="nowrap">
        <Grid item>
          <WithdrawalFormReadonlyField
            label="Disponible para retirar"
            data-e2e="available-to-withdraw"
            value={availableToWithdrawFormatted}
          />
        </Grid>
        <Grid item>
          <Box
            height="100%"
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Button
              color="primary"
              disableElevation
              size="large"
              variant="contained"
              onClick={this.props.onWithdrawAll}
              data-e2e="withdraw-all-button"
            >
              <Typography
                className={classes.buttonText}
                component="p"
                variant="subtitle2"
              >
                Retirar todo
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
    );
  }

  renderButtons() {
    const { classes } = this.props;
    return (
      <div className={classes.buttonsContainer}>
        <BlueButton
          data-e2e="withdraw-button"
          fullWidth
          onClick={this.props.onWithdraw}
          className={classes.submitButton}
          disabled={this.isNotAbleToWithdraw()}
        >
          <Typography
            className={classes.buttonText}
            component="p"
            variant="subtitle1"
          >
            Retirar
          </Typography>
        </BlueButton>
        {this.props.isProcessingWithdrawal &&
          <Typography
              className={classes.processingWithdrawal}
              component="p"
              variant="subtitle1"
            >
              Procesando tu requerimiento. Puede demorar hasta 1 minuto.
          </Typography>
          }
        <Button
          color="primary"
          disableElevation
          variant="outlined"
          data-e2e="go-back-to-portfolio"
          onClick={this.props.onGoBackToPortfolio}
          fullWidth
        >
          <Typography
            className={classes.buttonText}
            component="p"
            variant="subtitle1"
          >
            Volver al EcoPortfolio
          </Typography>
        </Button>
      </div>
    );
  }
}

WithdrawalForm = withStyles(useStyles)(WithdrawalForm);

export class CreationError extends Error {
    _failedAssertions;

    constructor(failedAssertions) {
        super();
        this._failedAssertions = failedAssertions;
    }

    // testing

    hasOnlyOneAssertionFailedWith(assertionId, assertionDescription) {
        return this._failedAssertions.length === 1
            && this._failedAssertions[0].isIdentifiedAsWith(assertionId, assertionDescription);
    }

    // accessing

    forEachAssertionFailed(closure) {
        return this._failedAssertions.forEach(closure);
    }
}
import React from "react";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import ExpandMoreSharp from "@material-ui/icons/ExpandMoreSharp";
import Typography from "@material-ui/core/Typography";
import {MenuItem} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = theme => ({
    paper: {
        minWidth: '280px',
        padding: theme.spacing(0, 1)
    },
    root: {
        width: '100%',
        '& .MuiOutlinedInput-input': {
            padding: theme.spacing(0)
        },
        '& fieldset': {
            borderStyle: 'none',
        },
        '&.Mui-focused fieldset': {
            borderStyle: 'none',
        },
        fontSize: '28px',
        '& .MuiSelect-select:focus': {
            background: 'none'
        }
    },
    accountInformation: {
        padding: theme.spacing(2)
    },
    username: {
        color: theme.palette.text.secondary
    }
})

export class ClientAccountSelect extends React.Component {

    userHasASingleAccount() {
        return this.props.loggedInUser.accountsNumbers().length === 1;
    }

    render() {
        const {classes} = this.props;

        return (
            <Paper className={classes.paper} data-e2e="client-account">
                {this.userHasASingleAccount() ?
                    this.renderAccountInformation(this.props.selectedClientAccount)
                    :
                    this.renderSelect()}
            </Paper>
        );
    }

    renderAccountInformation(account) {
        const {classes} = this.props;

        return (
            <div className={classes.accountInformation}>
                <Typography variant="h6">
                    Cuenta: {account}
                </Typography>
                {/*<Typography variant="subtitle2" data-e2e="username" className={classes.username}>*/}
                {/*    CUIT: {this.props.loggedInUser.username()}*/}
                {/*</Typography>*/}
            </div>
        );
    }

    renderSelect() {
        const {classes} = this.props;

        return (
            <Select className={classes.root}
                    data-e2e="client-account-select"
                    value={this.props.selectedClientAccount}
                    onChange={this.props.onSelectClientAccount}
                    variant="outlined"
                    IconComponent={(props) =>
                        <ExpandMoreSharp fontSize="inherit" {...props}/>
                    }
                    renderValue={(value) => this.renderAccountInformation(value)}>
                {this.props.loggedInUser.accountsNumbers().map((accountNumber) =>
                    <MenuItem value={accountNumber} key={accountNumber}>
                        <Typography variant="h6">{accountNumber}</Typography>
                    </MenuItem>
                )}>
            </Select>
        );
    }
}

ClientAccountSelect = withStyles(useStyles)(ClientAccountSelect);

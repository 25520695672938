import React from "react";
import Container from "@material-ui/core/Container";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = theme => ({
    body: {
        padding: theme.spacing(4, 2),
        height: "100%"
    }
});

export class StandardContainerScreen extends React.Component {
    render() {
        const {classes} = this.props;
        return (
            <Container className={`${this.props.className} ${classes.body}`} data-e2e={this.props["data-e2e"]}
                       maxWidth={this.props.maxWidth}>
                {this.props.children}
            </Container>
        )
    }
}

StandardContainerScreen = withStyles(useStyles)(StandardContainerScreen);
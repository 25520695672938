import React from "react";
import {withStyles} from "@material-ui/core";
import {HowToDeposit} from "./HowToDeposit";
import {GoToPortfolioButton} from "../ui_common/GoToPortfolioButton";
import {SmallContainerScreen} from "../layout/ui/SmallContainerScreen";

const useStyles = theme => ({
    backToPortfolioButton: {
        textAlign: 'center',
        marginTop: theme.spacing(4)
    }
});

export class DepositScreen extends React.Component {

    // rendering

    render() {
        const {classes} = this.props;

        return (
            <SmallContainerScreen data-e2e="deposit-information">
                <HowToDeposit/>
                <div className={classes.backToPortfolioButton}>
                    <GoToPortfolioButton data-e2e="go-back-to-portfolio" onClick={() => this.props.onClose()}>
                        Volver al EcoPortfolio
                    </GoToPortfolioButton>
                </div>
            </SmallContainerScreen>
        );
    }
}

DepositScreen = withStyles(useStyles)(DepositScreen);
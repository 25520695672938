import React from "react";
import TableCell from "@material-ui/core/TableCell";
import withStyles from "@material-ui/core/styles/withStyles";
import { MoneyPrinter } from "../../../MoneyPrinter";
import { PercentagePrinter } from "../../../PercentagePrinter";
import CircleMarker from "./HoldingColorMarker";
import {
  ARS_CURRENCY_HOLDING,
  DOLLAR_CABLE_CURRENCY_HOLDING,
  DOLLAR_CABLE_CURRENCY_BANK,
  DOLLAR_MATBA_ROFEX_CURRENCY_HOLDING,
  DOLLAR_MEP_CURRENCY_HOLDING,
  DOLLAR_UY_CURRENCY_HOLDING,
  DOLLAR_DB_CURRENCY_HOLDING,
  DOLLAR_MEP_CURRENCY_BANK,
} from "../../../../financial/instrument_types";
import OneLineCell from "../../../ui_common/OneLineCell";
import TableRow from "@material-ui/core/TableRow";
import { MoneySerializer } from "../../../../serializers/MoneySerializer";

const useStyles = (theme) => ({
  circleMarkerCell: {
    width: theme.spacing(2),
  },
  holdingName: {
    color: theme.palette.text.secondary,
    padding: 0,
  },
  negativePercentage: {
    color: "#FF0000",
  },
});

export class HoldingRow extends React.Component {
  renderTotal() {
    if (this.props.total.amount >= 1000000) {
      return new MoneyPrinter().print(this.props.total);
    }
    return new MoneyPrinter().printUsingLongNotation(this.props.total);
  }

  renderPercentage() {
    return new PercentagePrinter().print(this.props.share);
  }

  isPositive() {
    return this.props.share.value > 0;
  }

  shouldShowEvenIfHoldingAmountIsZero() {
    return this.props.holding.includes([
      ARS_CURRENCY_HOLDING,
      DOLLAR_MEP_CURRENCY_HOLDING,
      DOLLAR_MATBA_ROFEX_CURRENCY_HOLDING,
      DOLLAR_CABLE_CURRENCY_HOLDING,
      DOLLAR_MEP_CURRENCY_BANK,
      DOLLAR_CABLE_CURRENCY_BANK,
    ]);
  }

  render() {
    const { classes } = this.props;
    const money = new MoneySerializer().deserialize(this.props.total);

    if (money.isNotZero() || this.shouldShowEvenIfHoldingAmountIsZero()) {
      return (
        <TableRow
          hover={this.isPositive()}
          onMouseEnter={
            this.isPositive() ? this.props.onMouseEnter : () => null
          }
          onMouseLeave={
            this.isPositive() ? this.props.onMouseLeave : () => null
          }
        >
          <TableCell className={classes.circleMarkerCell}>
            <CircleMarker color={this.props.color} />
          </TableCell>
          <OneLineCell className={classes.holdingName}>
            {this.props.displayName}
          </OneLineCell>
          <TableCell
            align="right"
            className={!this.isPositive() ? classes.negativePercentage : null}
          >
            {this.renderPercentage()}
          </TableCell>
          <TableCell align="right">{this.renderTotal()}</TableCell>
        </TableRow>
      );
    }
    return null;
  }
}

HoldingRow = withStyles(useStyles)(HoldingRow);

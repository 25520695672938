import React from "react";
import {Grid, Snackbar} from "@material-ui/core";
import {Alert, AlertTitle} from "@material-ui/lab";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = theme => ({
    refreshButton: {
        padding: '0',
        textDecoration: "underline",
        "&:hover": {
            textDecoration: "underline",
            backgroundColor: 'transparent'
        }
    },
    subtitle: {
        marginBottom: theme.spacing(1)
    }
});

export class ErrorSnackbar extends React.Component {

    render() {
        return (
            <Snackbar data-e2e={this.props['data-e2e']}
                      open={this.props.isShown}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                      autoHideDuration={this.props['autoHideDuration']}>
                <Alert severity="error">
                    <AlertTitle>
                        {this.props.title}
                    </AlertTitle>
                    {this.props.subtitle !== undefined ? this.renderSubtitle() : null}
                    <Grid container justify="flex-end">
                        <Grid item>
                            {this.renderRefreshButton()}
                        </Grid>
                    </Grid>
                </Alert>
            </Snackbar>
        );
    }

    renderSubtitle() {
        const {classes} = this.props;
        return (
            <Typography variant="body2" className={classes.subtitle}>
                {this.props.subtitle}
            </Typography>
        );
    }

    renderRefreshButton() {
        const {classes} = this.props;
        return (
            <Button data-e2e="refresh-button-unexpected-error"
                    className={classes.refreshButton}
                    color="inherit"
                    disableRipple
                    variant="text"
                    onClick={() => window.location.reload()}>
                Reintente nuevamente
            </Button>
        );
    }
}

ErrorSnackbar = withStyles(useStyles)(ErrorSnackbar);
import React from "react";
import {Typography} from "@material-ui/core";
import {WithdrawalFormFieldContainer} from "./WithdrawalFormFieldContainer";

export class WithdrawalFormReadonlyField extends React.Component {
    render() {
        return (
            <WithdrawalFormFieldContainer label={this.props.label}>
                <Typography data-e2e={this.props["data-e2e"]}>{this.props.value}</Typography>
            </WithdrawalFormFieldContainer>
        );
    }
}
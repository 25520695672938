import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid, Hidden, Typography } from "@material-ui/core";
import { RouterButton } from "./RouterButton";
import withWidth from "@material-ui/core/withWidth";
import { LoggedLinkButton } from "./LoggedLinkButton";

const useStyles = theme => ({
  text: {
    color: theme.palette.text.secondary
  }
})

export class RouterButtons extends React.Component {

  // rendering

  render() {
    const { classes } = this.props;
    return (
      <Grid container spacing={1}>
        <Hidden smDown>
          <Grid item sm={2} container alignContent="center" justify="flex-end">
            <Typography component="p" variant="subtitle1" className={classes.text}>
              Plataformas:
            </Typography>
          </Grid>
        </Hidden>
        <Grid item xs={12/5}>
          <RouterButton href="https://play.google.com/store/apps/details?id=com.primary.talaris"
            target="_blank"
            size="large"
            style={{ textAlign: "center" }}
            fullWidth>
            Matriz Android
          </RouterButton>
        </Grid>
        <Grid item xs={12/5}>
          <RouterButton href="https://apps.apple.com/id/app/talaris/id1066002526"
            target="_blank"
            size="large"
            style={{ textAlign: "center" }}
            fullWidth>
            Matriz iPhone
          </RouterButton>
        </Grid>
        <Grid item xs={12/5}>
          <LoggedLinkButton 
            target="_blank"
            size="large"
            title = "Ingresar a Matriz"
            style={{ textAlign: "center" }}
            onLoggedLinkClicked={this.props.onMatrizClicked}/>
        </Grid>
        <Grid item xs={12/5}>
          <LoggedLinkButton 
            target="_blank"
            size="large"
            style={{ textAlign: "center" }}
            title = "Ingresar a Históricos"
            onLoggedLinkClicked={this.props.onHomebrokerClicked}/>
        </Grid>
      </Grid>
    );
  }
}


RouterButtons = withStyles(useStyles)(withWidth()(RouterButtons));

import {PortfolioHoldingsColumnsFilters} from "./PortfolioHoldingsColumnsFilters";
import {
    BOND,
    FUTURE,
    LETTER,
    MUTUAL_FUNDS,
    NEGOTIABLE_OBLIGATION,
    OPTION_FUTURE,
    STOCK,
    STOCK_OPTION
} from "../../../financial/instrument_types";
import {InstrumentsDetailsColumnsFilter} from "./InstrumentsDetailsColumnsFilter";
import {
    MARKETPRICE,
    MARKETVALUE,
    ORIGINALPRICE,
    PROFITANDLOSS,
    QUANTITY,
    TODAYSPROFITANDLOSS
} from "../../../instruments_details_table_columns";

export class ColumnsFilterFactory {

    createColumnsFilter(storage) {
        const defaultHoldingTypes = [STOCK, BOND, LETTER, NEGOTIABLE_OBLIGATION, STOCK_OPTION, FUTURE, OPTION_FUTURE,
            MUTUAL_FUNDS];
        let columnsFilterByHolding = {};
        defaultHoldingTypes.forEach((holdingType, _index) => {
            columnsFilterByHolding[holdingType] = new InstrumentsDetailsColumnsFilter(
                this._availableColumnsFor(holdingType), this._columnsToDisplayFor(holdingType, storage)
            );
        });

        return PortfolioHoldingsColumnsFilters.byHoldingType(columnsFilterByHolding, storage);
    }

    availableColumns() {
        return [QUANTITY, MARKETPRICE, ORIGINALPRICE, PROFITANDLOSS, TODAYSPROFITANDLOSS, MARKETVALUE];
    }

    // private

    _availableColumnsFor(holdingType) {
        if (this._isFutureType(holdingType)) {
            return this._futuresAvailableColumns();
        }
        if (this._isOptionFutureType(holdingType)) {
            return this._optionFuturesAvailableColumns();
        }
        return this.availableColumns();
    }

    _isFutureType(holdingType) {
        return [FUTURE].includes(holdingType);
    }

    _isOptionFutureType(holdingType) {
        return [OPTION_FUTURE].includes(holdingType);
    }

    _futuresAvailableColumns() {
        return [QUANTITY, MARKETPRICE, ORIGINALPRICE, PROFITANDLOSS, TODAYSPROFITANDLOSS];
    }

    _optionFuturesAvailableColumns() {
        return [QUANTITY];
    }

    _columnsToDisplayFor(holdingType, storage) {
        const columnsToDisplay = storage.retrieveFiltersConfigurationFor(holdingType);
        if (columnsToDisplay !== null) {
            return columnsToDisplay;
        }
        if (this._isFutureType(holdingType)) {
            return this._defaultFuturesColumnsToDisplay();
        }
        if (this._isOptionFutureType(holdingType)) {
            return this._defaultOptionFuturesColumnsToDisplay();
        }
        return this._defaultColumnsToDisplay();
    }

    _defaultColumnsToDisplay() {
        return [QUANTITY, MARKETPRICE, MARKETVALUE];
    }

    _defaultFuturesColumnsToDisplay() {
        return [QUANTITY, MARKETPRICE];
    }

    _defaultOptionFuturesColumnsToDisplay() {
        return [QUANTITY];
    }
}
import {FormFieldCompletionAssistant} from "./FormFieldCompletionAssistant";

export class TextFieldCompletionAssistant extends FormFieldCompletionAssistant {
    static handling(assertionId, fromContainerModelGetter, initialModel = "") {
        return super.handling(assertionId, fromContainerModelGetter, initialModel);
    }

    static handlingAll(assertionsId, fromContainerModelGetter, initialModel = "") {
        return super.handlingAll(assertionsId, fromContainerModelGetter, initialModel);
    }

    //model

    createModel(_creationClosure) {
        const modelWithoutSpacesAtBothSides = this._model.replace(/^\s+|\s+$/g, '');
        this.setModel(modelWithoutSpacesAtBothSides);

        return super.createModel(_creationClosure);
    }
}
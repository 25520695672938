import React from "react";
import {WithdrawalStatusScreen} from "./WithdrawalStatusScreen";
import somethingWentWrongIcon from "../../img/icons/withdraw-something-went-wrong.svg";
import {Typography} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = theme => ({
    title: {
        color: '#EB5757',
        fontWeight: theme.typography.fontWeightMedium,
        margin: theme.spacing(1, 0, 2, 0)
    },
    errorDetail: {
        marginBottom: theme.spacing(2)
    }
})

export class WithdrawalErrorScreen extends React.Component {
    render() {
        const {classes} = this.props;

        return (
            <WithdrawalStatusScreen data-e2e={this.props["data-e2e"]}
                                    iconImageSrc={somethingWentWrongIcon}
                                    title={
                                        <Typography component="h1" variant="h5" className={classes.title}>
                                            {this.props.title}
                                        </Typography>
                                    }
                                    subtitle={this.props.errorDetails.map(error =>
                                        <div className={this.props.classes.errorDetail}
                                             data-e2e="withdrawal-error-detail">
                                            {error}
                                        </div>
                                    )}
                                    onGoBackToPortfolio={this.props.onGoBackToPortfolio}/>
        );
    }
}

WithdrawalErrorScreen = withStyles(useStyles)(WithdrawalErrorScreen)

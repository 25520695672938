import { SimpleHTTPClient } from "./http_client/SimpleHTTPClient";
import { WithdrawalRequestSerializer } from "./serializers/WithdrawalRequestSerializer";

export class PortfolioEcoSystem {

  constructor() {
    this._httpClient = new SimpleHTTPClient();
  }

  configureErrorHandlers(onNetworkError, onUnexpectedError, onSessionExpired, isConnectionError,
    onConnectionRestablished) {
    this._onUnexpectedError = onUnexpectedError;
    this._onSessionExpired = onSessionExpired;
    this._isConnectionError = isConnectionError;
    this._onConnectionRestablished = onConnectionRestablished;

    this._httpClient.configureErrorHandlers(onNetworkError);
  }

  async forgotPassword(userName, ifSuccess, ifFail) {
    await this._httpClient.performPost(
      `/forgotPassword`,
      { 'username': userName },
      this._responseHandler(ifSuccess, ifFail));
  }

  async loginWithCredentials(userName, password, ifSuccess, ifFail) {
    await this._httpClient.performPost(
      `/login`,
      { 'username': userName, 'password': password },
      this._responseHandler(ifSuccess, ifFail));
  }

  async getPortfolio(userToken, accountNumber, ifSuccess, ifFail) {
    await this._httpClient.performAuthenticatedGet(
      userToken,
      `/portfolio/${accountNumber}`,
      this._authenticatedResponseHandler(ifSuccess, ifFail));
  }

  async getPortfolioHistory(userToken, accountNumber, ifSuccess) {
    await this._httpClient.performAuthenticatedGet(
      userToken,
      `/portfolio/${accountNumber}/history`,
      this._authenticatedResponseHandler(ifSuccess, () => { }));
  }

  async withdraw(userToken, withdrawalRequest, ifSuccess, ifFail) {
    const withdrawalRequestSerialized = new WithdrawalRequestSerializer().serialize(withdrawalRequest);

    await this._httpClient.performAuthenticatedPost(
      userToken,
      `/withdraw`,
      withdrawalRequestSerialized,
      this._authenticatedResponseHandler(ifSuccess, ifFail));
  }

  async confirmWithdraw(requestToken, ifSuccess, ifFail) {
    await this._httpClient.performPost(
      '/withdraw/confirm',
      { 'requestToken': requestToken },
      this._responseHandler(ifSuccess, ifFail));
  }

  async userInformationForWithdrawal(userToken, accountNumber, currency, ifSuccess, ifFail) {
    await this._httpClient.performAuthenticatedGet(
      userToken,
      `/withdraw/userInformation/${accountNumber}/${currency}`,
      this._authenticatedResponseHandler(ifSuccess, ifFail));
    }

  async redirectToUrl(userToken, url, ifSuccess, ifFail) {
    await this._httpClient.performAuthenticatedGet(
      userToken,
      url,
      this._authenticatedResponseHandler(ifSuccess, ifFail));
  }
  
  // private

  _responseHandler(ifSuccess, ifFail) {
    return async (response) => {
      const responseResolved = await response;
      const json = await responseResolved.json();
      const isErrorStatusCode = responseResolved.status !== 200;
      const hasErrors = json.errors.length > 0 || isErrorStatusCode;

      if (!hasErrors) {
        const hadConnectionError = this._isConnectionError();
        if (hadConnectionError) {
          this._onConnectionRestablished();
        }
        return ifSuccess(json.object);
      } else {
        const ifFailWithUnexpectedError = this._decorateIfFailWithUnexpectedError(ifFail);

        return ifFailWithUnexpectedError(json.errors);
      }
    }
  }

  _authenticatedResponseHandler(ifSuccess, ifFail) {
    return this._responseHandler(ifSuccess, this._decorateIfFailWithSessionExpired(ifFail));
  }

  _decorateIfFailWith(ifFail, handleErrorDecorator, condition) {
    return (errors) => {
      if (condition(errors)) {
        handleErrorDecorator(errors);
      }
      ifFail(errors);
    };
  }

  _decorateIfFailWithUnexpectedError(ifFail) {
    return this._decorateIfFailWith(ifFail, this._onUnexpectedError, this._isUnexpectedError);
  }

  _decorateIfFailWithSessionExpired(ifFail) {
    return this._decorateIfFailWith(ifFail, this._onSessionExpired, this._isSessionExpired);
  }

  _isSessionExpired(errors) {
    return errors.some(error => error.code === "INVALID_USER_TOKEN");
  }

  _isUnexpectedError(errors) {
    return errors.some(error => error.code === "UNEXPECTED_ERROR_CODE");
  }
}

import React from "react";
import {WhiteButton} from "../../ui_common/buttons/WhiteButton";
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import arrowDropDownCircleIcon from "../../img/icons/arrow-icon.svg";
import withStyles from "@material-ui/core/styles/withStyles";


const useStyles = theme => ({
    button: {
        background: "radial-gradient(50% 80% at 100% 0%, rgba(19, 231, 146, 0.1) 0%, rgba(255, 255, 255, 0) 100%)",
        height: '100%',
        "& .MuiButton-label": {
            height: '100%'
        }
    },
    content: {
        height: '100%'
    },
    operationIcon: {
        height: '80px'
    },
    text: {
        color: "#2F3A4D"
    },
    goToIcon: {
        width: '30px',
        marginTop: theme.spacing(1)
    }
});

export class OperationButtonForWelcomeScreen extends React.Component {
    render() {
        const {classes} = this.props;
        return (
            <WhiteButton data-e2e={this.props['data-e2e']}
                         onClick={this.props.onClick}
                         fullWidth
                         className={classes.button}>
                <Grid container direction="column" justify="space-between" className={classes.content}>
                    <Grid item>
                        <img src={this.props.icon} alt="operation-icon" className={classes.operationIcon}/>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" className={classes.text}>
                            {this.props.title}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">
                            {this.props.subtitle}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <img src={arrowDropDownCircleIcon} alt="go-to-operation" className={classes.goToIcon}/>
                    </Grid>
                </Grid>
            </WhiteButton>
        );
    }
}

OperationButtonForWelcomeScreen = withStyles(useStyles)(OperationButtonForWelcomeScreen);

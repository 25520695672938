import React from "react";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = _theme => ({
    root: {
        backgroundColor: '#6B3EFF',
        color: '#F2F2F2',
        '&:hover': {
            backgroundColor: '#4B1EDF'
        },
        '&.MuiButton-contained.Mui-disabled': {
            backgroundColor: '#91A5B7'
        }
    },
})

export class BlueButton extends React.Component {
    render() {
        const {classes} = this.props;
        return (
            <Button {...this.props}
                    className={`${classes.root} ${this.props.className}`}
                    variant="contained"
                    disableElevation>
                {this.props.children}
            </Button>
        );

    }
}

BlueButton = withStyles(useStyles)(BlueButton);

import React from "react";
import TableCell from "@material-ui/core/TableCell";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = _theme => ({
  cell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '25rem',
    whiteSpace: 'nowrap',
  },
});

class OneLineCell extends React.Component {

  render() {
    const { classes, className, ...other } = this.props;
    return <TableCell className={`${className} ${classes.cell}`}
      {...other} />
  }
}


export default withStyles(useStyles)(OneLineCell);

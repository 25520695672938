import {MoneySerializer} from "../serializers/MoneySerializer";
import {Money} from "../financial/money/money";

export class MoneyPrinter {
    static INVALID_FORMATTED_MONEY = "Invalid formatted money"

    printObjectUsingLongNotation(money) {
        const moneySerialized = new MoneySerializer().serialize(money);
        return this.printUsingLongNotation(moneySerialized);
    }

    moneyFromPrintedMoney(formattedMoney, currency) {
        const replaceBracketedValuesWithNegatives = (aFormattedMoney) =>
            aFormattedMoney.replace(/\((?=\d+)(.*)\)/, "-$1");
        const stripOutAnyCraft = (aFormattedMoney) => {
            const nonValidAmountCharsRegex = new RegExp("[^0-9-" + this._decimalSeparator() + "]", 'g');
            return aFormattedMoney.replace(nonValidAmountCharsRegex, '');
        }
        const replaceDecimalSeparatorWithFloatSeparator = (aFormattedMoney) =>
            aFormattedMoney.replace(this._decimalSeparator(), '.');

        formattedMoney = replaceBracketedValuesWithNegatives(formattedMoney);
        formattedMoney = stripOutAnyCraft(formattedMoney);
        const amountAsString = replaceDecimalSeparatorWithFloatSeparator(formattedMoney);
        const onlyInvalidCharacters = amountAsString === "";
        if (onlyInvalidCharacters) {
            throw Error(MoneyPrinter.INVALID_FORMATTED_MONEY);
        }
        const moneyAmount = parseFloat(amountAsString);
        return Money.withAmountAndCurrency(moneyAmount, currency);
    }

    // accessing

    fractionsDigits() {
        return 2;
    }

    // DEPRECATED protocol

    print(moneySerialized) {
        const moneyAsObj = new MoneySerializer().deserialize(moneySerialized);
        if (moneyAsObj.isUnknown()) {
            return this._printUnknownMoney(moneySerialized);
        }
        return this._printKnownCurrency(moneyAsObj);
    }

    printUsingLongNotation(moneySerialized) {
        const moneyAsObj = new MoneySerializer().deserialize(moneySerialized);
        if (moneyAsObj.isUnknown()) {
            return this._printUnknownMoney(moneySerialized);
        }
        return this._printKnownCurrencyUsingLongNotation(moneyAsObj);
    }

    // private

    _printUnknownMoney(_moneySerialized) {
        return "";
    }

    _printKnownCurrency(money) {
        const shortNotationOptions = {
            notation: 'compact',
            compactDisplay: 'long'
        };
        return this._formatMoney(money, shortNotationOptions);
    }

    _printKnownCurrencyUsingLongNotation(money) {
        return this._formatMoney(money, {});
    }

    _formatMoney(money, additionalFormattingOptions) {
        const moneyFormatter = new Intl.NumberFormat([this._userLocale()], {
            style: 'currency',
            currency: money.currencyCode(),
            minimumFractionDigits: this.fractionsDigits(),
            maximumFractionDigits: this.fractionsDigits(),
            ...additionalFormattingOptions
        });
        return moneyFormatter.format(money.amount());
    }

    _userLocale() {
        return "es-AR";
    }

    _decimalSeparator() {
        if (this._userLocale() === "es-AR") {
            return ",";
        } else {
            throw Error(`Unknown decimal separator for locale ${this._userLocale()}`);
        }
    }
}

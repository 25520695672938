import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Alert from "@material-ui/lab/Alert";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(1, 0),
        '& p': {
            margin: 0,
        }
    }
});

export class DepositWaitingTimeMessage extends React.Component {
    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <Alert severity="info">
                    <p>
                        Este proceso puede demorar en algunos casos hasta 60 minutos, si pasado este tiempo no se
                        acreditó el dinero, entrá al ECO CHAT opción 9 y te asistiremos.
                    </p>
                </Alert>
            </div>
        );
    }
}

DepositWaitingTimeMessage = withStyles(useStyles)(DepositWaitingTimeMessage);

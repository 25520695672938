import React from "react";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import welcomeWaivingHand from "../../img/welcome-waving-hand.svg";
import depositIcon from "../../img/icons/deposit-icon.png";
import withdrawalDollarIcon from "../../img/icons/withdrawal-dollar-icon.png";
import withdrawalPesoIcon from "../../img/icons/withdrawal-peso-icon.png";
import goToPortolioIcon from "../../img/icons/go-to-portfolio-icon.png";
import Grid from "@material-ui/core/Grid";
import {StandardContainerScreen} from "../../layout/ui/StandardContainerScreen";
import {DOLLAR_MEP, PESO} from "../../../financial/moneys";
import {OperationButtonForWelcomeScreen} from "./OperationButtonForWelcomeScreen";

const useStyles = theme => ({
    root: {
        paddingBottom: theme.spacing(9)
    },
    welcomeMessageContainer: {
        textAlign: 'center'
    },
    welcomeMessage: {
        color: theme.palette.common.black,
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.h4.fontSize,
        }
    },
    thanksMessage: {
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.h5.fontSize,
        }
    },
    activeAccountMessage: {
        color: theme.palette.text.secondary,
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.subtitle1.fontSize,
        }
    },
    continueToPortfolioButton: {
        marginTop: theme.spacing(4)
    }
});

export class WelcomeScreen extends React.Component {
    render() {
        const {classes} = this.props;

        return (
            <StandardContainerScreen data-e2e="welcome-screen" className={classes.root}>
                <div className={classes.welcomeMessageContainer}>
                    <img src={welcomeWaivingHand} alt="Waiving hand"/>
                    <Typography variant="h3" className={classes.welcomeMessage}>
                        ¡Bienvenidos a Eco!
                    </Typography>
                    <Typography variant="h4" className={classes.thanksMessage}>
                        Gracias por elegirnos
                    </Typography>
                    <Typography variant="h6" className={classes.activeAccountMessage}>
                        Tu cuenta ya está activa, depositá dinero y empezá a operar online con eTrader, Matriz y
                        Talaris.
                    </Typography>
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={3}>
                        <OperationButtonForWelcomeScreen icon={depositIcon}
                                                         title="Depositar"
                                                         subtitle="Comenzá depositando para poder operar"
                                                         data-e2e="deposit-button"
                                                         onClick={() => this.props.onGoToDeposit()}/>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <OperationButtonForWelcomeScreen icon={withdrawalPesoIcon}
                                                         title="Retirar pesos"
                                                         subtitle="Retirá a tu cuenta bancaria los pesos disponibles"
                                                         data-e2e={`go-to-withdrawal-${PESO.toString()}`}
                                                         onClick={() => this.props.onGoToWithdrawalPesos()}/>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <OperationButtonForWelcomeScreen icon={withdrawalDollarIcon}
                                                         title="Retirar dólares"
                                                         subtitle="Retirá a tu cuenta bancaria los dólares disponibles"
                                                         data-e2e={`go-to-withdrawal-${DOLLAR_MEP.toString()}`}
                                                         onClick={() => this.props.onGoToWithdrawalDollars()}/>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <OperationButtonForWelcomeScreen icon={goToPortolioIcon}
                                                         title="Ver Ecoportfolio"
                                                         subtitle="Revisá tus tenencias y comenzá a operar"
                                                         data-e2e="continue-to-portfolio-button"
                                                         onClick={() => this.props.onContinueToPortfolio()}/>
                    </Grid>
                </Grid>
            </StandardContainerScreen>
        );
    }
}

WelcomeScreen = withStyles(useStyles)(WelcomeScreen)
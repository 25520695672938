import React from "react";
import {EcoTextField} from "./EcoTextField";

export class EcoTextFieldWithoutAssistant extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: ''
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;

        this.setState({
            value: value
        });
    }

    render() {
        return (
            <EcoTextField data-e2e={this.props["data-e2e"]}
                          name={this.props.name}
                          error={this.props.error}
                          InputProps={this.props.InputProps}
                          label={this.props.label}
                          autoFocus={this.props.autoFocus}
                          type={this.props.type}
                          value={this.state.value}
                          onChange={this.handleInputChange}/>
        );
    }
}

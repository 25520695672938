import {CreationAssertion} from "../../financial/assertions/CreationAssertion";

export class FormCompletionAssistant {
    _assertionIds;
    _fromContainerModelGetter;
    _failedAssertions;

    constructor(assertionsId, fromContainerModelGetter) {
        this._assertionIds = assertionsId;
        this._fromContainerModelGetter = fromContainerModelGetter;
        this.removeFailedAssertions();
    }

    withCreatedModelDo(creationClosure, validModelClosure, invalidModelClosure) {
        const createdModel = this.createModel(creationClosure);
        if (CreationAssertion.isInvalidModel(createdModel)) {
            return invalidModelClosure();
        } else {
            return validModelClosure(createdModel);
        }
    }

    // model

    createModel(creationClosure) {
        return this._shouldBeImplementedBySubclass();
    }

    getModel() {
        return this._shouldBeImplementedBySubclass();
    }

    setModel(newModel) {
        this._shouldBeImplementedBySubclass();
    }

    setModelFrom(containerModel) {
        return this.setModel(this._fromContainerModelGetter(containerModel));
    }

    resetModel() {
        this._shouldBeImplementedBySubclass();
    }

    // assertions handling

    handles(assertion) {
        return this._assertionIds.some(assertionId => assertion.isIdentifiedAs(assertionId));
    }

    // failed assertions - add & remove

    addFailedAssertion(assertionFailed) {
        this._failedAssertions.push(assertionFailed);
    }

    removeFailedAssertions() {
        this._failedAssertions = [];
    }

    // failed assertions - accessing

    hasFailedAssertions() {
        return !(this._failedAssertions.length === 0);
    }

    failedAssertionsDescriptions() {
        return this._failedAssertions.map(failedAssertion => failedAssertion.description())
            .filter(description => description !== "");
    }

    // private

    _shouldBeImplementedBySubclass() {
        throw new Error("Should be implemented by subclass");
    }
}


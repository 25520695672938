import React from "react";
import {ColumnsFilter} from "../../portfolio/column_filter/ui/ColumnsFilter";
import {Portfolio} from "../../portfolio/ui/Portfolio";
import {system} from "../../../index";
import {DepositScreen} from "../../deposit/DepositScreen";
import {WelcomeScreen} from "../../portfolio/ui/WelcomeScreen";
import {
    COLUMNS_FILTER_SCREEN,
    DEPOSIT_SCREEN,
    LoggedInScreenNavigator,
    PORTFOLIO_SCREEN,
    WELCOME_SCREEN,
    WITHDRAWAL_SCREEN
} from "../LoggedInScreenNavigator";
import {PortfolioHoldingsColumnsFilters} from "../../portfolio/column_filter/PortfolioHoldingsColumnsFilters";
import {ColumnsFilterBrowserStorage} from "../../portfolio/column_filter/ColumnsFilterBrowserStorage";
import {WithdrawalScreen} from "../../withdrawal/ui/WithdrawalScreen";
import {DOLLAR_MEP, PESO} from "../../../financial/moneys";

export class LoggedInScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            portfolio: null,
            serviceUnavailable: null,
            navigator: new LoggedInScreenNavigator(),
            withdrawCurrency: null,
            isOpeningLoggedLink: false,
            selectedClientAccount: this.props.loggedInUser.accountsNumbers()[0],
            shouldRefresh: true,
        };

        this._refreshPortfolioTimer = null;
        this._columnsFilters = PortfolioHoldingsColumnsFilters.defaultFilters(new ColumnsFilterBrowserStorage());

        this.handleOpenColumnsFilter = this.handleOpenColumnsFilter.bind(this);
        this.handleCloseColumnsFilter = this.handleCloseColumnsFilter.bind(this);
        this.handleOpenDepositClick = this.handleOpenDepositClick.bind(this);
        this.handleContinueToPortfolio = this.handleContinueToPortfolio.bind(this);
        this.handleCloseDepositClick = this.handleCloseDepositClick.bind(this);
        this.handleOpenWithdrawalPesosClick = this.handleOpenWithdrawalPesosClick.bind(this);
        this.handleOpenWithdrawalDollarsClick = this.handleOpenWithdrawalDollarsClick.bind(this);
        this.handleGoBackToPortfolioClick = this.handleGoBackToPortfolioClick.bind(this);
        this.handleSelectClientAccount = this.handleSelectClientAccount.bind(this);

    }

    // lifecycle

    async componentDidMount() {
        await this._getPortfolio();
    }

    componentWillUnmount() {
        this._stopTimerForNextPortfolioRefresh();
    }

    // network

    async _getPortfolio() {
      this.setState({shouldRefresh: false});
      await system.getPortfolio(
        this._userToken(),
        this.state.selectedClientAccount,
        (responseObject) => {
          this.setState({
            portfolio: responseObject,
          });
          this.props.onGetDefaultTradingActionType(
            responseObject.defaultTradingActionType
          );
          this._refreshPortfolioAgainAfterSometime();
        },
        (errors) => {
          let serviceUnavailable = false;
          let shouldRefresh = true;
          for (const error of errors) {
            if (error.code === "SERVICE_UNAVAILABLE") {
              serviceUnavailable = true;
            } else if (error.code === "INVALID_USER_TOKEN") {
              shouldRefresh = false;
            }
          }
          this.setState({ serviceUnavailable: serviceUnavailable });
          shouldRefresh
            ? this._refreshPortfolioAgainAfterSometime()
            : this._stopTimerForNextPortfolioRefresh();
        }
      );
    }

    // refresh portfolio - private

    _stopTimerForNextPortfolioRefresh() {
        if (this._refreshPortfolioTimer != null) {
            clearTimeout(this._refreshPortfolioTimer);
            this._refreshPortfolioTimer = null;
        }
    }

    _refreshPortfolioAgainAfterSometime() {
        this._refreshPortfolioTimer = setTimeout(
            () => this.setState({ shouldRefresh: true }),
            this._refreshTimeInSeconds() * 1000
        );
    }

    _refreshTimeInSeconds() {
        return window._env.REACT_APP_PORTFOLIO_REFRESH_TIME_IN_SECS !== undefined ?
            window._env.REACT_APP_PORTFOLIO_REFRESH_TIME_IN_SECS : 30;
    }

    _check_withrawal_hours(){
        const hour = new Date(new Date().getTime()).getUTCHours() -3;
        console.log(hour)
        if (hour === 9){ 
            alert("Entre 9:00hs y 10:00hs las extracciones no están habilitadas, por favor intentá más tarde. Disculpas por el inconveniente.");
            return false;
        }
        return true;

    }
    // accessing - private

    _userToken() {
        return this.props.loggedInUser.userToken();
    }

    // handlers

    handleOpenColumnsFilter() {
        this.navigateTo(() => this.state.navigator.openFilters());
    }

    handleCloseColumnsFilter() {
        this.navigateTo(() => this.state.navigator.closeFilters());
    }

    handleContinueToPortfolio() {
        this.navigateTo(() => this.state.navigator.continueToPortfolio());
    }

    handleOpenDepositClick() {

        this.navigateTo(() => this.state.navigator.openDeposit());
    }

    handleCloseDepositClick() {
        this.navigateTo(() => this.state.navigator.closeDeposit());
    }

    handleOpenWithdrawalPesosClick() {
        if (this._check_withrawal_hours()) {
            this.navigateTo(() => this.state.navigator.openWithdrawal());
            this.setState({withdrawCurrency: PESO})
        }
    }

    handleOpenWithdrawalDollarsClick() {
        if (this._check_withrawal_hours()) {
            this.navigateTo(() => this.state.navigator.openWithdrawal());
            this.setState({withdrawCurrency: DOLLAR_MEP})    
        }
    }

    handleGoBackToPortfolioClick() {
        this.navigateTo(() => this.state.navigator.goToMainScreen());
    }

    navigateTo(navigatorAction) {
        navigatorAction();
        this.setState({navigator: this.state.navigator});
    }

    handleSelectClientAccount(event) {
        this.setState({selectedClientAccount: event.target.value, portfolio: null}, async () => {
            this._stopTimerForNextPortfolioRefresh()
            await this._getPortfolio();
        });
    }
    // rendering

    render() {
        if ((document.visibilityState === 'visible') && this.state.shouldRefresh) {
            this._getPortfolio();
        }
        return this.state.navigator.screenToShow({
            [WELCOME_SCREEN]: <WelcomeScreen onContinueToPortfolio={this.handleContinueToPortfolio}
                                             onGoToDeposit={this.handleOpenDepositClick}
                                             onGoToWithdrawalPesos={this.handleOpenWithdrawalPesosClick}
                                             onGoToWithdrawalDollars={this.handleOpenWithdrawalDollarsClick}/>,
            [PORTFOLIO_SCREEN]: <Portfolio preferredTradingActionType={this.props.preferredTradingActionType}
                                           onGetDefaultTradingActionType={this.props.onGetDefaultTradingActionType}
                                           onOpenColumnsFilter={this.handleOpenColumnsFilter}
                                           columnsModel={this._columnsFilters}
                                           portfolio={this.state.portfolio}
                                           serviceUnavailable={this.state.serviceUnavailable}
                                           onDepositClick={this.handleOpenDepositClick}
                                           onWithdrawalPesoClick={this.handleOpenWithdrawalPesosClick}
                                           onWithdrawalDollarsClick={this.handleOpenWithdrawalDollarsClick}
                                           loggedInUser={this.props.loggedInUser}
                                           selectedClientAccount={this.state.selectedClientAccount}
                                           onHomebrokerClicked={this.props.onHomebrokerClicked}
                                           onMatrizClicked={this.props.onMatrizClicked}
                                           onSelectClientAccount={this.handleSelectClientAccount}/>,
            [COLUMNS_FILTER_SCREEN]: <ColumnsFilter columnsModel={this._columnsFilters}
                                                    onClose={this.handleCloseColumnsFilter}/>,
            [DEPOSIT_SCREEN]: <DepositScreen onClose={this.handleCloseDepositClick}/>,
            [WITHDRAWAL_SCREEN]: <WithdrawalScreen userToken={this._userToken()}
                                                   onGoBackToPortfolio={this.handleGoBackToPortfolioClick}
                                                   currency={this.state.withdrawCurrency}
                                                   accountNumber={this.state.selectedClientAccount}/>
        })
    }
}

import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import {system} from "../../../index";
import {CheckInboxMessage} from "./CheckInboxMessage"
import {EcoTextFieldWithoutAssistant} from "../../ui_common/EcoTextFieldWithoutAssistant";
import {Link} from "@material-ui/core";
import {Cuit} from "../Cuit";
import Button from "@material-ui/core/Button";
import {LoginTitle} from "./LoginTitle";

const useStyles = theme => ({
    root: {
        width: '100%',
        '& > *': {
            margin: theme.spacing(2, 0)
        }
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        '& > *': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        }
    },
    loading: {
        margin: theme.spacing(4, 0)
    },
    link: {
        fontWeight: theme.typography.fontWeightBold
    }
});

export class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            success: false,
            invalidCuit: false,
            unregisteredCuit: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    // handlers

    async handleSubmit(event) {
        event.preventDefault();

        const userName = event.target.userName.value;

        if (!Cuit.isValid(userName)) {
            this.setState({
                invalidCuit: true
            });
        } else {
            this.setState({
                isLoading: true
            });

            await system.forgotPassword(userName,
                (_responseObject) => {
                    this.setState({
                        isLoading: false,
                        success: true,
                        invalidCuit: false
                    });
                },
                (_errors) => {
                    this.setState({
                        isLoading: false,
                        unregisteredCuit: true,
                        invalidCuit: false
                    });
                });
        }
    }

    // asserts

    userNameInputHasError() {
        return this.state.invalidCuit || this.state.unregisteredCuit;
    }

    // rendering

    renderNotifications() {
        if (this.state.invalidCuit) {
            return (
                <Alert severity="error" data-e2e="forgot-password-invalid-cuit-message">
                    Número de CUIT/CUIL incorrecto
                </Alert>
            );
        }
        if (this.state.unregisteredCuit) {
            return (
                <Alert severity="error" data-e2e="forgot-password-unregistered-cuit-message">
                    Su número de CUIT/CUIL no se encuentra registrado
                </Alert>
            );
        }
    }

    renderForgotPasswordForm() {
        const {classes} = this.props;

        if (this.state.isLoading) {
            return (
                <Grid container justify="center">
                    <CircularProgress className={classes.loading}/>
                </Grid>
            );
        } else {
            return (
                <form className={classes.form} noValidate onSubmit={this.handleSubmit} data-e2e="forgot-password-form">
                    <EcoTextFieldWithoutAssistant data-e2e="user-name-input"
                                                  name="userName"
                                                  label="CUIT"
                                                  error={this.userNameInputHasError()}
                                                  autoFocus/>
                    <Button data-e2e="forgot-password-submit-button"
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary">
                        Enviar
                    </Button>
                </form>
            );
        }
    }

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                {!this.state.success ?
                    <>
                        <LoginTitle>
                            Olvidé mi contraseña
                        </LoginTitle>
                        {this.renderNotifications()}
                        {this.renderForgotPasswordForm()}
                    </>
                    : <CheckInboxMessage/>
                }
                <Typography align="center" variant="subtitle2">
                    <Link data-e2e="back-to-login-link"
                          className={classes.link}
                          href="/">
                        Volver al login
                    </Link>
                </Typography>
            </div>
        );
    }
}

ForgotPassword = withStyles(useStyles)(ForgotPassword)
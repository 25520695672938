import {Currency} from "../financial/money/currency";

export class CurrencySerializer {

    serialize(a_currency) {
        return {
            'name_for_one': a_currency.nameForOne(),
            'currency_code': a_currency.currencyCode()
        };
    }

    deserialized(a_serialized_currency) {
        return Currency.namedWithCurrencyCode(a_serialized_currency['name_for_one'],
            a_serialized_currency['currency_code']);
    }
}
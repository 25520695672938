export class LoggedInUser {
    static newWith(userToken, username, accountsNumbers) {
        return new this(userToken, username, accountsNumbers);
    }

    constructor(userToken, username, accountsNumbers) {
        this._userToken = userToken;
        this._username = username;
        this._accountsNumbers = accountsNumbers;
    }

    userToken() {
        return this._userToken;
    }

    username() {
        return this._username;
    }

    accountsNumbers() {
        return this._accountsNumbers;
    }
}
const UNKNOWN_PERCENTAGE_SERIALIZED = "UNKNOWN_PERCENTAGE"

export class PercentagePrinter {

    print(percentage) {
        if (this._isUnknownPercentage(percentage)) {
            return '? %'
        }

        const percentageFormatter = new Intl.NumberFormat(["es-AR"], {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
        return percentageFormatter.format(percentage.value);
    }

    _isUnknownPercentage(percentage) {
        return percentage === UNKNOWN_PERCENTAGE_SERIALIZED;
    }
}

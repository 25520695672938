import React from "react";
import {WithdrawalFormFieldContainer} from "./WithdrawalFormFieldContainer";
import {EcoTextFieldWithAssistant} from "../../../ui_common/EcoTextFieldWithAssistant";

export class WithdrawalFormTextField extends React.Component {
    render() {
        return (
            <WithdrawalFormFieldContainer label={this.props.label}>
                <EcoTextFieldWithAssistant data-e2e={this.props["data-e2e"]}
                                           name={this.props.name}
                                           fieldAssistant={this.props.fieldAssistant}
                                           onFieldChange={this.props.onFieldChange}/>
            </WithdrawalFormFieldContainer>
        );
    }
}
import React from "react";
import { WITHDRAWAL_SOMETHING_WENT_WRONG } from "../WithdrawalScreenNavigator";
import { system } from "../../../index";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WITHDRAWAL_BEING_PROCESSED,
  WithdrawalConfirmationScreenNavigator
} from "../WithdrawalConfirmationScreenNavigator";
import { SmallContainerScreen } from "../../layout/ui/SmallContainerScreen";
import { WithdrawalErrorScreen } from "./WithdrawalErrorScreen";
import { WithdrawalBeingProcessedScreen } from "./WithdrawalBeingProcessedScreen";

const useStyles = theme => ({
  loading: {
    margin: theme.spacing(4, 0)
  }
})

export class WithdrawalConfirmationScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      navigator: new WithdrawalConfirmationScreenNavigator(),
      isLoading: true,
      withdrawErrors: []
    };
  }

  // handlers

  async componentDidMount() {
    const requestToken = window.location.pathname.split('/').pop();
    await system.confirmWithdraw(requestToken,
      (_responseObject) => {
        this.state.navigator.showWithdrawBeingProcessed();
        this.setState({
          isLoading: false,
          navigator: this.state.navigator
        });
      },
      (errors) => {
        this.state.navigator.showSomethingWentWrong();
        this.setState({
          isLoading: false,
          navigator: this.state.navigator,
          withdrawErrors: errors.map(error => error.text)
        });
      });
  }

  // rendering

  render() {
    const { classes } = this.props;
    return (
      <SmallContainerScreen>
        {this.state.isLoading ?
          <>
            <Grid data-e2e="loading" container justify="center">
              <CircularProgress className={classes.loading} />
            </Grid>
            <Grid data-e2e="loading" container justify="center">
              Un momento por favor, esta operación puede tardar hasta un minuto
            </Grid>
          </>
          :
          this.state.navigator.screenToShow({
            [WITHDRAWAL_BEING_PROCESSED]: <WithdrawalBeingProcessedScreen
              onGoBackToPortfolio={this.props.onGoBackToHome} />,
            [WITHDRAWAL_SOMETHING_WENT_WRONG]: <WithdrawalErrorScreen
              data-e2e="withdraw-something-went-wrong-screen"
              title="No fue posible realizar la extracción"
              errorDetails={this.state.withdrawErrors}
              onGoBackToPortfolio={this.props.onGoBackToHome} />
          })
        }
      </SmallContainerScreen>
    );
  }
}

WithdrawalConfirmationScreen = withStyles(useStyles)(WithdrawalConfirmationScreen)


import React from "react";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = theme => ({
    root: {
        fontSize: theme.typography.h6.fontSize,
        fontWeight: theme.typography.fontWeightBold,
        padding: theme.spacing(2, 10),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1, 4)
        }
    }
});

export class GoToPortfolioButton extends React.Component {
    render() {
        const {classes} = this.props;

        return (
            <Button data-e2e={this.props["data-e2e"]}
                    color="primary"
                    variant="contained"
                    onClick={this.props.onClick}
                    className={classes.root}>
                {this.props.children}
            </Button>
        );
    }
}

GoToPortfolioButton = withStyles(useStyles)(GoToPortfolioButton);

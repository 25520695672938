import {isWidthUp} from "@material-ui/core";

export class InstrumentsDetailsColumnsFilter {

    constructor(availableColumns, columnsToDisplay) {
        this._availableColumns = availableColumns;
        this._columnsToDisplay = columnsToDisplay;
        this._checkedColumns = this._computeInitialState();
    }

    // public

    toggleColumn(toggledColumn) {
        let newState = this._checkedColumns;

        const isColumnAlreadyChecked = this._checkedColumns.columns[toggledColumn] === true;
        if (isColumnAlreadyChecked) {
            this._uncheckColumn(toggledColumn, newState);
            this._checkedColumns = newState;
            return this._checkedColumns;
        }

        this._checkColumn(toggledColumn, newState);

        const checkedColumns = Object.entries(newState.columns).filter(([_column, isChecked]) => isChecked === true);
        const numberOfCheckedColumns = checkedColumns.length;

        if (numberOfCheckedColumns === 3) {
            this._markAsLastChecked(toggledColumn, newState);
        } else if (numberOfCheckedColumns === 4) {
            this._uncheckColumn(newState.thirdColumnChecked, newState);
            this._markAsLastChecked(toggledColumn, newState);
        }

        this._checkedColumns = newState;
        return this._checkedColumns;
    }

    applyFilter() {
        const columnsNames = Object.keys(this._checkedColumns.columns);
        const columnsNamesToEnable = columnsNames.filter(key => this._checkedColumns.columns[key]);

        this._columnsToDisplay = columnsNamesToEnable;
    }

    saveFilter(saveFilterAction) {
        saveFilterAction(this._columnsToDisplay);
    }

    // testing - filters

    isColumnChecked(aColumn) {
        return this._checkedColumns.columns[aColumn];
    }

    canApplyFilter() {
        return Object.entries(this._checkedColumns.columns).some(([_column, isChecked]) => isChecked === true);
    }

    // testing - display columns

    shouldDisplayColumn(column, currentWidthBreakpoint) {
        const isBigScreen = isWidthUp('md', currentWidthBreakpoint);
        if (isBigScreen) {
            return this._availableColumns.includes(column);
        }
        return this._columnsToDisplay.includes(column);
    }

    isColumnAvailableToCheck(columnName) {
        return this._availableColumns.includes(columnName);
    }

    // private

    _computeInitialState() {
        const enabledColumns = this._columnsToDisplay;

        const columnsNames = this._availableColumns;

        const columns = columnsNames.map(column => {
            if (enabledColumns.includes(column)) {
                return [column, true];
            }
            return [column, false];
        });

        let thirdColumnChecked = null;
        if (enabledColumns.length === 3) {
            thirdColumnChecked = enabledColumns[2];
        }

        return {
            columns: Object.fromEntries(columns),
            thirdColumnChecked: thirdColumnChecked
        };
    }

    _uncheckColumn(toggledColumn, previousState) {
        previousState.columns = {...previousState.columns, [toggledColumn]: false};
    }

    _checkColumn(toggledColumn, previousState) {
        previousState.columns = {...previousState.columns, [toggledColumn]: true};
    }

    _markAsLastChecked(toggledColumn, previousState) {
        previousState.thirdColumnChecked = toggledColumn;
    }
}
import {ColumnsFilterStorage} from "./ColumnsFilterStorage";

export class ColumnsFilterBrowserStorage extends ColumnsFilterStorage {
    storeFiltersConfigurationFor(holdingType, columnsToDisplay) {
        function saveArray(key, array) {
            localStorage.setItem(key, JSON.stringify(array))
        }

        saveArray(holdingType, columnsToDisplay);
    }

    retrieveFiltersConfigurationFor(holdingType) {
        function getArray(key) {
            return JSON.parse(localStorage.getItem(key));
        }

        return getArray(holdingType);
    }
}
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {IconButton, InputAdornment} from "@material-ui/core";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {EcoTextFieldWithoutAssistant} from "./EcoTextFieldWithoutAssistant";

const useStyles = theme => ({
    passwordVisibility: {
        color: theme.palette.primary.main
    }
});

export let PasswordTextField = class PasswordTextField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPasswordVisible: false
        };

        this.handlePasswordVisibility = this.handlePasswordVisibility.bind(this);
    }

    // handlers

    handlePasswordVisibility() {
        this.setState({isPasswordVisible: !this.state.isPasswordVisible})
    }

    // private

    passwordInputType() {
        if (this.state.isPasswordVisible) {
            return "text"
        } else {
            return "password"
        }
    }

    render() {
        const {classes} = this.props;

        return <EcoTextFieldWithoutAssistant name={this.props.name}
                                             label={this.props.label}
                                             type={this.passwordInputType()}
                                             InputProps={{
                                                 endAdornment:
                                                     <InputAdornment position="end">
                                                         <IconButton aria-label="toggle password visibility"
                                                                     className={classes.passwordVisibility}
                                                                     onClick={this.handlePasswordVisibility}>
                                                             {this.state.isPasswordVisible ? <Visibility/> :
                                                                 <VisibilityOff/>}
                                                         </IconButton>
                                                     </InputAdornment>
                                             }}
                                             data-e2e={this.props.dataE2E}
                                             error={this.props.hasError}/>;
    }
}

PasswordTextField = withStyles(useStyles)(PasswordTextField)

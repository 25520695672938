import { Currency } from "./money/currency";

export const PESO = Currency.namedWithCurrencyCode("peso", "ARS");
export const DOLLAR_CABLE = Currency.namedWithCurrencyCode(
  "dollar cable",
  "USD",
);
export const DOLLAR_CABLE_BANK = Currency.namedWithCurrencyCode(
  "Dólar CCL trabado",
  "USD",
);
export const DOLLAR_MEP = Currency.namedWithCurrencyCode("dollar MEP", "USD");
export const DOLLAR_MEP_BANK = Currency.namedWithCurrencyCode(
  "Dólar MEP trabado",
  "USD",
);
export const DOLLAR_MATBA_ROFEX = Currency.namedWithCurrencyCode(
  "dollar matba rofex",
  "USD",
);
export const DOLLAR_UY = Currency.namedWithCurrencyCode("dollar UY", "USD");
export const DOLLAR_DB = Currency.namedWithCurrencyCode("dollar DB", "USD");

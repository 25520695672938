export const WITHDRAWAL_FORM = "WITHDRAWAL_FORM";
export const WITHDRAWAL_SOMETHING_WENT_WRONG = "WITHDRAWAL_SOMETHING_WENT_WRONG";
export const WITHDRAWAL_EMAIL_SENT = "WITHDRAWAL_EMAIL_SENT";
export const WITHDRAWAL_CANT_REQUEST_WITHDRAW = "WITHDRAWAL_CANT_REQUEST_WITHDRAW";
export const WITHDRAWAL_FORM_LOADING = "WITHDRAWAL_FORM_LOADING";

export class WithdrawalScreenNavigator {
    constructor() {
        this._screenToShow = WITHDRAWAL_FORM_LOADING;
    }

    showEmailSent() {
        this._screenToShow = WITHDRAWAL_EMAIL_SENT;
    }

    showSomethingWentWrong() {
        this._screenToShow = WITHDRAWAL_SOMETHING_WENT_WRONG;
    }

    showCantRequestWithdraw() {
        this._screenToShow = WITHDRAWAL_CANT_REQUEST_WITHDRAW;
    }

    showWithdrawalForm() {
        this._screenToShow = WITHDRAWAL_FORM;
    }

    screenToShow(screensByName) {
        return screensByName[this._screenToShow];
    }
}
export class WelcomeScreenModel {
    // actions

    markAsVisited() {
        localStorage.setItem("WELCOME_SCREEN_SHOWN", "true");
    }

    // testing

    wasVisited() {
        const welcomeScreenShown = localStorage.getItem("WELCOME_SCREEN_SHOWN");
        return welcomeScreenShown === "true";
    }
}
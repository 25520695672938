export class Money {

    static withAmountAndCurrency(anAmount, aCurrency) {
        return new this(anAmount, aCurrency);
    }

    constructor(anAmount, aCurrency) {
        this._amount = anAmount;
        this._currency = aCurrency;
    }

    amount() {
        return this._amount
    }

    currency() {
        return this._currency;
    }

    currencyCode() {
        return this._currency.currencyCode()
    }

    isIn(currency) {
        return this._currency.isEqualTo(currency);
    }

    isUnknown() {
        return false;
    }

    isNotZero() {
        return this._amount !== 0;
    }

    isLessOrEqualThan(anotherMoney) {
        if (!anotherMoney.isIn(this._currency)) {
            throw Error("Cannot compare to money of a different currency");
        }
        return this.amount() <= anotherMoney.amount();
    }

    // serialization

    serialize(aSerializer) {
        return aSerializer.serializeMoney(this._amount, this._currency);
    }
}

import React from "react";
import {WithdrawalFormFieldContainer} from "./WithdrawalFormFieldContainer";
import {MoneyField} from "../../../ui_common/MoneyField";

export class WithdrawalFormMoneyField extends React.Component {
    render() {
        return (
            <WithdrawalFormFieldContainer label={this.props.label}>
                <MoneyField data-e2e={this.props["data-e2e"]}
                            name={this.props.name}
                            currency={this.props.currency}
                            fieldAssistant={this.props.fieldAssistant}
                            onFieldChange={this.props.onFieldChange}/>
            </WithdrawalFormFieldContainer>
        );
    }
}
import React from "react";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import {LoginTitle} from "./LoginTitle";

const useStyles = theme => ({
    body: {
        marginTop: theme.spacing(2),
        '& p': {
            margin: 0
        }
    },
    message: {
        padding: theme.spacing(3)
    }
});

export class CheckInboxMessage extends React.Component {

    render() {
        const {classes} = this.props;

        return (
            <>
                <LoginTitle>
                    Correo enviado
                </LoginTitle>
                <Alert severity="info" data-e2e="forgot-password-check-inbox-message" className={classes.message}>
                    <Typography component="h1" variant="subtitle2">
                        Revisa tu correo
                    </Typography>
                    <div className={classes.body}>
                        <p>
                            Te llegará un email con asunto "OMS - ECO" que contiene tu nueva contraseña.
                            Podés cambiarla por otra contraseña de tu preferencia, ingresando a Matriz 4, en el menú de
                            la esquina superior derecha.
                        </p>
                        <p>
                            Si no recibiste el email "OMS-ECO" en 2 minutos, por favor ingresá a Eco Chat, elegí opción
                            9 para hablar con alguien de Eco y escribí "no me llegó email de contraseña nueva de Matriz.
                        </p>
                    </div>
                </Alert>
            </>
        );
    }
}

CheckInboxMessage = withStyles(useStyles)(CheckInboxMessage);

import {CreationAssertionRunner} from "./CreationAssertionRunner";

export class CreationAssertion {
    _values;
    _id;
    _condition;
    _description;

    static forAll(values, id, condition, description) {
        return new this(values, id, condition, description);
    }

    static for(value, id, condition, description) {
        return this.forAll([value], id, condition, description);
    }

    static assertFor(value, id, condition, description) {
        return this.assertForAll([value], id, condition, description)
    }

    static assertForAll(values, id, condition, description) {
        CreationAssertionRunner.assertAll([this.forAll(values, id, condition, description)]);
    }

    static isInvalidModel(potentialModel) {
        return potentialModel === CreationAssertion.INVALID_MODEL;
    }

    constructor(values, id, condition, description) {
        this._values = values;
        this._id = id;
        this._condition = condition;
        this._description = description;
    }

    hasFailed() {
        return this._shouldNotRun() || this._doesNotHold();
    }

    isIdentifiedAs(assertionId) {
        return this._id === assertionId;
    }

    isIdentifiedAsWith(assertionId, assertionDescription) {
        return this.isIdentifiedAs(assertionId)
            && this._isDescription(assertionDescription);
    }

    description() {
        return this._description;
    }

    // private

    _shouldNotRun() {
        return this._values.some(value => this.constructor.isInvalidModel(value));
    }

    _doesHold() {
        return this._condition();
    }

    _doesNotHold() {
        return !this._doesHold();
    }

    _isDescription(assertionDescription) {
        return this._description === assertionDescription;
    }
}

// Este objeto es usado como token de modelo inválido y de esta
// manera poder correr todas las validaciones en objetos compuestos
CreationAssertion.INVALID_MODEL = {};
import React from "react";
import {Typography} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = theme => ({
    label: {
        marginBottom: theme.spacing(1),
        color: '#91A5B7'
    },
    field: {
        margin: theme.spacing(1, 0)
    },
});

export class WithdrawalFormFieldContainer extends React.Component {
    renderLabel() {
        const {classes} = this.props;
        return (
            <Typography variant="body2" className={classes.label}>{this.props.label}</Typography>
        );
    }

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.field}>
                {this.renderLabel()}
                {this.props.children}
            </div>
        );
    }
}

WithdrawalFormFieldContainer = withStyles(useStyles)(WithdrawalFormFieldContainer);
export class SimpleHTTPClient {

    configureErrorHandlers(onNetworkError) {
        this._onNetworkError = onNetworkError;
    }

    async performPost(url, jsonBody, onSuccess) {
        const parameters = {
            method: 'POST',
            body: JSON.stringify(jsonBody)
        };

        await this._performJsonRequest(url, parameters, onSuccess);
    }

    async performAuthenticatedGet(authorizationToken, url, onSuccess) {
        const parameters = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${authorizationToken}`
            }
        };

        await this._performJsonRequest(url, parameters, onSuccess);
    }

    async performAuthenticatedPost(authorizationToken, url, jsonBody, onSuccess) {
        const parameters = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${authorizationToken}`
            },
            body: JSON.stringify(jsonBody)
        };

        await this._performJsonRequest(url, parameters, onSuccess);
    }

    async _performJsonRequest(url, parameters, onSuccess) {
        parameters['headers'] = {
            ...parameters['headers'],
            'Content-Type': 'application/json'
        };

        fetch(this._urlWithFullPath(url), parameters)
            .then((response) => onSuccess(response))
            .catch((error) => {
                if (error instanceof TypeError) {
                    this._onNetworkError();
                } else {
                    throw error;
                }
            });
    }

    _urlWithFullPath(url) {
        let fullUrl = `${url}`;

        if (window._env.REACT_APP_BACKEND_URL !== undefined) {
            const baseUrl = window._env.REACT_APP_BACKEND_URL;
            fullUrl = `${baseUrl}${url}`;
        }
        return fullUrl;
    }
}
import React from "react";
import {Checkbox, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = theme => ({
    root: {
        borderBottomWidth: "0.5pt",
        borderBottomStyle: "solid",
        borderColor: theme.palette.grey[200],
        color: theme.palette.text.secondary,
        '&:last-child': {
            borderBottomWidth: 0,
        }
    }
});

class CheckColumnListItem extends React.Component {
    render() {
        return (this.props.isShown ?
            <ListItem className={this.props.classes.root}
                      dense button
                      onClick={this.props.onClick}>
                <ListItemIcon>
                    <Checkbox checked={this.props.isChecked}
                              name={this.props.column}/>
                </ListItemIcon>
                <ListItemText primary={this.props.name}/>
            </ListItem> : null
        );
    }
}

export default withStyles(useStyles)(CheckColumnListItem);
import {WelcomeScreenModel} from "../WelcomeScreenModel";

export const COLUMNS_FILTER_SCREEN = "COLUMNS_FILTER"
export const PORTFOLIO_SCREEN = "PORTFOLIO"
export const WELCOME_SCREEN = "WELCOME"
export const WITHDRAWAL_SCREEN = "WITHDRAW"
export const DEPOSIT_SCREEN = "DEPOSIT"

export class LoggedInScreenNavigator {
    constructor() {
        this._welcomeScreenModel = new WelcomeScreenModel();
        this.goToMainScreen();
    }

    continueToPortfolio() {
        this._welcomeScreenModel.markAsVisited();
        this._screenToShow = PORTFOLIO_SCREEN;
    }

    openFilters() {
        this._screenToShow = COLUMNS_FILTER_SCREEN;
    }

    closeFilters() {
        this.goToMainScreen();
    }

    openDeposit() {
        this._screenToShow = DEPOSIT_SCREEN;
    }

    closeDeposit() {
        this.goToMainScreen();
    }

    openWithdrawal() {
        this._screenToShow = WITHDRAWAL_SCREEN;
    }

    goToMainScreen() {
        if (this._welcomeScreenModel.wasVisited()) {
            this._screenToShow = PORTFOLIO_SCREEN;
        } else {
            this._screenToShow = WELCOME_SCREEN;
        }
    }

    screenToShow(screensByName) {
        return screensByName[this._screenToShow];
    }
}
import {WITHDRAWAL_SOMETHING_WENT_WRONG} from "./WithdrawalScreenNavigator";

export const WITHDRAWAL_BEING_PROCESSED = "WITHDRAWAL_BEING_PROCESSED";

export class WithdrawalConfirmationScreenNavigator {
    constructor() {
        this._screenToShow = null;
    }

    showWithdrawBeingProcessed() {
        this._screenToShow = WITHDRAWAL_BEING_PROCESSED;
    }

    showSomethingWentWrong() {
        this._screenToShow = WITHDRAWAL_SOMETHING_WENT_WRONG;
    }

    screenToShow(screensByName) {
        return screensByName[this._screenToShow];
    }
}
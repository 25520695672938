import React from 'react';
import Grid from '@material-ui/core/Grid';
import withStyles from "@material-ui/core/styles/withStyles";
import {Hidden} from "@material-ui/core";
import {Login} from "./Login"
import {ForgotPassword} from "./ForgotPassword"
import {PromotionalInformation} from "./PromotionalInformation";
import {LoginInteractions} from "../LoginInteractions";

const useStyles = theme => ({
    root: {
        minHeight: `calc(100vh - ${theme.mixins.footer.height})`,
        paddingTop: theme.mixins.toolbar.minHeight,
        [theme.breakpoints.up('sm')]: {
            backgroundPosition: '0 100%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '50% 100%',
            background: "linear-gradient(220.18deg, #60EEEE -6.72%, #3FB498 67.21%)"
        }
    },
    promotionalInformation: {
        margin: theme.spacing(3)
    },
    login: {
        padding: theme.spacing(3, 3, 7, 3),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(7)
        }
    }
});

export class LoginScreen extends React.Component {

    constructor(props) {
        super(props);
        this._interaction = new LoginInteractions(this, this.props.onSuccessfulLogin);

        this.state = this._interaction.initialState();
    }

    // rendering

    render() {
        const {classes} = this.props;

        return (
            <Grid container component="main" className={classes.root} data-e2e="login-screen">
                <Hidden only="xs">
                    <Grid item sm={6} container alignContent="center" justify="center">
                        <Grid item className={classes.promotionalInformation}>
                            <PromotionalInformation/>
                        </Grid>
                    </Grid>
                </Hidden>
                <Grid item sm={6} className={classes.login} container>
                    {this.state.showForgotPassword ?
                        <ForgotPassword/>
                        :
                        <Login isSessionExpired={this.props.isSessionExpired}
                               onForgotPasswordClick={() => this._interaction.handleForgotPassword()}
                               handleLogin={(event) => this._interaction.handleLogin(event)}
                               isLoading={this.state.isLoading}
                               accessDenied={this.state.accessDenied}
                               serviceUnavailable={this.state.serviceUnavailable}
                               invalidCuit={this.state.invalidCuit}/>
                    }
                </Grid>
            </Grid>
        );
    }
}

LoginScreen = withStyles(useStyles)(LoginScreen)

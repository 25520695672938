import React from "react";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'flex-start'
    },
    settingsIcon: {
        color: theme.palette.grey[500],
        marginTop: '3px',
        marginRight: '6px',
    },
    titleText: {
        fontWeight: theme.typography.fontWeightBold,
    },
    usernameText: {
        color: theme.palette.text.secondary,
    }
});


export class ConfigurationSectionTitle extends React.Component {
    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <SettingsIcon className={classes.settingsIcon}/>
                <div>
                    <Typography variant="subtitle1" className={classes.titleText}>
                        Configuración
                    </Typography>
                    <Typography component="p" variant="subtitle2" className={classes.usernameText}>
                        Usuario: {this.props.loggedInUser.username()}
                    </Typography>
                </div>
            </div>
        )
    }
}

ConfigurationSectionTitle = withStyles(useStyles)(ConfigurationSectionTitle)

import React from "react";
import {withStyles} from "@material-ui/core";
import {HowToDeposit} from "./deposit/HowToDeposit";
import {GoToPortfolioButton} from "./ui_common/GoToPortfolioButton";
import {SmallContainerScreen} from "./layout/ui/SmallContainerScreen";
import {HowToWithdraw} from "./deposit/HowToWithdraw";

const useStyles = theme => ({
    backToPortfolioButton: {
        textAlign: 'center',
        marginTop: theme.spacing(4)
    }
});

export class DepositAndWithdrawalHowToScreen extends React.Component {

    // rendering

    render() {
        const {classes} = this.props;

        return (
            <SmallContainerScreen data-e2e="deposit-and-withdrawal-howto">
                <HowToDeposit/>
                <HowToWithdraw/>
                <div className={classes.backToPortfolioButton}>
                    <GoToPortfolioButton onClick={() => this.props.onClose()}>
                        Ingresar a EcoPortfolio
                    </GoToPortfolioButton>
                </div>
            </SmallContainerScreen>
        );
    }
}

DepositAndWithdrawalHowToScreen = withStyles(useStyles)(DepositAndWithdrawalHowToScreen);
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {Hidden, IconButton, List, ListItem, ListItemIcon, ListItemText, Paper} from "@material-ui/core";
import clipboard from "../img/icons/copy.svg"
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

export const ARS_ALIAS = "ECO.VALORES.PESOS"
export const ARS_CBU = "3220001805006401910011"
export const USD_ALIAS = "ECO.VALORES.DOLARES"
export const USD_CBU = "3220001812006401910022"

const useStyles = theme => ({
    title: {
        padding: theme.spacing(0, 1, 1, 2),
        color: theme.palette.text.secondary
    },
    list: {
        padding: 0
    },
    label: {
        color: theme.palette.text.secondary
    },
    itemText: {
        margin: 0
    },
    copyButton: {
        padding: 0,
        minWidth: 0
    }
});

export class DepositInformation extends React.Component {

    // handlers

    async handleClipboardClick(label) {
        await navigator.clipboard.writeText(label);
    }

    // rendering

    renderDepositInformationItem(label, content) {
        const {classes} = this.props;

        return (
            <ListItem>
                <ListItemText className={classes.itemText}
                              primary={
                                  <Typography variant="caption" className={classes.label}>
                                      {label}
                                  </Typography>
                              }
                              secondary={
                                  <Typography variant="body1">
                                      {content}
                                  </Typography>}/>
                <ListItemIcon className={classes.copyButton}>
                    <IconButton data-e2e="copy-deposit-information-to-clipboard"
                                onClick={() => this.handleClipboardClick(content)}>
                        <img src={clipboard} alt={"copy to clipboard"}/>
                    </IconButton>
                </ListItemIcon>
            </ListItem>
        );
    }

    render() {
        const {classes} = this.props;

        return (
            <>
                <Typography component="p" variant="subtitle1" className={classes.title}>
                    {this.props.title}
                </Typography>
                <Paper>
                    <List className={classes.list}>
                        <Hidden only="xs">
                            <ListItem> <ListItemText primary="Banco industrial"/> </ListItem>
                            <Divider/>
                        </Hidden>
                        {this.renderDepositInformationItem("Alias", this.props.alias)}
                        <Divider/>
                        {this.renderDepositInformationItem("CBU", this.props.cbu)}
                    </List>
                </Paper>
            </>
        );
    }
}

DepositInformation = withStyles(useStyles)(DepositInformation);
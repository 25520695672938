import React from "react";
import AnimatedNumber from "react-animated-number";
import {MoneyPrinter} from "../MoneyPrinter";
import {MoneySerializer} from "../../serializers/MoneySerializer";

export class AnimatedMoney extends React.Component {
    'component lifecycle'

    componentDidUpdate(prevProps, prevState, snapshot) {
        this._previous_amount = prevProps.money.amount;
    }

    'formatting'

    amountDecimalPlaces() {
        return 2;
    }

    'animation'

    transitionCssStyle() {
        return {
            transition: '0.8s ease-out',
            transitionProperty:
                'color, opacity'
        }
    }

    transitionCssStyleDuringAmountChange() {
        const newAmount = this.props.money.amount;
        if (newAmount < this._previous_amount) {
            const red = '#FF5555';
            return {color: red};
        } else {
            return {};
        }
    }

    animationDuration() {
        return 750;
    }

    'rendering'

    render() {
        const money = new MoneySerializer().deserialize(this.props.money);

        if (money.isUnknown()) {
            return <div data-e2e={this.props.dataE2E}>{this.props.unknownMoneyMessage}</div>;
        }

        return (
            <AnimatedNumber data-e2e={this.props.dataE2E}
                            initialValue={money.amount()}
                            value={money.amount()}
                            style={this.transitionCssStyle()}
                            duration={this.animationDuration()}
                            formatValue={amount => new MoneyPrinter().printUsingLongNotation({
                                amount: amount,
                                currency: this.props.money.currency
                            })}
                            frameStyle={(percentageCompleted) => {
                                const animationInProgress = (percentageCompleted > 0 && percentageCompleted < 100)
                                return animationInProgress ? this.transitionCssStyleDuringAmountChange() : {}
                            }}
                            stepPrecision={this.amountDecimalPlaces()}
            />
        );
    }

}
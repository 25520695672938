import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import {Link} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import {EcoTextFieldWithoutAssistant} from "../../ui_common/EcoTextFieldWithoutAssistant";
import {PasswordTextField} from "../../ui_common/PasswordTextField"
import Button from "@material-ui/core/Button";
import {LoginTitle} from "./LoginTitle";
import {EcoChatButton} from "../../layout/ui/EcoChatButton";

const useStyles = theme => ({
    formContainer: {
        '& > *': {
            margin: theme.spacing(2, 0)
        },
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        '& > *': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        }
    },
    registerText: {
        color: theme.palette.text.secondary
    },
    registerLink: {
        marginTop: theme.spacing(1)
    },
    link: {
        fontWeight: theme.typography.fontWeightBold
    },
    loading: {
        margin: theme.spacing(4, 0)
    },
    forgotPassword: {
        padding: theme.spacing(4)
    }
});

const PHYSICAL_REGISTER_URL = "https://www.ecovalores.com.ar/abrir_cuenta_persona_fisica.php";
const LEGAL_REGISTER_URL = "https://www.ecovalores.com.ar/abrir_cuenta_persona_juridica.php";

export class Login extends React.Component {

    // asserts

    userNameInputHasError() {
        return this.props.accessDenied || this.props.invalidCuit;
    }

    // rendering

    renderNotifications() {
        if (this.props.invalidCuit) {
            return (
                <Alert severity="error">
                    Número de CUIT/CUIL incorrecto
                </Alert>
            );
        }
        if (this.props.accessDenied) {
            return (
                <Alert severity="error" data-e2e="invalid-login-credentials-error-message">
                    Su número de CUIT/CUIL y/o su contraseña son incorrectas
                </Alert>
            );
        }
        if (this.props.serviceUnavailable) {
            return (
                <Alert severity="error" data-e2e="service-unavailable-error-message">
                    Lo sentimos, el servicio no está disponible en este momento. Por favor, reintente más tarde.
                </Alert>
            );
        }
        if (this.props.isSessionExpired) {
            return (
                <Alert severity="error" data-e2e="user-token-expired-error-message">
                    Su sesión expiró. Por favor ingrese nuevamente.
                </Alert>
            );
        }
    }

    renderLoginForm() {
        const {classes} = this.props;

        if (this.props.isLoading) {
            return (
                <Grid container justify="center">
                    <CircularProgress className={classes.loading}/>
                </Grid>
            );
        } else {
            return (
                <form className={classes.form} noValidate onSubmit={this.props.handleLogin}>
                    <EcoTextFieldWithoutAssistant data-e2e="user-name-input"
                                                  name="userName"
                                                  label="CUIT"
                                                  error={this.userNameInputHasError()}
                                                  autoFocus/>
                    <PasswordTextField dataE2E="password-input"
                                       name="password"
                                       label="Contraseña"
                                       hasError={this.props.accessDenied}/>
                    <Button data-e2e="login-button"
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary">
                        Ingresar
                    </Button>
                </form>
            );
        }
    }

    renderForgotPasswordLink() {
        const {classes} = this.props;

        return (
            <div className={classes.forgotPassword}>
                <Grid container justify="center" spacing={1}>
                    <Grid item xs={12} align="center">
                        <Typography variant="subtitle2" className={classes.registerText}>
                            Para resetear tu clave, ingresá a:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} align="center">
                        <EcoChatButton smallSize/>
                    </Grid>
                </Grid>
            </div>
        );
    }

    renderRegisterLinks() {
        const {classes} = this.props;

        return (
            <Typography align="center" variant="subtitle2" className={classes.registerText}>
                No tengo usuario, registrarme como:
                <div className={classes.registerLink}>
                    <Link href={PHYSICAL_REGISTER_URL} className={classes.link}>
                        Persona Física
                    </Link>
                </div>
                <div className={classes.registerLink}>
                    <Link href={LEGAL_REGISTER_URL} className={classes.link}>
                        Persona Jurídica
                    </Link>
                </div>
            </Typography>
        );
    }

    render() {
        const {classes} = this.props;

        return (
            <Grid container alignContent="space-between">
                <Grid item xs={12} className={classes.formContainer}>
                    <LoginTitle>
                        Login
                    </LoginTitle>
                    {this.renderNotifications()}
                    {this.renderLoginForm()}
                </Grid>
                <Grid item xs={12}>
                    {this.renderForgotPasswordLink()}
                    {this.renderRegisterLinks()}
                </Grid>
            </Grid>
        );
    }
}

Login = withStyles(useStyles)(Login);

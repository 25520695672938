import React from "react";
import {
  WITHDRAWAL_CANT_REQUEST_WITHDRAW,
  WITHDRAWAL_EMAIL_SENT,
  WITHDRAWAL_FORM,
  WITHDRAWAL_FORM_LOADING,
  WITHDRAWAL_SOMETHING_WENT_WRONG,
  WithdrawalScreenNavigator
} from "../WithdrawalScreenNavigator";
import { WithdrawalForm } from "./form/WithdrawalForm";
import { system } from "../../../index";
import { DOLLAR_MEP, PESO } from "../../../financial/moneys";
import { WithdrawalRequest } from "../../../financial/WithdrawalRequest";
import { MoneySerializer } from "../../../serializers/MoneySerializer";
import { SmallContainerScreen } from "../../layout/ui/SmallContainerScreen";
import { WithdrawalFormLoading } from "./WithdrawalFormLoading";
import { WithdrawalErrorScreen } from "./WithdrawalErrorScreen";
import { WithdrawalEmailSentScreen } from "./WithdrawalEmailSentScreen";

export class WithdrawalScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      navigator: new WithdrawalScreenNavigator(),
      accountHolder: null,
      availableToWithdraw: null,
      costPerWithdrawal: null,
      emailAddress: null,
      withdrawalRequestAssistant: WithdrawalForm.createFormAssistant(),
      isProcessingWithdrawal: false,
      userInformationErrors: null,
    };

    this.handleWithdraw = this.handleWithdraw.bind(this);
    this.handleWithdrawalAll = this.handleWithdrawalAll.bind(this);
  }

  async componentDidMount() {
  
    await system.userInformationForWithdrawal(this.props.userToken, this.props.accountNumber, this.props.currency.currencyCode(),
      (userInformation) => {
        let availableToWithdraw = null;
        let costPerWithdrawal = null;
        if (this.props.currency === PESO) {
          availableToWithdraw = userInformation.availableToWithdrawInPesos;
          costPerWithdrawal = userInformation.costPerArsWithdrawal
        } else if (this.props.currency === DOLLAR_MEP) {
          availableToWithdraw = userInformation.availableToWithdrawInDollarMEP;
          costPerWithdrawal = userInformation.costPerDollarMEPWithdrawal
        } else {
          throw Error("Unsupported currency for withdrawal");
        }
        this.state.navigator.showWithdrawalForm();
        this.setState({
          accountHolder: userInformation.accountHolder,
          availableToWithdraw: new MoneySerializer().deserialize(availableToWithdraw),
          costPerWithdrawal: new MoneySerializer().deserialize(costPerWithdrawal),
          emailAddress: userInformation.emailAddress,
          navigator: this.state.navigator
        });
      },
      (errors) => {
        this.state.navigator.showCantRequestWithdraw();

        this.setState((state) => {
          return {
            userInformationErrors: errors.map(error => {
              return error.code !== "SERVICE_UNAVAILABLE" ? error.text : `Si el error persiste, por favor enviá un email a tesoreria@ecobolsar.com indicando:
"Solicito retirar de mi cuenta comitente ${this.props.accountNumber} la cantidad de ${this.props.currency === DOLLAR_MEP ? "US$" : "$"} XXXXXX ${this.props.currency === DOLLAR_MEP ? "Dólares" : "Pesos"}, a mi cuenta bancaria CBU/alias XXXXXXXXXXXXXXX"`
            }), navigator: state.navigator
          }
        });
      });
  }

  // handlers

  async handleWithdraw(event) {
    event.preventDefault();

    this.setState({ isProcessingWithdrawal: true });
    const createModel = (bankAccountId, withdrawalAmount) => {
      return WithdrawalRequest.newWith(
        this.props.accountNumber, bankAccountId, withdrawalAmount, this.state.availableToWithdraw);
    }

    this.state.withdrawalRequestAssistant.withCreatedModelDo(createModel,
      async (withdrawalRequest) => {
        await system.withdraw(this.props.userToken, withdrawalRequest,
          (_responseObject) => {
            this.state.navigator.showEmailSent();
            this.setState((state) => {
              return { navigator: state.navigator, isProcessingWithdrawal: false }
            });
          },
          (_errors) => {
            this.state.navigator.showSomethingWentWrong();

            this.setState((state) => {
              return { navigator: state.navigator, isProcessingWithdrawal: false }
            });
          });
      },
      () => this.setState({ isProcessingWithdrawal: false }));

    this.updateFormAssistant();
  }

  handleWithdrawalAll() {
    this.state.withdrawalRequestAssistant.withdrawalAmountAssistant.setModel(this.state.availableToWithdraw);
    this.updateFormAssistant();
  }

  // private
  withdrawalAmountAssertion
  updateFormAssistant() {
    this.setState({ withdrawalRequestAssistant: this.state.withdrawalRequestAssistant });
  }

  // rendering

  render() {
    return (
      <SmallContainerScreen data-e2e="withdrawal-screen">
        {this.state.navigator.screenToShow({
          [WITHDRAWAL_FORM_LOADING]: <WithdrawalFormLoading />,
          [WITHDRAWAL_FORM]: <WithdrawalForm onWithdraw={this.handleWithdraw}
            accountNumber={this.props.accountNumber}
            accountHolder={this.state.accountHolder}
            availableToWithdraw={this.state.availableToWithdraw}
            costPerWithdrawal={this.state.costPerWithdrawal}
            currency={this.props.currency}
            formCompletionAssistant={this.state.withdrawalRequestAssistant}
            isProcessingWithdrawal={this.state.isProcessingWithdrawal}
            onFormChange={() => this.updateFormAssistant()}
            onWithdrawAll={this.handleWithdrawalAll}
            onGoBackToPortfolio={this.props.onGoBackToPortfolio} />,
          [WITHDRAWAL_EMAIL_SENT]: <WithdrawalEmailSentScreen emailAddress={this.state.emailAddress}
            onGoBackToPortfolio={this.props.onGoBackToPortfolio} />,
          [WITHDRAWAL_SOMETHING_WENT_WRONG]: <WithdrawalErrorScreen
            data-e2e="withdraw-something-went-wrong-screen"
            title="Algo salió mal"
            errorDetails={["Si necesitás más información, por favor comunicate con nosotros a través de Eco Chat, opción 9."]}
            onGoBackToPortfolio={this.props.onGoBackToPortfolio} />,
          [WITHDRAWAL_CANT_REQUEST_WITHDRAW]: <WithdrawalErrorScreen
            data-e2e="cant-request-withdraw-screen"
            title="En este momento no podés hacer retiros automáticos"
            errorDetails={this.state.userInformationErrors}
            onGoBackToPortfolio={this.props.onGoBackToPortfolio} />
        })}
      </SmallContainerScreen>
    );
  }
}

import React from "react";
import { Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { AnimatedMoney } from "../../ui_common/AnimatedMoney";
import Skeleton from "react-loading-skeleton";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(3)
  },
  totalValue: {
    fontWeight: theme.typography.fontWeightBold
  },
  button: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.secondary,
    boxShadow: "0px 0px 20px rgba(162, 132, 255, 0.212)",
    padding: theme.spacing(1, 2),
    '&:hover': {
      boxShadow: "0px 0px 20px rgba(162, 132, 255, 0.212)",
      backgroundColor: '#f7f8f9'
    }
  }
});

export class PortfolioTotalValuePaper extends React.Component {

  isPortfolioLoaded() {
    return this.props.portfolioTotal !== null;
  }

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root} elevation={1}>
        <Typography component="p" variant="body2">
          Valor Portfolio Total
        </Typography>
        <Typography component="p" variant="h5" className={classes.totalValue}>
          {this.isPortfolioLoaded() ?
            <AnimatedMoney dataE2E="portfolio-total-value"
              money={this.props.portfolioTotal}
              unknownMoneyMessage="Desconocido" />
            : <Skeleton width={140} />
          }
        </Typography>
        <Typography component="p" variant="body2">
          Saldo proyectado
        </Typography>
      </Paper>
    );
  }
}

PortfolioTotalValuePaper = withStyles(useStyles)(PortfolioTotalValuePaper)

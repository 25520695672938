import {CreationError} from "./CreationError";

export class CreationAssertionRunner {
    _assertions;

    static assert(assertion) {
        this.assertAll([assertion]);
    }

    static assertAll(assertions) {
        (new this(assertions))._run();
    }

    constructor(assertions) {
        this._assertions = assertions;
    }

    // private

    _run() {
        const failedAssertions = this._failedAssertions();

        if (failedAssertions.length !== 0) {
            throw new CreationError(failedAssertions);
        }
    }

    _failedAssertions() {
        return this._assertions.filter(assertion => assertion.hasFailed());
    }
}


import React from "react";
import { Box, Grid, Paper, withWidth } from "@material-ui/core";
import { PortfolioTotalValuePaper } from "./PortfolioTotalValuePaper";
import { HoldingsSummaryList } from "./holdings_summary/HoldingsSummaryList";
import { PortfolioSharePieChart } from "./PortfolioSharePieChart";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  ARS_CURRENCY_HOLDING,
  BOND,
  COLLATERALS,
  DAILY_DIFFERENCE_IN_ARS,
  DOLLAR_CABLE_CURRENCY_HOLDING,
  DOLLAR_CABLE_CURRENCY_BANK,
  DOLLAR_MATBA_ROFEX_CURRENCY_HOLDING,
  DOLLAR_MEP_CURRENCY_HOLDING,
  DOLLAR_UY_CURRENCY_HOLDING,
  DOLLAR_DB_CURRENCY_HOLDING,
  DOLLAR_MEP_CURRENCY_BANK,
  LETTER,
  MUTUAL_FUNDS,
  NEGOTIABLE_OBLIGATION,
  PENDING_MOVEMENTS,
  STOCK,
  STOCK_OPTION,
} from "../../../financial/instrument_types";

const useStyles = (theme) => ({
  paper: {
    width: "100%",
    height: "100%",
    padding: theme.spacing(6),
    borderRadius: theme.spacing(5),
  },
  pieChartContainer: {
    width: "100%",
    height: "100%",
    padding: theme.spacing(0, 3),
  },
});

export let PortfolioSummary = class PortfolioSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedHolding: null,
    };

    this.setHoldingAsSelectedHolding =
      this.setHoldingAsSelectedHolding.bind(this);
    this.setNoHoldingSelected = this.setNoHoldingSelected.bind(this);
  }

  setHoldingAsSelectedHolding(holding) {
    this.setState({
      selectedHolding: holding,
    });
  }

  setNoHoldingSelected() {
    this.setState({
      selectedHolding: null,
    });
  }

  // accessing - private

  isPortfolioLoaded() {
    return this.props.portfolio !== null;
  }

  holdingsData() {
    if (!this.isPortfolioLoaded()) {
      return null;
    }

    const {
      stocksTotal,
      bondsTotal,
      lettersTotal,
      negotiableObligationsTotal,
      stockOptionsTotal,
      mutualFundsTotal,
      stocksShare,
      bondsShare,
      lettersShare,
      negotiableObligationsShare,
      stockOptionsShare,
      mutualFundsShare,
      collateralsTotal,
      collateralsShare,
      arsCurrencyHoldingTotal,
      arsCurrencyHoldingShare,
      dollarMEPCurrencyHoldingTotal,
      dollarMEPCurrencyBankTotal,
      dollarMatbaRofexCurrencyHoldingTotal,
      dollarMEPCurrencyHoldingShare,
      dollarMEPCurrencyBankShare,
      dollarMatbaRofexCurrencyHoldingShare,
      dollarUYCurrencyHoldingTotal,
      dollarUYCurrencyHoldingShare,
      dollarDBCurrencyHoldingTotal,
      dollarDBCurrencyHoldingShare,
      pendingMovementsShare,
      pendingMovementsTotal,
      dailyDiffShare,
      dailyDiffTotal,
      dollarCableCurrencyHoldingTotal,
      dollarCableCurrencyBankTotal,
      dollarCableCurrencyHoldingShare,
      dollarCableCurrencyBankShare,
    } = this.props.portfolio;

    return [
      {
        holding: STOCK,
        displayName: "Acciones",
        share: stocksShare,
        total: stocksTotal,
        color: "#3C17ED",
      },
      {
        holding: BOND,
        displayName: "Bonos",
        share: bondsShare,
        total: bondsTotal,
        color: "#FFAD01",
      },
      {
        holding: LETTER,
        displayName: "Letras",
        share: lettersShare,
        total: lettersTotal,
        color: "#9C44C2",
      },
      {
        holding: NEGOTIABLE_OBLIGATION,
        displayName: "Obligaciones Negociables",
        share: negotiableObligationsShare,
        total: negotiableObligationsTotal,
        color: "#00CEFF",
      },
      {
        holding: STOCK_OPTION,
        displayName: "Opciones de Acciones",
        share: stockOptionsShare,
        total: stockOptionsTotal,
        color: "#FA8383",
      },
      {
        holding: MUTUAL_FUNDS,
        displayName: "Fondos Comunes de Inversión",
        share: mutualFundsShare,
        total: mutualFundsTotal,
        color: "#698674",
      },
      {
        holding: COLLATERALS,
        displayName: "Monedas en garantías (pesificado)",
        share: collateralsShare,
        total: collateralsTotal,
        color: "#52214f",
      },
      {
        holding: ARS_CURRENCY_HOLDING,
        displayName: "Pesos",
        share: arsCurrencyHoldingShare,
        total: arsCurrencyHoldingTotal,
        color: "#FFADF7",
      },
      {
        holding: DOLLAR_MEP_CURRENCY_HOLDING,
        displayName: "Dólar MEP",
        share: dollarMEPCurrencyHoldingShare,
        total: dollarMEPCurrencyHoldingTotal,
        color: "#E91E40",
      },
      {
        holding: DOLLAR_UY_CURRENCY_HOLDING,
        displayName: "Dólar UY",
        share: dollarUYCurrencyHoldingShare,
        total: dollarUYCurrencyHoldingTotal,
        color: "#8BBEB2",
      },
      {
        holding: DOLLAR_DB_CURRENCY_HOLDING,
        displayName: "Dólar Caja de Valores (7000)",
        share: dollarDBCurrencyHoldingShare,
        total: dollarDBCurrencyHoldingTotal,
        color: "#8B9DBE",
      },
      {
        holding: DOLLAR_MEP_CURRENCY_BANK,
        displayName: "Dólar MEP (para extraer a banco)",
        share: dollarMEPCurrencyBankShare,
        total: dollarMEPCurrencyBankTotal,
        color: "#FA2F51",
      },
      {
        holding: DOLLAR_MATBA_ROFEX_CURRENCY_HOLDING,
        displayName: "Dólar MATBA Rofex",
        share: dollarMatbaRofexCurrencyHoldingShare,
        total: dollarMatbaRofexCurrencyHoldingTotal,
        color: "#FFAD01",
      },
      {
        holding: DOLLAR_CABLE_CURRENCY_HOLDING,
        displayName: "Dólar Cable",
        share: dollarCableCurrencyHoldingShare,
        total: dollarCableCurrencyHoldingTotal,
        color: "#00D07D",
      },
      {
        holding: DOLLAR_CABLE_CURRENCY_BANK,
        displayName: "Dólar Cable (para extraer a banco)",
        share: dollarCableCurrencyBankShare,
        total: dollarCableCurrencyBankTotal,
        color: "#11E18E",
      },
      {
        holding: PENDING_MOVEMENTS,
        displayName: "Compras pendientes productos ByMA",
        share: pendingMovementsShare,
        total: pendingMovementsTotal,
        color: "#C6F408",
      },
      {
        holding: DAILY_DIFFERENCE_IN_ARS,
        displayName: "Dif. diarias en pesos",
        share: dailyDiffShare,
        total: dailyDiffTotal,
        color: "#73AAE2",
      },
    ];
  }

  portfolioTotalInPesos() {
    if (!this.isPortfolioLoaded()) {
      return null;
    }

    // This does not look good. We should have a Portfolio object in frontend, and real money objects here
    return this.props.portfolio.portfolioTotals.find(
      (total) => total["currency"]["currency_code"] === "ARS",
    );
  }

  hasFutures() {
    if (!this.isPortfolioLoaded()) {
      return null;
    }

    return this.props.portfolio.hasFutures;
  }

  // rendering

  render() {
    const portfolioTotalValuePaperComponent = (
      <PortfolioTotalValuePaper
        portfolioTotal={this.portfolioTotalInPesos()}
        onDepositClick={this.props.onDepositClick}
      />
    );
    const holdingsSummaryListComponent = (
      <HoldingsSummaryList
        holdingsData={this.holdingsData()}
        onHoldingRowMouseEnter={this.setHoldingAsSelectedHolding}
        onRowMouseLeave={this.setNoHoldingSelected}
        hasFutures={this.hasFutures()}
      />
    );
    const portfolioSharePieChartComponent = (
      <PortfolioSharePieChart
        holdingsData={this.holdingsData()}
        portfolioTotal={this.portfolioTotalInPesos()}
        selectedHolding={this.state.selectedHolding}
        onPieSectionHover={this.setHoldingAsSelectedHolding}
        onPieLeave={this.setNoHoldingSelected}
        isDesktop={this.props.isDesktop}
      />
    );
    return (
      <Grid container spacing={2}>
        {this.props.isDesktop
          ? this._renderDesktop(
              portfolioTotalValuePaperComponent,
              portfolioSharePieChartComponent,
              holdingsSummaryListComponent,
            )
          : this._renderMobile(
              portfolioTotalValuePaperComponent,
              portfolioSharePieChartComponent,
              holdingsSummaryListComponent,
            )}
      </Grid>
    );
  }

  _renderDesktop(
    portfolioTotalValuePaperComponent,
    portfolioSharePieChartComponent,
    holdingsSummaryListComponent,
  ) {
    const { classes } = this.props;
    return (
      <>
        <Grid item sm={7}>
          <Paper className={classes.paper}>
            <Grid
              container
              spacing={2}
              alignContent={"center"}
              justify={"center"}
            >
              <Grid item xs={12}>
                {portfolioTotalValuePaperComponent}
              </Grid>
              <Grid item xs={12}>
                {holdingsSummaryListComponent}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item sm={5}>
          <Paper className={classes.paper}>
            <div className={classes.pieChartContainer}>
              {portfolioSharePieChartComponent}
            </div>
          </Paper>
        </Grid>
      </>
    );
  }

  _renderMobile(
    portfolioTotalValuePaperComponent,
    portfolioSharePieChartComponent,
    holdingsSummaryListComponent,
  ) {
    return (
      <>
        <Grid item xs={12}>
          {portfolioTotalValuePaperComponent}
        </Grid>
        <Grid item xs={12} container justify="center">
          <Box width={180} height={180}>
            {portfolioSharePieChartComponent}
          </Box>
        </Grid>
        <Grid item xs={12}>
          {holdingsSummaryListComponent}
        </Grid>
      </>
    );
  }
};

PortfolioSummary = withWidth()(withStyles(useStyles)(PortfolioSummary));

import React from "react";
import {EcoTextField} from "./EcoTextField";

export class EcoTextFieldWithAssistant extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldAssistant: props.fieldAssistant
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        this.state.fieldAssistant.setModel(event.target.value);
        this.props.onFieldChange();
        this.setState((state) => {
            return {fieldAssistant: state.fieldAssistant};
        });
    }

    render() {
        return (
            <EcoTextField data-e2e={this.props["data-e2e"]}
                          name={this.props.name}
                          error={this.state.fieldAssistant.hasFailedAssertions()}
                          helperText={this.errorDescriptions()}
                          InputProps={this.props.InputProps}
                          label={this.props.label}
                          onChange={this.handleInputChange}
                          value={this.state.fieldAssistant.getModel()}
            />
        );
    }

    errorDescriptions() {
        return this.state.fieldAssistant.failedAssertionsDescriptions().join(". ");
    }
}

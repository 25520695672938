import React from "react";
import {List} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import CheckColumnListItem from "./CheckColumnListItem";
import {
    MARKETPRICE,
    MARKETVALUE,
    ORIGINALPRICE,
    PROFITANDLOSS,
    QUANTITY,
    TODAYSPROFITANDLOSS
} from "../../../../instruments_details_table_columns";
import {SmallContainerScreen} from "../../../layout/ui/SmallContainerScreen";

const useStyles = theme => ({
    columnsFilterSection: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[1],
        borderRadius: theme.shape.borderRadius
    },
    columnsFilterHeader: {
        padding: theme.spacing(2, 3),
        color: theme.palette.text.secondary
    },
    columnsFilterButtons: {
        padding: theme.spacing(2, 3)
    },
    columnsFilterCancelButton: {
        padding: theme.spacing(1, 3)
    },
    columnsFilterApplyButton: {
        padding: theme.spacing(1, 6)
    }
});

export class ColumnsFilter extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            model: this.props.columnsModel
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleApplyFilter = this.handleApplyFilter.bind(this);
    }

    // private

    isApplyFiltersButtonDisabled() {
        return !this.state.model.canApplyFilter();
    }

    // handlers

    handleChange(toggledColumn) {
        this.state.model.toggleColumn(toggledColumn);

        this.setState({model: this.state.model});
    }

    handleApplyFilter() {
        this.state.model.applyFilter();

        this.props.onClose();
    }

    // rendering

    render() {
        const {classes} = this.props;
        return (
            <SmallContainerScreen>
                <Grid container spacing={1} className={classes.root}>
                    <Grid item xs={12}>
                        <Grid container
                              justify="space-between"
                              alignItems="center"
                              className={`${classes.columnsFilterHeader} ${classes.columnsFilterSection}`}>
                            <Grid item>
                                <Typography display="inline" variant='h6' component='h6'>
                                    Filtrar columnas
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography display="inline" variant="caption" component="p">
                                    Máximo 3 opciones
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <List data-e2e="filter-columns-list" className={classes.columnsFilterSection}>
                            <CheckColumnListItem key={QUANTITY}
                                                 isChecked={this.state.model.isColumnChecked(QUANTITY)}
                                                 isShown={this.state.model.isColumnAvailableToCheck(QUANTITY)}
                                                 onClick={() => this.handleChange(QUANTITY)}
                                                 column={QUANTITY}
                                                 name="Cantidad"/>
                            <CheckColumnListItem key={MARKETPRICE}
                                                 isChecked={this.state.model.isColumnChecked(MARKETPRICE)}
                                                 isShown={this.state.model.isColumnAvailableToCheck(MARKETPRICE)}
                                                 onClick={() => this.handleChange(MARKETPRICE)}
                                                 column={MARKETPRICE}
                                                 name="Precio"/>
                            <CheckColumnListItem key={ORIGINALPRICE}
                                                 isChecked={this.state.model.isColumnChecked(ORIGINALPRICE)}
                                                 isShown={this.state.model.isColumnAvailableToCheck(ORIGINALPRICE)}
                                                 onClick={() => this.handleChange(ORIGINALPRICE)}
                                                 column={ORIGINALPRICE}
                                                 name="Precio promedio"/>
                            <CheckColumnListItem key={PROFITANDLOSS}
                                                 isChecked={this.state.model.isColumnChecked(PROFITANDLOSS)}
                                                 isShown={this.state.model.isColumnAvailableToCheck(PROFITANDLOSS)}
                                                 onClick={() => this.handleChange(PROFITANDLOSS)}
                                                 column={PROFITANDLOSS}
                                                 name="P&L Total"/>
                            <CheckColumnListItem key={TODAYSPROFITANDLOSS}
                                                 isChecked={this.state.model.isColumnChecked(TODAYSPROFITANDLOSS)}
                                                 isShown={this.state.model.isColumnAvailableToCheck(TODAYSPROFITANDLOSS)}
                                                 onClick={() => this.handleChange(TODAYSPROFITANDLOSS)}
                                                 column={TODAYSPROFITANDLOSS}
                                                 name="P&L Diario"/>
                            <CheckColumnListItem key={MARKETVALUE}
                                                 isChecked={this.state.model.isColumnChecked(MARKETVALUE)}
                                                 isShown={this.state.model.isColumnAvailableToCheck(MARKETVALUE)}
                                                 onClick={() => this.handleChange(MARKETVALUE)}
                                                 column={MARKETVALUE}
                                                 name="Monto"/>
                        </List>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1} justify="space-evenly" className={classes.columnsFilterButtons}>
                            <Grid item>
                                <Button className={classes.columnsFilterCancelButton}
                                        disableElevation
                                        variant="contained"
                                        onClick={this.props.onClose}>
                                    Cancelar
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button data-e2e="apply-filter-button"
                                        className={classes.columnsFilterApplyButton}
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        disabled={this.isApplyFiltersButtonDisabled()}
                                        onClick={this.handleApplyFilter}>
                                    Aplicar filtros
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </SmallContainerScreen>
        );
    }
}

ColumnsFilter = withStyles(useStyles)(ColumnsFilter);

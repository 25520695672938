import React from "react";
import {Grid, Snackbar} from "@material-ui/core";
import {Alert, AlertTitle} from "@material-ui/lab";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = theme => ({
    refreshButton: {
        padding: '0',
        textDecoration: "underline",
        "&:hover": {
            textDecoration: "underline",
            backgroundColor: 'transparent'
        }
    },
    subtitle: {
        marginBottom: theme.spacing(1)
    }
});

export class InformationSnackbar extends React.Component {

    render() {
        return (
            <Snackbar data-e2e={this.props['data-e2e']}
                      open={this.props.isShown}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}>
                <Alert severity="info">
                    <AlertTitle>
                        {this.props.title}
                    </AlertTitle>
                    {this.props.subtitle !== undefined ? this.renderSubtitle() : null}
                </Alert>
            </Snackbar>
        );
    }

    renderSubtitle() {
        const {classes} = this.props;
        return (
            <Typography variant="body2" className={classes.subtitle}>
                {this.props.subtitle}
            </Typography>
        );
    }
}

InformationSnackbar = withStyles(useStyles)(InformationSnackbar);
import React from "react";
import { Hidden, Link, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = theme => ({
  root: {
    padding: theme.spacing(4, 4, 13, 4),
    backgroundColor: "#2E384B",
    minHeight: theme.mixins.footer.height,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5, 8),
    }
  },
  text: {
    color: theme.palette.common.white,
    lineHeight: theme.typography.h6.lineHeight
  },
  emphaticText: {
    fontWeight: theme.typography.fontWeightBold
  },
  textLink: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightMedium,
  },
  iconLink: {
    opacity: 0.5,
    color: theme.palette.common.white,
    marginRight: theme.spacing(4),
  },
  iconLinks: {
    marginTop: theme.spacing(4)
  }
});

export class Footer extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <footer className={classes.root}>
        <Typography variant="subtitle2" className={`${classes.text} ${classes.emphaticText}`}>
          ECOVALORES SA
        </Typography>
        <Hidden smUp>
          <Typography variant="body2" className={classes.text}>
            Copyright 2023
          </Typography>
          <Typography variant="body2" className={classes.text}>
            Todos los derechos reservados.
          </Typography>
        </Hidden>
        <Hidden only="xs">
          <Typography variant="body2" className={classes.text}>
            Copyright 2023 - Todos los derechos reservados.
          </Typography>
        </Hidden>
        <Typography variant="body2" className={classes.text}>
          <Link underline="always"
            className={classes.textLink}
            href="#"
            onClick={this.props.onTermsAndConditionsClick}>
            Términos y Condiciones
          </Link>
        </Typography>
      </footer>
    );
  }
}

Footer = withStyles(useStyles)(Footer);

import React from "react";
import {Hidden} from "@material-ui/core";
import {BlueButton} from "./BlueButton";
import {WhiteButton} from "./WhiteButton";

export class RouterButton extends React.Component {

    render() {
        return (
            <>
                <Hidden lgUp>
                    <WhiteButton {...this.props}>{this.props.children}</WhiteButton>
                </Hidden>
                <Hidden mdDown>
                    <BlueButton {...this.props}>{this.props.children}</BlueButton>
                </Hidden>
            </>
        )
    }
}
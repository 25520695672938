import React from "react";
import NumberFormat from "react-number-format";
import {MoneyPrinter} from "../MoneyPrinter";
import {EcoTextField} from "./EcoTextField";
import {Money} from "../../financial/money/money";

class MoneyInput extends React.Component {

    constructor(props) {
        super(props);

        this.moneyPrinter = new MoneyPrinter();
        this.aReferenceForNumberFormat = React.createRef();
    }

    componentDidMount() {
        // When value is changed externally, input is not updated until input lost focus.
        // On debugging, we found that when input component is initialized, focusedElm is undefined and the condition
        // that triggers update assert for null and fails. So, input value attribute remains unchanged.
        // To workaround this, we set focusedElm on null immediately after first render.
        const numberFormat = this.aReferenceForNumberFormat.current;
        numberFormat.focusedElm = null;
    }

    render() {
        const {inputRef, ...other} = this.props;
        return (
            <NumberFormat
                {...other}
                ref={this.aReferenceForNumberFormat}
                onValueChange={(numberFormatValues) => {
                    return this.handleChange(numberFormatValues)
                }}
                format={(money) => {
                    return this.format(money);
                }}
                removeFormatting={(formattedMoney) => {
                    return this.removeFormatting(formattedMoney);
                }}
                value={this.format(this.props.value)}
            />
        )
    }

    handleChange(numberFormatValues) {
        const anObjectSomewhatPolymorphicWithAnEvent = {
            target: {
                name: this.props.name,
                value: numberFormatValues.value,
            }
        };
        this.props.onValueChange(anObjectSomewhatPolymorphicWithAnEvent);
    }

    format(moneyOrEmptyString) {
        const isEmptyString = moneyOrEmptyString === "";
        if (isEmptyString) {
            return moneyOrEmptyString;
        } else {
            const moneyWithoutExtraDigits = this.removeExtraDigitsAtTheEndFromUserInput(moneyOrEmptyString);
            return this.moneyToFormattedMoney(moneyWithoutExtraDigits);
        }
    }

    removeFormatting(formattedMoney) {
        try {
            return this.formattedMoneyToMoney(formattedMoney);
        } catch (exception) {
            if (exception.message === MoneyPrinter.INVALID_FORMATTED_MONEY) {
                return "";
            } else {
                throw exception;
            }
        }
    }

    removeExtraDigitsAtTheEndFromUserInput(money) {
        const numberOfDigitsToTruncateTo = this.moneyPrinter.fractionsDigits();
        const regex = new RegExp("(\\d+\\.\\d{" + numberOfDigitsToTruncateTo + "})(\\d)");
        const moneyAmountAsString = money.amount().toString();
        const regexMatchObject = moneyAmountAsString.match(regex);
        if (regexMatchObject !== null) {
            const truncatedAmountAsString = regexMatchObject[1];
            return Money.withAmountAndCurrency(parseFloat(truncatedAmountAsString), money.currency());
        } else {
            return money;
        }
    };

    moneyToFormattedMoney(money) {
        return this.moneyPrinter.printObjectUsingLongNotation(money);
    }

    formattedMoneyToMoney(formattedMoney) {
        return this.moneyPrinter.moneyFromPrintedMoney(formattedMoney, this.props.currency);
    }
}

export class MoneyField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldAssistant: props.fieldAssistant
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        this.state.fieldAssistant.setModel(event.target.value);
        this.props.onFieldChange();
        this.setState((state) => {
            return {fieldAssistant: state.fieldAssistant};
        });
    }

    render() {
        return (
            <EcoTextField data-e2e={this.props["data-e2e"]}
                          name={this.props.name}
                          error={this.state.fieldAssistant.hasFailedAssertions()}
                          helperText={this.errorDescriptions()}
                          InputProps={{
                              inputComponent: MoneyInput,
                              inputProps: {
                                  currency: this.props.currency,
                                  onValueChange: this.handleInputChange,
                              }
                          }}
                          value={this.state.fieldAssistant.getModel()}
            />
        )
    }

    errorDescriptions() {
        return this.state.fieldAssistant.failedAssertionsDescriptions().join(". ");
    }
}
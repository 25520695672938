import React from "react";
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = theme => ({
    root: {
        '& label': {
            color: '#90949A'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E8EAEF'
            },
            '&.Mui-focused fieldset': {
                borderWidth: '1px'
            }
        }
    },
    input: {
        backgroundColor: theme.palette.common.white,
    }
});

export class EcoTextField extends React.Component {
    render() {
        const {classes} = this.props;

        return (
            <TextField variant="outlined"
                       required
                       fullWidth
                       id={this.props.name}
                       autoComplete={this.props.name}
                       className={classes.root}
                       FormHelperTextProps={{"data-e2e": "error-message"}}
                       data-e2e={this.props["data-e2e"]}
                       name={this.props.name}
                       error={this.props.error}
                       helperText={this.props.helperText}
                       InputProps={{...this.props.InputProps, className: classes.input}}
                       label={this.props.label}
                       onChange={this.props.onChange}
                       value={this.props.value}
                       autoFocus={this.props.autoFocus}
                       type={this.props.type}
            />
        );
    }
}

EcoTextField = withStyles(useStyles)(EcoTextField)

import {MoneySerializer} from "./MoneySerializer";

export class WithdrawalRequestSerializer {
    serialize(withdrawalRequest) {
        const bankAccountId = withdrawalRequest.bankAccountId();
        const amountToWithdraw = withdrawalRequest.withdrawalAmount();
        const accountNumber = withdrawalRequest.accountNumber();

        const amountToWithdrawSerialized = new MoneySerializer().serialize(amountToWithdraw);

        return {
            'accountNumber': accountNumber,
            'bankAccountId': bankAccountId,
            'amountToWithdraw': amountToWithdrawSerialized
        }
    }
}
import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Skeleton from "react-loading-skeleton";
import withStyles from "@material-ui/core/styles/withStyles";
import {HoldingRow} from "./HoldingRow";
import Typography from "@material-ui/core/Typography";

const useStyles = theme => ({
    table: {
        '& td': {
            padding: theme.spacing(2, 1)
        },
        '& tr:last-child td': {
            borderBottomWidth: 0
        },
    },
    hasFutureMessage: {
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(3)
    }
});

export class HoldingsSummaryList extends React.Component {

    // rendering conditions

    isPortfolioLoaded() {
        return this.props.holdingsData !== null;
    }

    // rendering

    render() {
        const {classes} = this.props;
        return (
            <>
                <TableContainer className={classes.table}>
                    {this.isPortfolioLoaded() ?
                        <Table aria-label="holdings">
                            <TableBody>
                                {this.props.holdingsData.map(holdingData =>
                                    <HoldingRow
                                        key={holdingData.holding}
                                        holding={holdingData.holding}
                                        displayName={holdingData.displayName}
                                        share={holdingData.share}
                                        total={holdingData.total}
                                        color={holdingData.color}
                                        onMouseEnter={() => this.props.onHoldingRowMouseEnter(holdingData.holding)}
                                        onMouseLeave={() => this.props.onRowMouseLeave()}
                                    />)
                                }
                            </TableBody>
                        </Table>
                        : <Skeleton count={15}/>
                    }
                </TableContainer>
                {this.props.hasFutures ?
                    <Typography align="center" component="p" variant="body2" className={classes.hasFutureMessage}>
                        Tu Portfolio contiene futuros
                    </Typography> : null
                }
            </>
        );
    }

}

HoldingsSummaryList = withStyles(useStyles)(HoldingsSummaryList);

import React from "react";
import {ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = theme => ({
    listBullet: {
        minWidth: '24px',
        '& span': {
            backgroundColor: theme.palette.primary.main,
            height: '12px',
            width: '12px',
            borderRadius: '50%',
        }
    }
});

export class HowToListItem extends React.Component {
    render() {
        return (
            <ListItem alignItems="flex-start">
                <ListItemIcon className={this.props.classes.listBullet}> <span/> </ListItemIcon>
                <ListItemText primary={this.props.text}/>
            </ListItem>
        );
    }
}

HowToListItem = withStyles(useStyles)(HowToListItem);
import React from "react";
import Typography from "@material-ui/core/Typography";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Hidden,
    IconButton,
    TableCell,
    TableHead,
    withWidth
} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import withStyles from "@material-ui/core/styles/withStyles";
import ExpandMoreSharp from '@material-ui/icons/ExpandMoreSharp';
import {MoneyPrinter} from "../../MoneyPrinter";
import OneLineCell from "../../ui_common/OneLineCell";
import {
    MARKETPRICE,
    MARKETVALUE,
    ORIGINALPRICE,
    PROFITANDLOSS,
    QUANTITY,
    TODAYSPROFITANDLOSS
} from "../../../instruments_details_table_columns";
import filterIcon from "../../img/icons/holding-type-column-filter.svg";
import {PositionPopoverInfo} from "./PositionPopoverInfo";

const useStyles = theme => ({
    root: {
        boxShadow: "0px 0px 18px #EFF1F7",
        backgroundColor: 'transparent',
        '&::before': {
            backgroundColor: "transparent"
        }
    },
    holdingTypeSummary: {
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius
    },
    holdingTypeSummaryExpanded: {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
    },
    holdingTypeTitle: {
        color: theme.palette.info.main,
        fontWeight: theme.typography.fontWeightMedium
    },
    holdingTypeContent: {
        backgroundColor: theme.palette.background.paper,
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
        padding: 0
    },
    tableBody: {
        '& :last-child td': {
            borderBottomWidth: 0
        }
    },
    tableBodyRow: {
        cursor: 'pointer',
        '&.MuiTableRow-hover:hover': {
            backgroundColor: '#fdfdfd'
        }
    },
    tableRow: {
        '& th': {
            padding: theme.spacing(2, 0.25),
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: theme.typography.caption.fontSize,
            color: theme.palette.text.secondary
        },
        '& td': {
            padding: theme.spacing(2, 0.25),
            fontWeight: theme.typography.fontWeightLight
        },
        '& td:first-child': {
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.secondary
        },
        '& th:first-child, td:first-child': {
            paddingLeft: theme.spacing(1)
        },
        [theme.breakpoints.up('sm')]: {
            '& th:first-child, td:first-child': {
                paddingLeft: theme.spacing(2)
            },
        }
    },
    positionInfoCell: {
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(6)
        }
    },
    positionSymbol: {
        overflowWrap: 'break-word',
        maxWidth: '96px'
    },
    tradingActionButtonCell: {
        lineHeight: 1,
        width: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(4)
        }
    },
    tradingActionButtonIcon: {
        transform: "rotate(-90deg)"
    }
});

export let PositionDetailsTable = class PositionDetailsTable extends React.Component {
    constructor(props) {
        super(props);

        this.handleStartFilter = this.handleStartFilter.bind(this);
    }

    // private

    preferredTradingAction(availableActions) {
        return availableActions.find((action) => action.type === this.props.preferredTradingActionType);
    }

    // handlers

    onTableRowClick(availableActions) {
        const tradingAction = this.preferredTradingAction(availableActions);
        if (tradingAction !== undefined && tradingAction.link !== undefined) {
            return () => window.open(tradingAction.link, "_blank");
        } else {
            return null;
        }
    }

    handleStartFilter() {
        this.props.columnsModel.startColumnsFilterOf(this.props.holdingType);
        this.props.onOpenColumnsFilter();
    }

    render() {
        const {classes} = this.props;
        if (this.props.data.length === 0) {
            return null;
        }
        return (
            <Accordion className={classes.root}
                       defaultExpanded={this.props.defaultExpanded}>
                <AccordionSummary
                    classes={{root: classes.holdingTypeSummary, expanded: classes.holdingTypeSummaryExpanded}}
                    expandIcon={<ExpandMoreSharp/>}
                    aria-controls="holdingTypeTable-content"
                    data-e2e="holding-type-expand-button">
                    <Typography component="p" variant="subtitle1" className={classes.holdingTypeTitle}>
                        {this.props.titleTypeName}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails data-e2e="instrument-type-details"
                                  className={classes.holdingTypeContent}>
                    <TableContainer>
                        <Table aria-label="simple table" padding="none">
                            <TableHead>
                                <TableRow className={classes.tableRow}>
                                    <TableCell/>
                                    <OneLineCell align="left">Especie</OneLineCell>
                                    {this.props.columnsModel.shouldDisplayColumn(this.props.holdingType, QUANTITY, this.props.width) ?
                                        <OneLineCell align="center" data-e2e={`${QUANTITY}-column`}>
                                            Cant.
                                        </OneLineCell> : null
                                    }
                                    {this.props.columnsModel.shouldDisplayColumn(this.props.holdingType, MARKETPRICE, this.props.width) ?
                                        <OneLineCell align="center" data-e2e={`${MARKETPRICE}-column`}>
                                            Precio
                                        </OneLineCell> : null
                                    }
                                    {this.props.columnsModel.shouldDisplayColumn(this.props.holdingType, ORIGINALPRICE, this.props.width) ?
                                        <OneLineCell align="center" data-e2e={`${ORIGINALPRICE}-column`}>
                                            Precio promedio
                                        </OneLineCell> : null
                                    }
                                    {this.props.columnsModel.shouldDisplayColumn(this.props.holdingType, PROFITANDLOSS, this.props.width) ?
                                        <OneLineCell align="center" data-e2e={`${PROFITANDLOSS}-column`}>
                                            P&L Total
                                        </OneLineCell> : null
                                    }
                                    {this.props.columnsModel.shouldDisplayColumn(this.props.holdingType, TODAYSPROFITANDLOSS, this.props.width) ?
                                        <OneLineCell align="center" data-e2e={`${TODAYSPROFITANDLOSS}-column`}>
                                            P&L Diario
                                        </OneLineCell> : null
                                    }
                                    {this.props.columnsModel.shouldDisplayColumn(this.props.holdingType, MARKETVALUE, this.props.width) ?
                                        <OneLineCell align="center" data-e2e={`${MARKETVALUE}-column`}>
                                            Monto
                                        </OneLineCell> : null
                                    }
                                    <OneLineCell align="center">
                                        <Hidden smUp>
                                            <IconButton data-e2e={`filter-button`}
                                                        onClick={this.handleStartFilter}>
                                                <img src={filterIcon} alt="filter"/>
                                            </IconButton>
                                        </Hidden>
                                    </OneLineCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.tableBody}>
                                {this.props.data.map((positionDetails, index) =>
                                    <TableRow key={index} hover={true}
                                              className={`${classes.tableRow} ${classes.tableBodyRow}`}
                                              onClick={this.onTableRowClick(positionDetails.tradingActions)}>
                                        <TableCell align="center" className={classes.positionInfoCell}>
                                            <PositionPopoverInfo
                                                shouldDisplay={positionDetails.isInPesosAndInstrumentIsNot}/>
                                        </TableCell>
                                        <TableCell align="left" className={classes.positionSymbol}>
                                            {positionDetails.symbol}
                                        </TableCell>
                                        {this.props.columnsModel.shouldDisplayColumn(this.props.holdingType, QUANTITY, this.props.width) ?
                                            <TableCell align="center">
                                                {positionDetails.quantity}
                                            </TableCell>
                                            : null
                                        }
                                        {this.props.columnsModel.shouldDisplayColumn(this.props.holdingType, MARKETPRICE, this.props.width) ?
                                            <TableCell align="center">
                                                {new MoneyPrinter().printUsingLongNotation(positionDetails.marketPrice)}
                                            </TableCell>
                                            : null
                                        }
                                        {this.props.columnsModel.shouldDisplayColumn(this.props.holdingType, ORIGINALPRICE, this.props.width) ?
                                            <TableCell align="center">
                                                {new MoneyPrinter().printUsingLongNotation(positionDetails.originalPrice)}
                                            </TableCell>
                                            : null
                                        }
                                        {this.props.columnsModel.shouldDisplayColumn(this.props.holdingType, PROFITANDLOSS, this.props.width) ?
                                            <TableCell align="center">
                                                {new MoneyPrinter().print(positionDetails.profitAndLoss)}
                                            </TableCell>
                                            : null
                                        }
                                        {this.props.columnsModel.shouldDisplayColumn(this.props.holdingType, TODAYSPROFITANDLOSS, this.props.width) ?
                                            <TableCell align="center">
                                                {new MoneyPrinter().print(positionDetails.todaysProfitAndLoss)}
                                            </TableCell>
                                            : null
                                        }
                                        {this.props.columnsModel.shouldDisplayColumn(this.props.holdingType, MARKETVALUE, this.props.width) ?
                                            <TableCell align="center">
                                                {new MoneyPrinter().print(positionDetails.marketValue)}
                                            </TableCell>
                                            : null
                                        }
                                        <TableCell align="center" className={classes.tradingActionButtonCell}>
                                            <ExpandMoreSharp color="primary"
                                                             className={classes.tradingActionButtonIcon}/>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table></TableContainer>
                </AccordionDetails>
            </Accordion>
        );
    }
}

PositionDetailsTable = withWidth()(withStyles(useStyles)(PositionDetailsTable));

import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

const useStyles = theme => ({
    root: {
        padding: theme.spacing(4),
        '& p': {
            textAlign: 'justify'
        }
    }
});

export class TermsAndConditionsScreen extends React.Component {

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <Typography component="h5" variant="h5" color="primary">
                    Términos y Condiciones
                </Typography>

                <p>
                    El contenido de la presente aplicación no constituye asesoramiento en inversiones ni una
                    invitación a operar con valores negociables a través de Eco Valores S.A. ("Eco"). Cualquier
                    persona interesada en analizar y, en su caso, operar los valores negociables aquí descriptos,
                    deberá consultar la información contenida en sus documentos de emisión. La información aquí
                    inserta ha sido confeccionada exclusivamente para la República Argentina, no debiendo
                    interpretarse destinado a cualquier otra jurisdicción o territorio. Se advierte que parte de la
                    información de la presente aplicación está constituida por estimaciones futuras. Debido a
                    riesgos e incertidumbres, los resultados reales o el desempeño de los valores negociables
                    podrían diferir significativamente de lo expresado en las estimaciones futuras. La información
                    aquí contenida está sujeta a cambios y modificaciones y no puede ser considerada definitiva por
                    aquellas personas que tomen conocimiento de ella. El receptor deberá basarse exclusivamente en
                    su propia investigación, evaluación y juicio independiente para tomar una decisión relativa a la
                    inversión en valores negociables. Los rendimientos pasados manifestados por valores negociables
                    son un indicador y no implican, bajo ningún concepto, promesas de rendimientos futuros.
                </p>
                <p>
                    Eco es un Agente de Liquidación y Compensación - Propio registrado bajo la matrícula n° 109 y
                    Agente de Colocación y Distribución Integral de FCI registrado bajo la matrícula n° 45, en todos
                    los casos de la CNV. Además, Eco es miembro de Bolsas y Mercados Argentinos S.A. (BYMA), MATBA
                    ROFEX S.A, y Mercado Argentino de Valores S.A. (MAV).
                </p>
                <p>
                    En particular ha de tenerse presente que las inversiones en cuotapartes de fondos comunes de
                    inversión no constituyen depósitos en la sociedad depositaria de tales fondos a los fines de la
                    Ley de Entidades Financieras ni cuentan con ninguna de las garantías que tales depósitos a la
                    vista o a plazo puedan gozar de acuerdo a la legislación y reglamentación aplicables en materia
                    de depósitos en entidades financieras. Asimismo, la sociedad depositaria de los fondos comunes
                    de inversión se encuentra impedida por normas del Banco Central de la República Argentina de
                    asumir, tácita o expresamente, compromiso alguno en cuanto al mantenimiento, en cualquier
                    momento, del valor del capital invertido, al rendimiento, al valor de rescate de las cuotapartes
                    o al otorgamiento de liquidez a tal fin.
                </p>
                <p>
                    Se hace saber al público inversor, que ciertas compañías locales y extranjeras ofrecen servicios
                    en diversos medios de comunicación, sobre productos como bonos, acciones, contratos de futuros y
                    opciones, fideicomisos, fondos de inversión, sin contar con la autorización correspondiente de
                    la CNV. Los ofrecimientos realizados a inversores locales desde el extranjero que permitan
                    operar a través de plataformas informáticas o páginas de Internet, representan un alto riesgo
                    para el público inversor. Por ello, se advierte sobre los elevados riesgos de pérdida de capital
                    que potencialmente podría acarrear efectuar inversiones a través de entidades no autorizadas, y
                    las consecuencias que puede traer aparejada la infracción a la Ley N° 26.831 de Oferta Pública.
                    Si desea conocer la lista de Agentes registrados en la CNV, cliquee aquí.
                </p>
                <p>
                    El titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los
                    mismos en forma gratuita a intervalos no inferiores a 6 meses, salvo que se acredite un interés
                    legítimo al efecto, conforme lo establecido en el artículo 14, inciso 3 de la Ley Nº25.326” “La
                    DIRECCION NACIONAL DE PROTECCION DE DATOS PERSONALES, órgano de Control de la Ley Nº25.326,
                    tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al
                    incumplimiento de las normas sobre protección de datos personales.
                </p>
                <p>
                    Ley 24.240, Artículo 10 ter: Modos de Rescisión. Cuando la contratación de un servicio,
                    incluidos los servicios públicos domiciliarios, haya sido realizada en forma telefónica,
                    electrónica o similar, podrá ser rescindida a elección del consumidor o usuario mediante el
                    mismo medio utilizado en la contratación.
                </p>
                <p>
                    La empresa receptora del pedido de rescisión del servicio deberá enviar sin cargo al domicilio
                    del consumidor o usuario una constancia fehaciente dentro de las SETENTA Y DOS (72) horas
                    posteriores a la recepción del pedido de rescisión. Esta disposición debe ser publicada en la
                    factura o documento equivalente que la empresa enviare regularmente al domicilio del consumidor
                    o usuario.`
                </p>
            </div>
        );
    }

}

TermsAndConditionsScreen = withStyles(useStyles)(TermsAndConditionsScreen)

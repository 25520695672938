export const STOCK = "STOCK";
export const BOND = "BOND";
export const LETTER = "LETTER";
export const NEGOTIABLE_OBLIGATION = "NEGOTIABLE_OBLIGATION";
export const STOCK_OPTION = "STOCK_OPTION";
export const FUTURE = "FUTURE";
export const OPTION_FUTURE = "OPTION_FUTURE";
export const COLLATERALS = "COLLATERALS";
export const MUTUAL_FUNDS = "MUTUAL_FUNDS";
export const ARS_CURRENCY_HOLDING = "ARS_CURRENCY_HOLDING";
export const DOLLAR_MEP_CURRENCY_HOLDING = "DOLLAR_MEP_CURRENCY_HOLDING";
export const DOLLAR_UY_CURRENCY_HOLDING = "DOLLAR_UY_CURRENCY_HOLDING";
export const DOLLAR_DB_CURRENCY_HOLDING = "DOLLAR_DB_CURRENCY_HOLDING";
export const DOLLAR_MEP_CURRENCY_BANK = "DOLLAR_MEP_CURRENCY_BANK";
export const DOLLAR_MATBA_ROFEX_CURRENCY_HOLDING =
  "DOLLAR_MATBA_ROFEX_CURRENCY_HOLDING";
export const DOLLAR_CABLE_CURRENCY_HOLDING = "DOLLAR_CABLE_CURRENCY_HOLDING";
export const DOLLAR_CABLE_CURRENCY_BANK = "DOLLAR_CABLE_CURRENCY_BANK";
export const PENDING_MOVEMENTS = "PENDING_MOVEMENTS";
export const DAILY_DIFFERENCE_IN_ARS = "DAILY_DIFFERENCE_IN_ARS";

import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {EcoChatButton} from "./EcoChatButton";

const useStyles = theme => ({
    root: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4)
    }
});

export class EcoChatFab extends React.Component {

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <EcoChatButton/>
            </div>
        );
    }
}

EcoChatFab = withStyles(useStyles)(EcoChatFab);

export class UnknownMoney {

    static withCurrency(aCurrency) {
        return new this(aCurrency);
    }

    constructor(aCurrency) {
        this._currency = aCurrency;
    }

    isIn(currency) {
        return this._currency.isEqualTo(currency);
    }

    isUnknown() {
        return true;
    }

    isNotZero() {
        return true;
    }

    // serialization

    serialize(aSerializer) {
        return aSerializer.serializeUnknownMoney(this._currency);
    }
}

import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = theme => ({
    root: {
        height: '8px',
        width: '8px',
        borderRadius: '50%',
        display: 'inline-block',
        verticalAlign: 'middle',
        [theme.breakpoints.up('sm')]: {
            height: '16px',
            width: '16px'
        }
    }
});

class HoldingColorMarker extends React.Component {
    render() {
        return <span className={this.props.classes.root} style={{backgroundColor: this.props.color}}/>
    }
}

export default withStyles(useStyles)(HoldingColorMarker)
import {FormCompletionAssistant} from "./FormCompletionAssistant";

export class FormFieldCompletionAssistant extends FormCompletionAssistant {
    _model;
    _initialModel;

    static handling(assertionId, fromContainerModelGetter, initialModel) {
        return this.handlingAll([assertionId], fromContainerModelGetter, initialModel);
    }

    static handlingAll(assertionsId, fromContainerModelGetter, initialModel) {
        return new this(assertionsId, fromContainerModelGetter, initialModel);
    }

    constructor(assertionsId, fromContainerModelGetter, initialModel) {
        super(assertionsId, fromContainerModelGetter);
        this._initialModel = initialModel;
        this.setModel(initialModel);
    }

    // model

    createModel(_creationClosure) {
        this.removeFailedAssertions();
        return this._model;
    }

    getModel() {
        return this._model;
    }

    setModel(newModel) {
        this._model = newModel;
    }

    resetModel() {
        this._model = this._initialModel;
    }

    // testing

    isIncomplete() {
        return this.getModel() === "";
    }
}
import React from "react";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = theme => ({
    root: {
        paddingLeft: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold
    }
});

export class LoginTitle extends React.Component {
    render() {
        const {classes} = this.props;

        return (
            <Typography component="h5" variant="h5" color="primary" className={classes.root}>
                {this.props.children}
            </Typography>
        );
    }
}

LoginTitle = withStyles(useStyles)(LoginTitle);

import React from "react";
import Grid from "@material-ui/core/Grid";
import {Hidden} from "@material-ui/core";
import {ARS_ALIAS, ARS_CBU, DepositInformation, USD_ALIAS, USD_CBU} from "./DepositInformation";
import {DepositWaitingTimeMessage} from "./DepositWaitingTimeMessage";
import {HowTo} from "../ui_common/HowTo";
import {HowToListItem} from "../ui_common/HowToListItem";

export class HowToDeposit extends React.Component {

    // rendering

    render() {
        const howToSection = (
            <Grid item xs={12}>
                <HowTo xsTitle="Para Depositar" smUpTitle="¿Cómo deposito?" allCuit="Eco Valores S.A. CUIT 30-70918189-7">
                    <HowToListItem text="Copiá con el ícono el alias o el CBU"/>
                    <HowToListItem
                        text="Pegá el alias o CBU de Eco Valores S.A. en tu Homebanking o billetera virtual"/>
                    <HowToListItem text="¡Listo! entrá a nuestra plataforma y empezá a operar"/>
                </HowTo>
            </Grid>
        )

        return (
            <Grid container spacing={2}>
                <Hidden only="xs">
                    {howToSection}
                </Hidden>
                <Grid item xs={12} sm={6}>
                    <DepositInformation title="Depositar Pesos"
                                        alias={ARS_ALIAS}
                                        cbu={ARS_CBU}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DepositInformation title="Depositar Dólares"
                                        alias={USD_ALIAS}
                                        cbu={USD_CBU}/>
                </Grid>
                <Hidden smUp>
                    {howToSection}
                </Hidden>
                <DepositWaitingTimeMessage/>
            </Grid>
        );
    }
}

import React from "react";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = theme => ({
    root: {
        backgroundColor: theme.palette.common.white,
        boxShadow: "0px 0px 20px rgba(162, 132, 255, 0.212)",
        color: theme.palette.text.secondary,
        '&:hover': {
            backgroundColor: '#f7f8f9',
            boxShadow: "0px 0px 20px rgba(162, 132, 255, 0.212)"
        }
    }
})

export class WhiteButton extends React.Component {
    render() {
        const {classes} = this.props;
        return (
            <Button {...this.props}
                    className={`${classes.root} ${this.props.className}`}
                    variant="contained">
                {this.props.children}
            </Button>
        );
    }
}

WhiteButton = withStyles(useStyles)(WhiteButton);

import React from "react";
import {StandardContainerScreen} from "./StandardContainerScreen";

export class SmallContainerScreen extends React.Component {
    render() {
        return (
            <StandardContainerScreen data-e2e={this.props["data-e2e"]} maxWidth="sm">
                {this.props.children}
            </StandardContainerScreen>
        )
    }
}